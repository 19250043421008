import {GetSafeValue} from '../utils/get-safe-value';
import {StringFieldUtil} from '../utils/string-field-util';
import {DeliveryMethod} from "./delivery-method";

export class OrderStatus {
  id: string;
  storeId: string;
  total: number;
  customerName: string;
  customerId: string;
  orderDate: string;
  documentPath: string;
  orderStatus: string;
  isPaid: boolean;
  private _deliveryMethod: DeliveryMethod;
  phoneNumber : string;

  constructor() {
    this.deliveryMethod = new DeliveryMethod();
  }

  fromJson(data: OrderStatus) {
    this.id = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.orderId], null);
    this.storeId = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.storeId], null);
    this.orderDate = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.orderDate], null);
    this.customerName = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.customerName], null);
    this.total = GetSafeValue.getSafeValueAsNumber(data[StringFieldUtil.total], 0.0);
    this.documentPath = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.documentPath], null);
    this.orderStatus = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.orderStatus], null);
    this.isPaid =  GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isPaid], null);
    this.customerId = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.customerId], null);
    this.phoneNumber = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.phoneNumber], null);
    this.deliveryMethod = new DeliveryMethod();
    if (data[StringFieldUtil.deliveryMethod]) {
      this.deliveryMethod.fromJson(data[StringFieldUtil.deliveryMethod]);
    }
  }

  getTypeDeliveryMethod(): String {
    if (!this.deliveryMethod || !this.deliveryMethod.type) {
      return undefined;
    }

    return this.deliveryMethod.type;
  }

  getValueDeliveryMethod(): number {
    if (!this.deliveryMethod || !this.deliveryMethod.value) {
      return 0;
    }

    return this.deliveryMethod.value;
  }

  get deliveryMethod(): DeliveryMethod {
    return this._deliveryMethod;
  }

  set deliveryMethod(value: DeliveryMethod) {
    this._deliveryMethod = value;
  }

  toMap() {
    return {
      'orderId': this.id,
      'storeId': this.storeId,
      'orderDate': this.orderDate,
      'customerName': this.customerName,
      'total': this.total,
      'documentPath': this.documentPath,
      'orderStatus': this.orderStatus,
      'isPaid': this.isPaid,
      'customerId': this.customerId,
      'deliveryMethod': this.deliveryMethod.toMap(),
      'phoneNumber': this.phoneNumber,
    };
  }
}

import {GetSafeValue} from "../utils/get-safe-value";
import {StringFieldUtil} from "../utils/string-field-util";

export class Watermark {
  private _isWatermarkEnable: boolean;
  private _imageUrls: string[];

  constructor() {
    this.isWatermarkEnable = false;
    this.imageUrls = [];

  }

  fromJson(data: Watermark) {
    this.imageUrls = GetSafeValue.getSafeValueAsList(data[StringFieldUtil.imageUrls], [], []);
    this.isWatermarkEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isWatermarkEnable], false);
  }


  get isWatermarkEnable(): boolean {
    return this._isWatermarkEnable;
  }

  set isWatermarkEnable(value: boolean) {
    this._isWatermarkEnable = value;
  }

  get imageUrls(): string[] {
    return this._imageUrls;
  }

  set imageUrls(value: string[]) {
    this._imageUrls = value;
  }

  toMap() {
    return {
      "isWatermarkEnable" : this.isWatermarkEnable,
      "imageUrls" : this.imageUrls
    };
  }
}

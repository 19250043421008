import {FirebaseService} from "../services/firebase.service";
import {SharedPreference} from "../shared-preference/shared-perference";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {SubscriptionUtils} from "../utils/subscriptionUtils";
import {environment} from "../../environments/environment";
import {LoggUtilInfo} from '../utils/loggUtil';
import {StringUtils} from '../utils/string-utils';
import * as firebase from 'firebase';
import Swal from "sweetalert2";
import {homePageUrl, loginUrl} from '../inventory/route';
import {StringFieldUtil} from "../utils/string-field-util";
import {User} from "../model/user";
import {ApiService} from '../services/http-service/api.service';
import {AesHelper} from '../shared-preference/aes-helper';
/**
 * Created by cheaphakdey on 19/6/19.
 */

export class UserService{
  private static subscriptionUtils: SubscriptionUtils = new SubscriptionUtils();

  static getUserRole(firebaseService: FirebaseService, router: Router, uid: string) {
    let subscriptionName: string = FirebaseService.user +"/"+ uid;
    if(this.subscriptionUtils.isNotAlreadySubscribe(subscriptionName)) {
      let subscription: Subscription = firebaseService
        .onGetDataById(FirebaseService.user, uid)
        .subscribe(async data => {
            LoggUtilInfo.loggingListen(StringUtils.listenSnapshot + subscriptionName, ` component : user service`);
            let user = data;
            if (user == null || user.isEnable == null || !user.isEnable) {
              this.subscriptionUtils.unsubscribeAll();
              SharedPreference.clearUser();
              SharedPreference.setIsUserLogin(false);
              SharedPreference.isShowingMenu = false;
              SharedPreference.removeAllItem();
              if (environment.isEnableHomePage) {
                await router.navigate(['/home-page'])
              } else {
                await router.navigate([loginUrl]);
              }

            } else {
              SharedPreference.isShowingMenu = true;
            }
            LoggUtilInfo.loggingEnd(StringUtils.listenSnapshot + subscriptionName, ` component : user service`);
          }
        );
      this.subscriptionUtils.subscribe(subscriptionName, subscription);
    }
  }

  static unsubcribUser(){
    this.subscriptionUtils.unsubscribeAll();
  }

  static signInWithPhoneNumber(firebaseService: FirebaseService, loginVia: string, api: ApiService, code: string, phone: string, callback: Function, error: Function, loading: Function) {
    api.requestCustomToken(phone, code).toPromise().then(value => {
      loading(false);
      const token: string = AesHelper.decrypt(value['token']) || '';
      const verifySms: boolean = AesHelper.decrypt(value['verifySms'] || false) === 'true';
      const loginPinCode: string = AesHelper.decrypt(value['loginPinCode'] || '') || '';
      const isCustomer: boolean = AesHelper.decrypt(value['isCustomer'] || false) === 'true';
      let isLoginPinCode: boolean = loginPinCode && loginPinCode.length === 6 || false;

      if(isCustomer)
        callback({uid: StringUtils.internalUserOnly});

      // sms
      if(verifySms || ['forgot_pin', 'change_pin'].includes(loginVia)){
        isLoginPinCode = false;
        this.signInWithOTPCode(api, token, phone, code, callback, error, loading);
        return;
      }

      // pin
      if(isLoginPinCode){ //todo: login with pin code
        this.inputUICodeOrOTP(
          "Please enter your 6 digits PIN code.",
          'Enter old PIN code'
        ).then((result) => {
          if(result.value === loginPinCode){
            loading(true);
            firebase.auth().signInWithCustomToken(token).then((data) => {
              callback({uid: data['user'].uid});
            }).catch((e) => error(e));
          }else {
            callback({uid: StringUtils.invalidPin});
          }
        });
      }else {
        this.inputUICodeOrOTP(
          "Please set a new 6 digits PIN code",
          'Set a new PIN code'
        ).then((result) => {
          if(result.value){
            loading(true);
            firebase.auth().signInWithCustomToken(token).then((data) => {
              const uid = data['user'].uid;
              const path = FirebaseService.user + '/' + uid;
              const user = {
                loginPinCode: result.value,
              };

              firebaseService.updateField(path, user).then((_) => {
                callback({uid: data['user'].uid});
              }).catch((e => error(e)));

            }).catch((e) => error(e));
          }else {
            callback({uid: StringUtils.invalidPin});
          }
        });
      }
    }).catch((e) => error(e));
  }

  static signInWithOTPCode(api: ApiService, token: string, phone: string, code: string, callback: Function, error: Function, loading: Function) {
    api.requestAuthOTP(phone, code).toPromise().then(res => {
      loading(false);
      const otpCode: string = AesHelper.decrypt(res['result'] && res['result']['otpCode']) || '';
      if(!environment.production)
        // console.log('requestAuthOTP==> ', otpCode, res);

      phone = code + phone;
      this.inputUICodeOrOTP(
        StringUtils.enterOTPCode + phone,
        StringUtils.temporaryOTPCode
      ).then((result) => {
        if(result && result.value === otpCode){
          loading(true);
          firebase.auth().signInWithCustomToken(token).then((data) => {
            callback({uid: data['user'].uid});
          }).catch((e) => error(e));
        }else {
          callback({uid: StringUtils.invalidPin});
        }
      });
    }).catch((e) => error(e));
  }

  static inputUICodeOrOTP(title: string, inputPlaceholder: string) {
    return Swal.fire({
      title: `<h4>${title}</h4>`,
      input: 'password',
      inputPlaceholder: inputPlaceholder,
      showCancelButton: false,
      confirmButtonText: 'Submit',
      showLoaderOnConfirm: true,
      width: 250,
      inputAttributes: {
        maxlength: "6",
        autocapitalize: "off",
        autocorrect: "off"
      },
      preConfirm: (temPassword) => {
        if (temPassword === undefined || temPassword === '') {
          return Swal.showValidationMessage(
            StringUtils.temporaryPasswordRequest,
          );
        } else if (temPassword.toString().length < 6) {
          return Swal.showValidationMessage(
            StringUtils.temporaryPasswordSixDigits,
          );
        }
      },
    });
  }

  static checkUserWithPhoneExist(firebaseService: FirebaseService, user: User){
    let phone = user.phoneNumber.get(StringUtils.phone);
    return firebaseService.db.collection(FirebaseService.user, ref =>
      ref
        .where(StringFieldUtil.phoneNumber_Phone, "==", phone)
        .where(StringFieldUtil.loginVia, "==", StringFieldUtil.phone)
    ).get();
  }

}

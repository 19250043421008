import {Validators} from '@angular/forms';

/**
 * Created by cheaphakdey on 26/1/19.
 */

export class ControlFormNameUtil{
  static cfnFirstName = "firstName";
  static cfnLastName = "lastName";
  static cfnGender = "gender";
  static cfnEmail = "email";
  static cfnCompanyName = "companyName";
  static cfnRole = "roles";
  static cfnAddress = "address";
  static cfnAddressLineTwo = "address2";
  static cfnCountry = "country";
  static cfnCity = "city";
  static cfnState_Province = "state";
  static cfnPostCode = "postCode";
  static cfnPhone = "phone";
  static cfnCurrentPassword = "currentPassword";
  static cfnNewPassword = "newPassword";
  static cfnConfirmPassword = "confirmPassword";
  static cfcStoreId = "storeId";
  static cfnPassword = "password";
  static cfnEnable = "enable";
  static cfnStoreNameEn = "storeNameEn";
  static storeNameKh = "storeNameKh";
  static storeAddressEn = "storeAddressEn";
  static storeAddressKh = "storeAddressKh";
  static storeHoursEn = "storeHoursEn";
  static storeHoursKh = "storeHoursKh";
  static storeAndroidUrl = "storeAndroidUrl";
  static storeIosUrl = "storeIosUrl";
  static storeWebUrl = "storeWebUrl";
  static storeNumberPhone = "numberPhone";

  static cfnLimitCostFreeShipping = "limitCostFreeShipping";
  static cfnCustomDayCost = "customDayCost";
  static cfnDeliveryOptions = "deliveryOptions";
  static cfnPrice = "price";
  static cfnCustomDayShipping = "customDayShipping";
  static sameDayCutOffTime = "sameDayCutOffTime";
  static sameDayCost = "sameDayCost";
  static nextDayCost = "nextDayCost";
  static storePhoneNumber = "storePhoneNumber";

  static facebookAndroid = "facebookAndroid";
  static facebookIos = "facebookIos";
  static facebookWeb = "facebookWeb";

  static instagramAndroid = "instagramAndroid";
  static instagramIos = "instagramIos";
  static instagramWeb = "instagramWeb";
  static website = "website";

  static OderConfirmMessEn = "OderConfirmMessEn";
  static OderConfirmMessKh = "OderConfirmMessKh";

  static pushStatusDeliveryEn = "pushStatusDeliveryEn";
  static pushStatusDeliveryKh = "pushStatusDeliveryKh";
  static pushStatusProcessedEn = "pushStatusProcessedEn";
  static pushStatusProcessedKh = "pushStatusProcessedKh";

  static packageEmailTeamField = "packageEmailTeamField";
  static deliveryEmailTeamField = "deliveryEmailTeamField";
  static recipientEmailTeamField = "recipientEmailTeamField";

  static cfnDesEn = "descriptionEn";
  static cfnDesKh = "descriptionKh";
  static cfnParentID="pID";
  static cfnTitleEn="titleEn";
  static cfnType="type";
  static cfnTitleKh="titleKh";
  static cfnIsEnable="isEnable";
  static cfnIsSubCategory="isSubCategory";
  static cfnStoreName="storeName";
  static cfnStoreAddress="storeAddress";
  static cfnStoreID="storeID";
  static cfnStoreOwnerFirstName="storeOwnerFirstName";
  static cfnStoreOwnerLastName="storeOwnerLastName";
  static cfnStoreOwnerPhone="storeOwnerPhone";
  static cfnMaxInternalUser = "maxInternalUser";
  static cfnStoreOwnerEmail="storeOwnerEmail";
  static cfnStoreAdminFirstName="storeAdminFirstName";
  static cfnStoreAdminLastName="storeAdminLastName";
  static cfnStoreAdminPhone="storeAdminPhone";
  static cfnStoreAdminEmail="storeAdminEmail";
  static cfnIsDashboardEnable="isDashboardEnable";
  static cfnIsFacebookEnable="isFacebookEnable";
  static cfnIsExportEnable="isExportEnable";
  static cfnMaxItemCount = "maxItemCount";
  static cfnMaxItemSpecial = "maxItemSpecial";


  static cfnBarcode="barcode";
  static cfnRating="rating";
  static cfnEn="en";
  static cfnKh="kh";
  static cfnBrand="brand";
  static cfnCategory="category";
  static cfnSubCategory="subCategory";
  static cfnSize="size";
  static cfnColor="color";
  static cfnCode="code";
  static cfnQtyPerCase="qtyPerCase";
  static cfnItemSalesType="saleType";
  static cfnSalesPrice="salePrice";
  static cfnOriPrice="originalPrice";
  static cfnDiscount="discount";
  static cfnStockIn="availableQty";
  static cfnBufferQty="bufferQty";
  static cfnKeyword="keyword";
  static cfnItemCount="reviewCounter";
  static cfnTitleEnDetail="titleEnDetail";
  static cfnTitleKhDetail="titleKhDetail";
  static cfnDesEnDetail="descripEn";
  static cfnDesKhDetail="descripKh";
  static cfnAdditional = "additional";
  static cfnUnitCost = "unitCost";
  static cfnAndroidPlayStore = "AndroidPlayStore";
  static cfnIosAppStore = "IosAppStore";
  static cfnStockWarning = "stockWarning";
  static cfnItemSpecialExpirationDate="itemSpecialExpirationDate";
  static cfnIsInventoryDeleteEnable = "isInventoryDeleteEnable";
  static cfnSubscribeDashboardOrderStatus = "subscribeDashboardOrderStatus";
  static cfnMaxRecordItems = "maxRecordItems";
  static cfnMaxItemRanking = "maxItemRanking";
  static cfnMinItemRanking = "minItemRanking";
  static cfnMaxRecordKeywords = "maxRecordKeywords";
  static cfnMaxRecordReport = "maxRecordReport";
  static cfnMaxRecordOrderStatus = "maxRecordOrderStatus";
  static cfnMaxRecordRating = "maxRecordRating";
  static cfnMessageDisplayDays = "messageDisplayDays";
  static cfnCanUpdateCustomerUser = "canUpdateCustomerUser";
  static cfnIsTermAndConditionEnable = "isTermAndConditionEnable";
  static cfnIsCustomShippingEnable = "isCustomShippingEnable";
  static cfnIsSingleStoreSetting = "isSingleStoreSetting";
  static cfnCreatedAt = "createdAt";
  static cfnHashRef = "hashRef";
  static cfnReleaseDate = "releaseDate";
  static cfnVersion = "version";
  static cfnSelectAll = "selectAll";
  static cfnCollection = "collection";
  static cfnBankName = "bankName";
  static cfnAccountName = "accountName";
  static cfnAccountNumber = "accountNumber";
  static cfnAbout = "about";
  static cfnCommentOnReceipt = "commentOnReceipt";
  static cfnBusinessType = "businessType";
  static sameRegionsRate = "sameRegionsRate";
  static cfnViewStoresEnable = "viewStoresEnable";
  static cfnIsMultiPayment = "isMultiPayment";
  static cfnIsPaymentOnlineEnable = "isPaymentOnlineEnable";
  static cfnIsGoogleMapEnable = "isGoogleMapEnable";
  static cfnWebUrl = "webUrl";
  static cfnLimitRedeemAmount = "limitRedeemAmount";
  static cfnMinPurchaseAmount = "minPurchaseAmount";
  static cfnLocation = "location";
  static cfnStartsDate = "startsDate";
  static cfnImageUrls = "imageUrls";
  static cfnEndsDate = "endsDate";
  static cfnCDNConfig = "CDNConfig";
  static cfnCDNLastUpdate = "lastUpdate";
  static cfnCDNInterval = "interval";
  static isQrCodeSystemControlEnable = "isQrCodeSystemControlEnable";
  static isEmailEnable = "isEmailEnable";
  static isPhoneEnable = "isPhoneEnable";
  static isWingEnable = "isWingEnable";
  static isAmkPayEnable = "isAmkPayEnable";
  static isAmkQrEnable = "isAmkQrEnable";
  static isWatermarkEnable = "isWatermarkEnable";
  static isBackgroundRemovalEnable = "isBackgroundRemovalEnable";
  static isBackgroundPlaceOrderEnable = "isBackgroundPlaceOrderEnable";
  static isApplyLoanEnable = "isApplyLoanEnable";
  static isHomeConfig = "isHomeConfig";

  static cfnIsCashOnDeliveryEnable = "isCashOnDeliveryEnable";
  static cfnIsAcledaEnable = "isAcledaEnable";
  static cfnIsABAEnable = "isABAEnable";
  static cfnIsPiPayEnable = "isPiPayEnable";
  static cfnIsMasterCardEnable = "isMasterCardEnable";
  static generalLimitCostFreeShipping = "generalLimitCostFreeShipping";
  static maxSameRegionDeliveryDistance = "maxSameRegionDeliveryDistance";
  static maxSameRegionDeliveryCost = "maxSameRegionDeliveryCost";
  static minSameRegionDeliveryCost = "minSameRegionDeliveryCost";
  static messageKh = "messageKh";
  static messageEn = "messageEn";



  // WhiteLabelSetting

  static bundleId = "bundleId";
  static iosAppId = "iosAppId";
  static appName = "appName";
  static domain = "domain";
  static logo = "logo";
  static theme = "theme";
  static payment = "payment";
  static accentColor = "accentColor";
  static primaryColor = "primaryColor";
  static buttonColor = "buttonColor";
  static scaffoldBackgroundColor = "scaffoldBackgroundColor";
  static cardColor = "cardColor";
  static dividerColor = "dividerColor";
  static errorColor = "errorColor";
  static bottomAppBarColor = "bottomAppBarColor";
  static backgroundColor = "backgroundColor";
  static iconColor = "iconColor";
  static gradientColors = "gradientColors";
  static whiteLabelSetting = "whiteLabelSetting";
  static khqrMerchantId = "khqrMerchantId";


}

import {INg2LoadingSpinnerConfig, ANIMATION_TYPES} from "ng2-loading-spinner";
/**
 * Created by cheaphakdey on 14/6/19.
 */

export class SpinnerUtil{
  static configSpinner(){
    let loadingConfig: INg2LoadingSpinnerConfig = {
      animationType  : ANIMATION_TYPES.halfCircle,
      backdropBorderRadius: '5px',
      spinnerSize: 'lg',
      spinnerFontSize: '2rem',
      spinnerColor: "#c0d6e4",
    };

    return loadingConfig;
  }

  static configSpinnerButtonCircle(){
    let loadingConfig: INg2LoadingSpinnerConfig = {
      animationType  : ANIMATION_TYPES.halfCircle,
      backdropBorderRadius: '20px',
      spinnerSize: 'lg',
      spinnerFontSize: '1rem',
      spinnerColor: "#c0d6e4",
    };

    return loadingConfig;
  }

  static configSpinnerButtonTriangle(){
    let loadingConfig: INg2LoadingSpinnerConfig = {
      animationType  : ANIMATION_TYPES.halfCircle,
      backdropBorderRadius: '5px',
      spinnerSize: 'lg',
      spinnerFontSize: '1rem',
      spinnerColor: "#c0d6e4",
    };

    return loadingConfig;
  }
}

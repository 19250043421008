import {Component, Input, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import {SharedPreference} from '../../shared-preference/shared-perference';
import {Role} from '../../model/role';
import {ChatsUtils} from '../../utils/chats-utils';
import {closeOrderListUrl, orderListUrl, searchOrderListUrl} from '../../inventory/route';
import {StringUtils} from '../../utils/string-utils';
import {LoggUtilInfo} from '../../utils/loggUtil';
import {ApiService} from '../../services/http-service/api.service';
import {OrderStatusUtil} from '../../utils/order-status-util';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.css'],

})
export class BreadcrumbComponent implements OnInit {

  showChat(): boolean {
    if(!SharedPreference.getUser()){
      return false;
    }
    return SharedPreference.getLogin() &&  Role.hasCustomerServiceRole(SharedPreference.getUser()) && !SharedPreference.getUser().isRequireChangePassword;
  }

  isAllowNotification() {

    if(!SharedPreference.getUser()){
      return false;
    }

    return  Role.hasVersitaAdmin(SharedPreference.getUser())   ||
            Role.hasAdminRole(SharedPreference.getUser())      ||
            Role.hasVersitaSystem(SharedPreference.getUser())  ||
            Role.hasSupervisorRole(SharedPreference.getUser()) ||
            Role.hasSupervisorRole(SharedPreference.getUser()) ||
            Role.hasCustomerServiceRole(SharedPreference.getUser()) ||
            Role.hasVersitaCustomerService(SharedPreference.getUser());
  }

  isAdmin() {
    return Role.hasAdminRole(SharedPreference.getUser());
  }

  @Input() layout;
  pageInfo;
  isWhiteLabelApp: boolean;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private apiService: ApiService
  ) {
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      .map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      })
      .filter(route => route.outlet === 'primary')
      .mergeMap(route => route.data)
      .subscribe(event => {
        let link = window.location.hash;
        let linkStatus = decodeURIComponent(link.substring(link.lastIndexOf('=') + 1));

        if(!this.onGetLink(encodeURIComponent(StringUtils.searchOrderList))){
          OrderStatusUtil.searchKeyword = null;
          OrderStatusUtil.searchOrderStatus = [];
        }

        if(!link.includes('from=detail')){
          if(this.onGetLink('from'+link.substring(link.lastIndexOf('=')))
            && !this.onGetLink(StringUtils.hrefOrderList)
            && !this.onGetLink('list') && !this.onGetLink('low')){
              event['urls'][0]['title'] = "Summary " + linkStatus + " List";
              event['urls'][0]['url'] = orderListUrl + "/" + linkStatus;
          }else if (this.onGetLink(StringUtils.hrefOrderList) && !this.onGetLink(encodeURIComponent(StringUtils.searchOrderList))) {
            if(StringUtils.openOrders.includes(StringUtils.fromEmptyToMinus(decodeURIComponent(link.substring(link.lastIndexOf('=') + 1, link.lastIndexOf("Orders")))))){
              event['urls'][0]['title'] = StringUtils.openOrderList;
              event['urls'][0]['url'] = orderListUrl;
            }else {
              event['urls'][0]['title'] = StringUtils.closeOrderList;
              event['urls'][0]['url'] = closeOrderListUrl;
            }
          }else if(this.onGetLink(encodeURIComponent(StringUtils.searchOrderList))){
            event['urls'][0]['title'] = StringUtils.searchOrderList;
            event['urls'][0]['url'] = searchOrderListUrl;
          }
        }
        this.titleService.setTitle(event['title']);
        this.pageInfo = event;
      });
  }

  onGetLink(string: string){
    return window.location.href.includes(string);
  }

  async ngOnInit() {
    if (this.isAdmin()) {
      await this.getPlatforms();
    }
  }

  getCounterUnread(){
    return ChatsUtils.counterUnread;
  }

  async getPlatforms() {
    try {
      const promise: any = await this.apiService.requestPlatforms(null, SharedPreference.getStoreId()).toPromise();
      let platformWhiteLabel = [];
      platformWhiteLabel.push(promise['result']);
      this.isWhiteLabelApp = platformWhiteLabel.length > 0;
    } catch (e) {
      LoggUtilInfo.loggingError("component breadcrumb notification get platform",e);
      return [];
    }
  }
}

import {Item, ItemVariation} from '../inventory/model/item';
import {ItemStock} from '../model/item-stock';
import {StringUtils} from './string-utils';
import {environment} from '../../environments/environment';

/**
 * Created by cheaphakdey on 11/3/19.
 */

export class ItemUtils{
  static item: Map<string, Item>;
  static itemStock: Map<string, ItemStock>;
  static lastUpdate: Date;

  static isNotTimeout(): boolean {
    return !this.isEmpty() && this.lastUpdateUtil(this.lastUpdate,environment.itemsTimeoutInSeconds);
  }

  static isEmpty(): boolean {
    return this.item === null || this.item === undefined;
  }

  static lastUpdateUtil(lastUpdate: Date, duration: any): boolean{
    let _lastUpdate = lastUpdate.getTime() + (duration * 1000);
    return lastUpdate !== null && (Date.now().toString() < _lastUpdate.toString());
  }

  static getNewTimeOut(status?: string) {
    ItemUtils.lastUpdate = new Date(Date.now());
    console.log(status ? status : '','Start Time at :', StringUtils.getTime(ItemUtils.lastUpdate));
    let lasDate = ItemUtils.lastUpdate.getTime() + (environment.itemsTimeoutInSeconds * 1000);
    console.log(status ? status : '','Timeout at :', StringUtils.getTime(new Date(parseInt(lasDate.toString()))));
  }

  static tagImage(img){
    if(img.length === 0)
      img = ["assets/images/background/default-placeholder.png"];
    return `<img width="${StringUtils.widthImg}" height="${StringUtils.heightImg}" src="${img[0]}">`;
  }

  static isTypeEmpty(item: Item){
    return Array.from(item.variation.values())
        .filter(v => v.type.value === undefined || v.type.value === "" || v.type.value == null)
        .length === item.variation.size;
  }

  static isTypeNotEmpty(item: Item){
    return Array.from(item.variation.values())
        .filter(v => v.type.value !== undefined && v.type.value !== "" && v.type.value !== null)
        .length === item.variation.size;
  }

  static isSizeEmpty(item: Item){
    return Array.from(item.variation.values())
        .filter(v => v.size.value === undefined || v.size.value === "" || v.size.value == null)
        .length === item.variation.size;
  }

  static isSizeNotEmpty(item: Item){
    return Array.from(item.variation.values())
        .filter(v => v.size.value !== undefined && v.size.value !== "" && v.size.value !== null)
        .length === item.variation.size;
  }

  static isColorEmpty(item: Item){
    return Array.from(item.variation.values())
        .filter(v => v.color.value === undefined || v.color.value === "" || v.color.value == null)
        .length === item.variation.size;
  }

  static isColorNotEmpty(item: Item){
    return Array.from(item.variation.values())
        .filter(v => v.color.value !== undefined && v.color.value !== "" && v.color.value !== null)
        .length === item.variation.size;
  }

  static isQtyPerCaseEmpty(item: Item){
    return Array.from(item.variation.values())
        .filter(v => v.qtyPerCase.value === undefined || v.qtyPerCase.value === "" || v.qtyPerCase.value == null)
        .length === item.variation.size;
  }

  static isQtyPerCaseNotEmpty(item: Item){
    return Array.from(item.variation.values())
        .filter(v => v.qtyPerCase.value !== undefined && v.qtyPerCase.value !== "" && v.qtyPerCase.value !== null)
        .length === item.variation.size;
  }

  static getItem(id: string){
    return Array.from(ItemUtils.item.values()).filter(i => i.barcodes.includes(id) || i.id === id);
  }

  static isEqualVariation(item: Item, variation: ItemVariation){
    if(item && item.type && item.type.toLowerCase() === StringUtils.services.toLowerCase()){
      return false;
    }else{
      return Array.from(item.variation.values())
        .filter(v =>
          v.barcode !== variation.barcode &&
          ((v.type.value ? v.type.value.toLowerCase() : v.type.value)
            === (variation.type.value ? variation.type.value.toLowerCase() : variation.type.value)) &&
          ((v.size.value ? v.size.value.toLowerCase() : v.size.value)
            === (variation.size.value ? variation.size.value.toLowerCase() : variation.size.value)) &&
          ((v.qtyPerCase.value ? v.qtyPerCase.value.toLowerCase(): v.qtyPerCase.value)
            === (variation.qtyPerCase.value ? variation.qtyPerCase.value.toLowerCase() : variation.qtyPerCase.value)) &&
          ((v.color.value ? v.color.value.toLowerCase() : v.color.value)
            === (variation.color.value ? variation.color.value.toLowerCase() : variation.color.value))
        ).length > 0;
    }
  }

  static clear(){
    if(this.item){
      this.item.clear();
    }
  }

  static totalItemSpecialByStoreId(storeId:  string) {
    let totalCount = 0;
    if (ItemUtils.item) {
      ItemUtils.item.forEach((value) =>{
        if ((value.storeId === storeId) && (value.saleType === StringUtils.sales || value.saleType === StringUtils.promotion)) {
          totalCount += 1;
        }
      });
      return totalCount;
    } else {
      return totalCount;
    }
  }
}

import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreIdService {

  private subject = new Subject<any>();

  onStoreIdChange(message: string, storeId: string, isSummaryStore?: boolean) {
    this.subject.next({ storeName: message, storeId: storeId, isSummaryStore: isSummaryStore});
  }

  onGetStoreId(): Observable<any> {
    return this.subject.asObservable();
  }
}

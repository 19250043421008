import {GetSafeValue} from "../utils/get-safe-value";
import {StringFieldUtil} from "../utils/string-field-util";
/**
 * Created by cheaphakdey on 11/7/19.
 */

export class DeliveryMethod{
  private _type: string;
  private _value: number;
  private _day: number;

  constructor(){};

  clone(other: DeliveryMethod) {
    this.type = other.type;
    this.value = other.value;
    this.day = other.day;
  }

  get day(): number {
    return this._day;
  }

  set day(value: number) {
    this._day = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get value(): number {
    return this._value;
  }

  set value(value: number) {
    this._value = value;
  }

  fromJson(data: DeliveryMethod) {
    this.type = GetSafeValue.getSafeValueAsString(
      data[StringFieldUtil.type],
      ""
    );
    this.value = GetSafeValue.getSafeValueAsNumber(
      data[StringFieldUtil.value],
      0
    );
    this.day = GetSafeValue.getSafeValueAsNumber(
      data[StringFieldUtil.day],
      0
    );
  }

  toMap() {
    return {
      'type': this.type,
      'day': this.day,
      'value': this.value
    };
  }
}

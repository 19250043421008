import {AfterViewInit, Component} from '@angular/core';
import {Router} from "@angular/router";
import {FirebaseService} from "./services/firebase.service";
import {UserService} from "./login/user-service";
import {SharedPreference} from "./shared-preference/shared-perference";
import {Idle} from '@ng-idle/core';
import {AuthenticationService} from './services/authentication.service';
import {SessionTimeout} from './utils/session-timeout';
import {StringUtils} from './utils/string-utils';
import {StoreUtils} from './utils/store-utils';
import {SystemConfigUtils} from './utils/system-config-utils';
import {Version} from "./utils/version";
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit{

  title = 'app';

  constructor(
    private idle: Idle,
    private _router: Router,
    private _firebaseService: FirebaseService,
    private _authenticationService: AuthenticationService
  ) {
    if (SharedPreference.getUser()) {
      UserService.getUserRole(this._firebaseService, this._router, SharedPreference.getUser().uid);
      SessionTimeout.sessionTimeout(idle, this._authenticationService, this._router);
      Version.isApprovedVersion(this._firebaseService, this._router);

      if (SharedPreference.getUser() && SharedPreference.getUser().storeIds.length > 0 &&
        (SharedPreference.getUser().roles.includes(StringUtils.roleAdmin) ||
          SharedPreference.getUser().roles.includes(StringUtils.roleSupervisor) ||
          SharedPreference.getUser().roles.includes(StringUtils.roleCustomerService))
        || SharedPreference.getUser().roles.includes(StringUtils.versitaStore)) {
        if (!SystemConfigUtils.getSubscribeDashboardOrderStatus() ||
          !SystemConfigUtils.getIsTermAndConditionEnable() ||
          !SystemConfigUtils.getCanUpdateCustomerUser()
        ){
          SystemConfigUtils.onGetSystemConfiguration(this._firebaseService);
        }

        if (!window.location.href.includes(StringUtils.login)
          && !window.location.href.includes(StringUtils.export)/*
          && !window.location.href.includes(StringUtils.dashboard)*/) {
          if (SharedPreference.getUser().roles.includes(StringUtils.internalUser)) {
            StoreUtils.onGetStoreForSetting(this._firebaseService, SharedPreference.getUser().currentStoreId);
          }
        }
      }
    }
  }

  ngAfterViewInit(): void {
    const apiKey = environment.firebase.apiKey;
    const src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    document.write(`<script src=${src}></script>`);
  }
}

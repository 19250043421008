import {VersitaStores} from '../model/versita-stores';
import {FirebaseService} from '../services/firebase.service';
import {SharedPreference} from '../shared-preference/shared-perference';
import {LoggUtilInfo} from './loggUtil';
import {StringUtils} from './string-utils';
import {Role} from '../model/role';
import {tryCatch} from 'rxjs/internal-compatibility';

export class StoreUtils {
  static versitaStore: Map<string, VersitaStores>;
  static versitaStoreCount: Map<string, any>;
  static store: VersitaStores = new VersitaStores();
  static selectStoreId: string;
  static selectStoreName: string;
  static storeName: string;

  static storeIdMap: Map<string, string>;

  static onSetValueToUtil(data) {
    StoreUtils.versitaStore = new Map();
    data.map(e => {
      if (e.exists) {
        let store = new VersitaStores();
        store.fromJson(e.data());
        StoreUtils.versitaStore.set(e.data().storeId,  store);
      }
    });
  }

  static onGetStoreForSetting(firebaseService: FirebaseService, storeId: string, callBack?: Function) {
    if(!storeId) return;

    let collection = FirebaseService.store;
    if(!StoreUtils.versitaStore){
      StoreUtils.versitaStore = new Map();
    }

    firebaseService.onReadDocument(collection, storeId).then((value => {
      if (value.exists) {
        let store: VersitaStores = new VersitaStores();
        store.fromJson(value.data() as VersitaStores);
        StoreUtils.store = store;
        StoreUtils.versitaStore.set(store.id, store);
        callBack ? callBack(store) : null;
        LoggUtilInfo.loggingEnd(StringUtils.readDocument + collection + "/" + storeId + ' component : versita store util');
      }
    })).catch((e) =>{
      LoggUtilInfo.loggingError('from versita store util',e.toString());
    });
  }

  static clearStore(){
    this.store = new VersitaStores();
  }

  static getStoreName() : string{
    try {
      return this.store.name;
    }catch (e) {
      return undefined;
    }
  };

  static isProcessingEnable() {
    try {
      return StoreUtils.store.businessSetting.isProcessingEnable;
    }catch (e) {
      return true;
    }
  }

  static isProcessedEnable() {
    try {
      return StoreUtils.store.businessSetting.isProcessedEnable;
    }catch (e) {
      return true;
    }
  }

  static isPackagingEnable() {
    try {
      return StoreUtils.store.businessSetting.isPackagingEnable;
    }catch (e) {
      return true;
    }
  }

  static isPackagedEnable() {
    try {
      return StoreUtils.store.businessSetting.isPackagedEnable;
    }catch (e) {
      return true;
    }
  }

  static isDeliveringEnable() {
    try {
      return StoreUtils.store.businessSetting.isDeliveringEnable;
    }catch (e) {
      return true;
    }
  }

  static isDeliveredEnable() {
    try {
      return StoreUtils.store.businessSetting.isDeliveredEnable;
    }catch (e) {
      return true;
    }
  }

  static isWhiteLabel() {
    try {
      return StoreUtils.store.isWhiteLabel && StoreUtils.store.whiteLabelSetting.bundleId;
    }catch (e) {
      return false;
    }
  }

  static isProcessingEnableFrom(storeId: string) {
    try {
      return StoreUtils.versitaStore[storeId].businessSetting.isProcessingEnable;
    }catch (e) {
      return true;
    }
  }

  static isProcessedEnableFrom(storeId: string) {
    try {
      return StoreUtils.versitaStore[storeId].businessSetting.isProcessedEnable;
    }catch (e) {
      return true;
    }
  }

  static isPackagingEnableFrom(storeId: string) {
    try {
      return StoreUtils.versitaStore[storeId].businessSetting.isPackagingEnable;
    }catch (e) {
      return true;
    }
  }

  static isPackagedEnableFrom(storeId: string) {
    try {
      return StoreUtils.versitaStore[storeId].businessSetting.isPackagedEnable;
    }catch (e) {
      return true;
    }
  }

  static isDeliveringEnableFrom(storeId: string) {
    try {
      return StoreUtils.versitaStore[storeId].businessSetting.isDeliveringEnable;
    }catch (e) {
      return true;
    }
  }

  static isDeliveredEnableFrom(storeId: string) {
    try {
      return StoreUtils.versitaStore[storeId].businessSetting.isDeliveredEnable;
    }catch (e) {
      return true;
    }
  }

  static isCustomerDetailEnable() {
    try {
      return StoreUtils.store.businessSetting.isCustomerDetailEnable;
    } catch (e) {
      return false;
    }
  }

  static storeCategoriesType() {
    try {
      return StoreUtils.store.businessSetting.categoriesType;
    } catch (e) {
      return [];
    }
  }

  static isPosEnable() {
    try {
      return StoreUtils.store.businessSetting.isPos;
    } catch (e) {
      return false;
    }
  }

  static isHomeConfig() {
    try {
      return StoreUtils.store.businessSetting.isHomeConfig;
    } catch (e) {
      return false;
    }
  }

  static isItemLabelExportEnable() {
    try {
      return StoreUtils.store.businessSetting.isItemLabelExportEnable;
    } catch (e) {
      return false;
    }
  }

  static getStoreType(){
    try {
      if(StoreUtils.store.businessSetting.type.length < 1){
        return StringUtils.shopping.toLowerCase();
      }
      return String.prototype.toLowerCase.apply(StoreUtils.store.businessSetting.type).split(",");
    }catch (e) {
      return StringUtils.shopping.toLowerCase();
    }
  }


  static  getSelectStoreId(): string {
    return this.selectStoreId;
  }

  static  setSelectStoreId(value: string) {
    this.selectStoreId = value;
    SharedPreference.setStoreId(this.selectStoreId);
  }

  static  getSelectStoreName(): string {
    return this.selectStoreName;
  }

  static  setSelectStoreName(value: string) {
    console.log("value", value);
    this.selectStoreName = value;
  }

  static clearSelectStoreId(){
    this.selectStoreId = undefined;
  }

  public static getStoreNameAndId(): Map<string,string> {
    let storeName: Map<string, string>;
    storeName = new Map<string, string>();
    if (Role.hasVersitaUser(SharedPreference.getUser())) {
      if(StoreUtils.storeName && StoreUtils.storeName.includes('-')){
        storeName.set(StringUtils.keyStoreName, StoreUtils.storeName.substring(0, StoreUtils.storeName.lastIndexOf("-")));
        storeName.set(StringUtils.keyStoreId, StoreUtils.storeName.substring(StoreUtils.storeName.lastIndexOf("-") + 1));
        return storeName;
      }else {
        storeName.set(StringUtils.keyStoreName, StoreUtils.storeName);
        return storeName;
      }
    }else {
      return storeName;
    }
  }


}

import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageServiceService {

  constructor() { }

  private subject = new Subject<any>();

  onChangeLanguage(language: string) {
    this.subject.next({language: language});
  }

  onGetLanguage(): Observable<any> {
    return this.subject.asObservable();
  }
}

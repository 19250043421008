import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Category} from '../../model/category';
import {Role} from '../../../model/role';
import {SharedPreference} from '../../../shared-preference/shared-perference';
import {categoryCreate} from '../../route';
import {StringUtils} from '../../../utils/string-utils';
import {CategoryUtils} from '../../../utils/category-utils';
import {SystemConfigUtils} from '../../../utils/system-config-utils';
import {LocalDataSource} from 'ng2-smart-table';
import {NumberUtils} from '../../../utils/number-utils';
import {MethodUtil} from '../../../utils/method-util';
import {SpinnerUtil} from "../../../utils/spinner-util";
import {FirebaseService} from "../../../services/firebase.service";
import {CategoryService} from "../category-service";
import {RoleUtils} from '../../../utils/role-utils';
import {StoreUtils} from '../../../utils/store-utils';
import {StoreIdService} from '../../../services';

@Component({
  selector: 'app-category',
  templateUrl: 'category.list.component.html',
  styleUrls: ['category.list.component.css']
})
export class CategoryListComponent implements OnInit {
  uiCategories: UICategory[];
  source: LocalDataSource;
  totalCategories : string = '';
  path = FirebaseService.category + '/' + FirebaseService.categoryCollection;
  currentPage: number;
  showSpinner: boolean;
  isSingleStore: boolean = false;
  readingSystemConfig: boolean;
  settings = null;
  loadingConfig = SpinnerUtil.configSpinner();

  temStoreId: string;
  temStoreName: string;
  currentStoreId: string;
  currentStoreName: string;
  storeIdFromHeader$: any = null;


  constructor(
    private router: Router,
    private firebaseService: FirebaseService,
    private storeIdService: StoreIdService,
  ) {}

  ngOnInit() {
    MethodUtil.clearStoreNameOnNavBar();

    this.temStoreId = StoreUtils.getSelectStoreId();
    this.temStoreName = StoreUtils.getSelectStoreName();
    if (StoreUtils.getSelectStoreId()) {
      this.currentStoreId = StoreUtils.getSelectStoreId();
    }
    this.storeIdFromHeader$ = this.storeIdService.onGetStoreId();

    if(!SystemConfigUtils.getIsInventoryDeleteEnable()){
      SystemConfigUtils.onGetSystemConfiguration(this.firebaseService);
    }
    if(!this.currentPage){
      this.source = new LocalDataSource();
    }
    this.getData();

    if (Role.hasInternalUser(SharedPreference.getUser())) {
      this.getIsSingleStore();
    }

    if (Role.hasVersitaAdmin(SharedPreference.getUser()) || Role.hasVersitaCustomerService(SharedPreference.getUser()) || Role.hasVersitaSupervisor(SharedPreference.getUser())) {
      this.settings = CategoryUtils.getSetting(false); //todo: Versita Admin should change settings here for next ticket @srouch last changes
    }

    if (Role.hasVersitaAdmin(SharedPreference.getUser())) {
      this.hideColumnItemCountActive();
    }

  }

  hideColumnItemCountActive() {
    delete this.settings.columns.itemCountActive;
  }

  getData(): void {
    this.readCategory();
  }

  async onChangeStoreIdAndName(storeId: string, storeName: string) {
    if (this.temStoreId && !storeId) {
      this.currentStoreId = this.temStoreId;
      this.currentStoreName = this.temStoreName;
    } else  {
      this.currentStoreId = storeId;
      this.currentStoreName = storeName;
      if(this.temStoreId !== storeId){
        this.temStoreId = storeId;
        this.temStoreName = storeName;
        StoreUtils.setSelectStoreId(this.currentStoreId);
        this.getData();
      }
    }
  }

  private sortCategory() {
    let categories = Array.from(this.uiCategories)
      .sort((a: Category, b: Category) => (a.modifiedAt && b.modifiedAt)
        ? StringUtils.getDate24H(b.modifiedAt).localeCompare(StringUtils.getDate24H(a.modifiedAt))
        : null
      );

    let self = this;
    this.source.load(categories);
    this.source.setPaging(NumberUtils.page, NumberUtils.perPage, true);
    if (this.currentPage) {
      this.source.setPage(this.currentPage, true);
    }
    this.totalCategories = (StringUtils.getTotalData(this.source, categories)).toString();
    StringUtils.onSourceChange(this.source, categories, this.totalCategories, (total)=>{
      self.totalCategories = total;
    });
    self.showSpinner = false;
  }

  create(): void {
    this.router.navigate([categoryCreate]);
  }

  update(event) {
    this.router.navigate([categoryCreate, event['data'].id]);
  }

  hasPermission(): boolean {
    return (Role.hasAdminRole(SharedPreference.getUser()) && (this.isSingleStore || StoreUtils.isHomeConfig())) || Role.hasVersitaAdmin(SharedPreference.getUser());
  }


  onCustomAction(event) {
    this.currentPage = this.source.getPaging().page;
    let category = event['data'];
    category.modifiedAt = StringUtils.getDateLocal(StringUtils.getDateUTC(new Date()));

    if (event.action === 'enable') {
      if (!category.isEnable){
        this.showSpinner = true;
        category.isEnable = true;
        this.updateEnableDisable(category);
      }
    } else {
      if (category.isEnable){
        this.showSpinner = true;
        category.isEnable = false;
        this.updateEnableDisable(category);
      }
    }
  }

  private getIsSingleStore() {
    if (!SystemConfigUtils.getIsSingleStoreBCSetting()) {
      this.readingSystemConfig = true;
      SystemConfigUtils.onGetSystemConfiguration(this.firebaseService, (_) =>{
        this.readingSystemConfig = false;
        this.isSingleStore = SystemConfigUtils.getIsSingleStoreBCSetting();
        this.settings = CategoryUtils.getSetting(this.isSingleStore);
      })
    } else {
      this.isSingleStore = SystemConfigUtils.getIsSingleStoreBCSetting();
      this.settings = CategoryUtils.getSetting(this.isSingleStore);
    }
  }

  isHasReadOnlyRole() {
    return RoleUtils.hasReadOnlyRole();
  }

  private updateEnableDisable(category: Category) {
    let self = this;
    let data = Category.clone(category);
    this.firebaseService.updateFieldIsEnable(this.path, data.isEnable, data.id+".isEnable").then((_) => {
      self.showSpinner = false;
      MethodUtil.informMessage(StringUtils.categorySaved);
      this.source.update(category, category);
      CategoryUtils.category.set(data.id, data);
    });
  }

  private readCategory(){
    this.showSpinner = true;
    CategoryService.onGetCategory(this.firebaseService,()=>{
      this.renderCategory();
      this.showSpinner = false;
    }, ()=>{
      this.showSpinner = false;
    });
  }

  private renderCategory(){
    this.uiCategories = [];

    if(CategoryUtils.category && CategoryUtils.category.size > 0) {
      Array.from(CategoryUtils.category.values()).forEach((category) => {
        if(!CategoryUtils.isItemCountEmpty()) {
          if (Array.from(CategoryUtils.itemCount.keys()).filter(k => k === category.id).length > 0) {
            this.uiCategories.push(CategoryUtils.addUICategory(category));
          }else {
            CategoryUtils.setTotalActiveItemCount(category.id, 0, 0);
            this.uiCategories.push(CategoryUtils.addUICategory(category));
          }
        }else {
          CategoryUtils.setTotalActiveItemCount(category.id, 0, 0);
          this.uiCategories.push(CategoryUtils.addUICategory(category));
        }
      });
    }

    this.sortCategory();
  }
}

export class UICategory extends Category{
  itemCountTotal: number;
  itemCountActive: number;

  constructor(
    id: string, pid: string, title: Map<any, any>, imageUrls: string[], isEnable: boolean, modifiedAt: string,
    itemCountTotal: number, itemCountActive: number
  ) {
    super();
    this.id = id;
    this.title = title;
    this.imageUrls = imageUrls;
    this.isEnable = isEnable;
    this.modifiedAt = modifiedAt;
    this.itemCountTotal = itemCountTotal;
    this.itemCountActive = itemCountActive;
    this.pid = pid;
  }
}

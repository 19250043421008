import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {User} from '../model/user';
import {SharedPreference} from '../shared-preference/shared-perference';
import {Role} from '../model/role';
import {ApiService} from '../services/http-service/api.service';
import {LoggUtilInfo} from '../utils/loggUtil';

@Injectable({
  providedIn: 'root'
})
export class NotificationGuard implements CanActivate {
  constructor() {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    return Role.hasVersitaAdmin(SharedPreference.getUser())   ||
      Role.hasAdminRole(SharedPreference.getUser())      ||
      Role.hasVersitaSystem(SharedPreference.getUser())  ||
      Role.hasSupervisorRole(SharedPreference.getUser()) ||
      Role.hasSupervisorRole(SharedPreference.getUser()) ||
      Role.hasCustomerServiceRole(SharedPreference.getUser()) ||
      Role.hasVersitaCustomerService(SharedPreference.getUser())
      ;

  }
}

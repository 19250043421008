import {GetSafeValue} from "../utils/get-safe-value";

export class TagConfi {
  private _title: Map<string,string>;
  private _icon: string;

  constructor() {
    this.title = new Map();
  }

  fromJson(data: TagConfi) {
    this.title = GetSafeValue.getSafeValueAsMap(data["title"], {"en" : "", "kh" : ""});
    this.icon = GetSafeValue.getSafeValueAsString(data["icon"], "");
  }


  get title(): Map<string, string> {
    return this._title;
  }

  set title(value: Map<string, string>) {
    this._title = value;
  }

  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
    let g = "";
    g.toString()
  }

  toMap() {
    return {
      'title': {
        'en': this.title['en'] ? this.title['en'].toString().trim() : "",
        'kh': this.title['kh'] ? this.title['kh'].toString().trim() : ""
      },
      "icon" : this.icon
    };
  }
}

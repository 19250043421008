import {FirebaseService} from "../services/firebase.service";
import {Router} from "@angular/router";
import {Subscription} from "rxjs";
import {SubscriptionUtils} from "./subscriptionUtils";
import {SharedPreference} from "../shared-preference/shared-perference";
import {environment} from "../../environments/environment";
import {LoggUtilInfo} from "./loggUtil";
import {StringUtils} from "./string-utils";
import {RoleUtils} from './role-utils';
import {Role} from '../model/role';

export class Version {

  private  static subscriptionUtils: SubscriptionUtils = new SubscriptionUtils();


  static isApprovedVersion(firebaseService: FirebaseService, router: Router){
    this.getVersion(firebaseService, router);
  }


   static getVersion(firebaseService: FirebaseService, router: Router) {
    let subscriptionName: string = FirebaseService.version +"/"+ FirebaseService.versionCollection;
    if(this.subscriptionUtils.isNotAlreadySubscribe(subscriptionName)) {
      let subscription: Subscription = firebaseService
        .onGetDataById(FirebaseService.version, FirebaseService.versionCollection)
        .subscribe(data => {
          LoggUtilInfo.loggingListen(StringUtils.listenSnapshot + subscriptionName, ` component : ApprovedVersion`);
          LoggUtilInfo.loggingInfo("currentVersion : ", data.version);
          LoggUtilInfo.loggingInfo("softwareVersion : ",  environment.softwareVersion);

          if(environment.softwareVersion != data.version && !Role.hasVersitaUser(SharedPreference.getUser())){
            LoggUtilInfo.loggingInfo(StringUtils.systemUnderMaintenance);
            if (SharedPreference.getUser()) {
              SharedPreference.clearUser();
              SharedPreference.setIsUserLogin(false);
            }
            SharedPreference.isShowingMenu = false;
            if (environment.isEnableHomePage) {
              router.navigate(['/home-page']);
            } else {
              router.navigate(['/login']);
            }

            window.location.reload();
          }
          LoggUtilInfo.loggingEnd(StringUtils.listenSnapshot + subscriptionName, ` component : ApprovedVersion`);
          }
        );
      this.subscriptionUtils.subscribe(subscriptionName, subscription);
    }
  }
}

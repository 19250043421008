export class NumberUtils {
  static maxWidthHeightImage : number = 1024;
  static maxSizeImage: number = 900000;
  static numPad = 6;
  static waitTimer = 2500;
  static page: number = 1;
  static perPage: number = 12;
  static currentTotalInternalUser = undefined;
  static countDownNumber: number = 3;

  static nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "K" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

}

import {Address} from './address';
import {GetSafeValue} from '../utils/get-safe-value';
import {StringFieldUtil} from '../utils/string-field-util';
import {StringUtils} from '../utils/string-utils';
import {Role} from './role';
import {SharedPreference} from '../shared-preference/shared-perference';

/**
 * Created by vmc on 18/1/19.
 */
export class User {
  private _id: string;
  private _uid: string;
  private _firstName: string;
  private _lastName: string;
  private _email: string;
  private _companyName: string;
  private _imageUrl: string;
  private _roles: string[];
  private _devTokens: string[];
  private _gender: string;
  private _address: Address;
  private _oldPassword: string;
  private _newPassword: string;
  private _confirmPassword: string;
  private _phoneNumber: Map<string, string>;
  private _createdAt: string;
  private _signedInAt: string;
  private _isEnable: boolean;
  private _isDelete: boolean;
  private _isRequireChangePassword: boolean;
  private _storeIds: string[];
  private _currentStoreId: string;
  private _loginVia: string;
  private _bankIds: string[];
  private _balance: number;

  constructor(){
    this.address = new Address();
    this.phoneNumber = new Map();
    this.phoneNumber.set(StringUtils.code, "+855");
    this.phoneNumber.set('isoCode', 'kh');
    this.companyName = '';
    this.address.country = '';
    this.roles = [];
    this.uid = '';
    this.id = '';
    this.devTokens = [];
    this.imageUrl = "";
    this.gender = "";
    this.email = "";
    this.createdAt = "";
    this.signedInAt = "";
    this.isEnable = true;
    this.isDelete = false;
    this.firstName = "";
    this.lastName = "";
    this.storeIds = [];
    this.bankIds = [];
    this.currentStoreId = "";
    this.newPassword = "";
  }

  clone(user: User){
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    let add: Address = new Address();
    add.clone(user.address);
    this.address = add;
    this.phoneNumber = user.phoneNumber;
    this.companyName = user.companyName;
    this.roles = user.roles === undefined ? [] : user.roles;
    this.uid = user.uid;
    this.id = user.id;
    this.devTokens = user.devTokens;
    this.imageUrl = user.imageUrl;
    this.gender = user.gender;
    this.createdAt = user.createdAt;
    this.signedInAt = user.signedInAt;
    this.isEnable = user.isEnable;
    this.isDelete = user.isDelete;
    this.isRequireChangePassword = user.isRequireChangePassword;
    this.storeIds = user.storeIds ? user.storeIds : [];
    this.bankIds = user.bankIds ? user.bankIds : [];
    this.newPassword = user.newPassword;
    this.currentStoreId = user.currentStoreId;
    this.loginVia = user.loginVia;
    this.balance = user.balance;
  }

  get currentStoreId(): string {
    return this._currentStoreId;
  }

  set currentStoreId(value: string) {
    this._currentStoreId = value;
  }

  get storeIds(): string[] {
    return this._storeIds;
  }

  set storeIds(value: string[]) {
    this._storeIds = value;
  }

  get loginVia(): string {
    return this._loginVia;
  }

  set loginVia(value: string) {
    this._loginVia = value;
  }

  get isRequireChangePassword(): boolean {
    return this._isRequireChangePassword;
  }

  set isRequireChangePassword(value: boolean) {
    this._isRequireChangePassword = value;
  }

  get isEnable(): boolean {
    return this._isEnable;
  }

  set isEnable(value: boolean) {
    this._isEnable = value;
  }

  get isDelete(): boolean {
    return this._isDelete;
  }

  set isDelete(value: boolean) {
    this._isDelete = value;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }

  get signedInAt(): string {
    return this._signedInAt;
  }

  set signedInAt(value: string) {
    this._signedInAt = value;
  }

  get phoneNumber(): Map<string, string> {
    return this._phoneNumber;
  }

  set phoneNumber(value: Map<string, string>) {
    this._phoneNumber = value;
  }

  getFullName():string{
    return this._firstName +" "+ this._lastName;
  }

  getPhoneNumber():string{
    return this._phoneNumber.get('code') +" "+ this._phoneNumber.get('phone');
  }

  getFullAddress():string{
    try {
      let value = this._address.addressLineOne + " " + this._address.city +","+ this._address.country;
      if(value.toString().includes("null"))
        return "";
      return value;
    }catch (e) {
        return "";
    }
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get uid(): string {
    return this._uid;
  }

  set uid(value: string) {
    this._uid = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get companyName(): string {
    return this._companyName;
  }

  set companyName(value: string) {
    this._companyName = value;
  }

  get imageUrl(): string {
    return this._imageUrl;
  }

  set imageUrl(value: string) {
    this._imageUrl = value;
  }


  get roles(): string[] {
    return this._roles;
  }

  set roles(value: string[]) {
    this._roles = value;
  }

  get devTokens(): string[] {
    return this._devTokens;
  }

  set devTokens(value: string[]) {
    this._devTokens = value;
  }

  get gender(): string {
    return this._gender;
  }

  set gender(value: string) {
    this._gender = value;
  }

  get address(): Address {
    return this._address;
  }

  set address(value: Address) {
    this._address = value;
  }

  get oldPassword(): string {
    return this._oldPassword;
  }

  set oldPassword(value: string) {
    this._oldPassword = value;
  }

  get newPassword(): string {
    return this._newPassword;
  }

  set newPassword(value: string) {
    this._newPassword = value;
  }

  get confirmPassword(): string {
    return this._confirmPassword;
  }

  set confirmPassword(value: string) {
    this._confirmPassword = value;
  }

  get bankIds(): string[] {
    return this._bankIds;
  }

  set bankIds(value: string[]) {
    this._bankIds = value;
  }


  get balance(): number {
    return this._balance;
  }

  set balance(value: number) {
    this._balance = value;
  }

  fromJson(key: string, data: User){
    this.id = key;
    this.uid = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.uid], null);
    this.firstName = GetSafeValue.getSafeValueAsString( data[StringFieldUtil.firstName], null);
    this.lastName = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.lastName], null);
    this.email = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.email], null);
    this.loginVia = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.loginVia], null);
    let phone = GetSafeValue.getSafeValueAsMap(data[StringFieldUtil.phoneNumber], {'code': null, 'isoCode': null,'phone' : null});
    if(phone){
      this.phoneNumber = StringUtils.objToStrMap(phone);
    }
    this.companyName = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.companyName], null);
    this.isEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isEnable], false);
    this.gender = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.gender], null);
    this.createdAt = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.createdAt], null);
    this.signedInAt = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.singedInAt], null);
    this.imageUrl = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.imageUrl], '');
    this.roles = GetSafeValue.getSafeValueAsList(data[StringFieldUtil.roles], [], []);
    this.isDelete = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isDelete], false);
    this.storeIds = GetSafeValue.getSafeValueAsList(data[StringFieldUtil.storeIds], [], []);
    this.bankIds = GetSafeValue.getSafeValueAsList(data[StringFieldUtil.bankIds], [], []);
    this.isRequireChangePassword = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isRequireChangePassword], false);
    this.newPassword = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.newPassword], null);
    if (data[StringFieldUtil.address]){
      this.address.fromJson(data[StringFieldUtil.address]);
    }
    if (data[StringFieldUtil.devTokens]){
      this.devTokens = GetSafeValue.getSafeValueAsList(data[StringFieldUtil.devTokens], [], []);
    }
  }

  isUserIsCustomer(): boolean {
    return this.roles.includes(StringUtils.customer);
  }

  isVersitaUser(): boolean {
    return this.roles.includes(StringUtils.versitaUser)
  }

  toMap() {
    if (this.isUserIsCustomer()) {
      return this.toMapForCustomer();
    } else if (this.isVersitaUser()) {
      return this.toMapForVersitaUser();
    } else {
      return this.toMapForInternalUser();
    }

  }

  private toMapForCustomer() {
    return {
      'firstName': this.firstName,
      'lastName': this.lastName,
      "phoneNumber": this.phoneNumber.get('phone') === undefined ? {} : {
        'phone': this.phoneNumber.get('phone'),
        'code': this.phoneNumber.get('code'),
        'isoCode': this.phoneNumber.get('isoCode')
      },
      'email': this.email,
      'companyName': this.companyName,
      'imageUrl': this.imageUrl,
      'address': this.address.toMap(),
      'roles': this.roles,
      'devTokens': this.devTokens,
      'uid': this.uid,
      'gender': this.gender,
      'createdAt': this.createdAt,
      'singedInAt': this.signedInAt,
      'isEnable': this.isEnable,
      'isDelete': this.isDelete,
      'storeIds': this.storeIds,
      'bankIds': this.bankIds,
      'loginVia': this.loginVia,
    };
  }

  private toMapForInternalUser() {
    return {
      'firstName': this.firstName,
      'lastName': this.lastName,
      "phoneNumber": this.phoneNumber.get('phone') === undefined ? {} : {
        'phone': this.phoneNumber.get('phone'),
        'code': this.phoneNumber.get('code'),
        'isoCode': this.phoneNumber.get('isoCode')
      },
      'email': this.email,
      'companyName': this.companyName,
      'imageUrl': this.imageUrl,
      'address': this.address.toMap(),
      'roles': this.roles,
      'devTokens': this.devTokens,
      'uid': this.uid,
      'gender': this.gender,
      'createdAt': this.createdAt,
      'singedInAt': this.signedInAt,
      'isEnable': this.isEnable,
      'isDelete': this.isDelete,
      'storeIds': this.storeIds,
      'bankIds': this.bankIds,
      'isRequireChangePassword': this.isRequireChangePassword,
      'newPassword': this.newPassword,
      'loginVia': this.loginVia,
    };
  }

  private toMapForVersitaUser() {
    return {
      'firstName': this.firstName,
      'lastName': this.lastName,
      "phoneNumber": this.phoneNumber.get('phone') === undefined ? {} : {
        'phone': this.phoneNumber.get('phone'),
        'code': this.phoneNumber.get('code'),
        'isoCode': this.phoneNumber.get('isoCode')
      },
      'email': this.email,
      'companyName': this.companyName,
      'imageUrl': this.imageUrl,
      'address': this.address.toMap(),
      'roles': this.roles,
      'devTokens': this.devTokens,
      'uid': this.uid,
      'gender': this.gender,
      'createdAt': this.createdAt,
      'singedInAt': this.signedInAt,
      'isEnable': this.isEnable,
      'isDelete': this.isDelete,
      'storeIds': this.storeIds,
      'bankIds': this.bankIds,
      'isRequireChangePassword': this.isRequireChangePassword,
      'loginVia': this.loginVia,
    };
  }

  toMapForSharePref() {
    return {
      'firstName': this.firstName,
      'lastName': this.lastName,
      "phoneNumber": this.phoneNumber.get('phone') === undefined ? {} : {
        'phone': this.phoneNumber.get('phone'),
        'code': this.phoneNumber.get('code'),
        'isoCode': this.phoneNumber.get('isoCode')
      },
      'email': this.email,
      'companyName': this.companyName,
      'imageUrl': this.imageUrl,
      'address': this.address.toMap(),
      'roles': this.roles,
      'devTokens': this.devTokens,
      'uid': this.uid,
      'gender': this.gender,
      'createdAt': this.createdAt,
      'singedInAt': this.signedInAt,
      'isEnable': this.isEnable,
      'isDelete': this.isDelete,
      'storeIds': this.storeIds,
      'bankIds': this.bankIds,
      'isRequireChangePassword': this.isRequireChangePassword,
      'newPassword': this.newPassword,
      'currentStoreId': this.currentStoreId || null,
      'loginVia': this.loginVia,
    };
  }

}

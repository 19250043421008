import {GetSafeValue} from '../utils/get-safe-value';
import {StringFieldUtil} from '../utils/string-field-util';
import {BusinessSetting, UserStore} from './user-store';
import {Address} from "./address";
import {WhiteLabelSetting} from "./white_label_setting";
import {ControlFormNameUtil} from "../utils/string-control-form-name";
import {Payment} from "./payment";
import {Role} from "./role";
import {SharedPreference} from "../shared-preference/shared-perference";

export class VersitaStores {

  private _id: string;
  private _name: string;
  private _imageUrl: string[];
  private _address: Address;
  private _storeOwner: UserStore;
  private _storeAdmin: UserStore;
  private _modifiedAt: string;
  private _businessSetting: BusinessSetting;
  private _contractSetting: ContractSetting;
  private _isEnable: boolean;
  private _isWhiteLabel: boolean;
  private _whiteLabelSetting : WhiteLabelSetting;
  private _payment : Payment;

  get isEnable(): boolean {
    return this._isEnable;
  }

  set isEnable(value: boolean) {
    this._isEnable = value;
  }


  get isWhiteLabel(): boolean {
    return this._isWhiteLabel;
  }

  set isWhiteLabel(value: boolean) {
    this._isWhiteLabel = value;
  }


  get whiteLabelSetting(): WhiteLabelSetting {
    return this._whiteLabelSetting;
  }

  set whiteLabelSetting(value: WhiteLabelSetting) {
    this._whiteLabelSetting = value;
  }

  constructor() {
    this.name = "";
    this.address = new Address();
    this.imageUrl = [];
    this.storeOwner = new UserStore();
    this.storeAdmin = new UserStore();
    this.businessSetting = new BusinessSetting();
    this.contractSetting = new ContractSetting();
    this.whiteLabelSetting = new WhiteLabelSetting();
    this.payment = new Payment();
  }


  clone(data: VersitaStores){
    this.id = data.id;
    this.name = data.name;
    this.address.clone(data.address);
    this.imageUrl = data.imageUrl;
    this.storeOwner.clone(data.storeOwner);
    this.storeAdmin.clone(data.storeAdmin);
    this.businessSetting.clone(data.businessSetting);
    this.contractSetting.clone(data.contractSetting);
    this.modifiedAt = data.modifiedAt;
    this.isEnable = data.isEnable;
    this.isWhiteLabel = data.isWhiteLabel;
    this.whiteLabelSetting = data.whiteLabelSetting;
    this.payment = data.payment;
  }

  fromJson(data: VersitaStores){
    this.address = new Address();
    this.id = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.storeId], "");
    this.name = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.storeName], "");
    this.address.fromJson(data[StringFieldUtil.address]);
    this.modifiedAt = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.modifiedAt], "");
    this.imageUrl = GetSafeValue.getSafeValueAsList(data[StringFieldUtil.imageUrl], [],[]);
    if (data[StringFieldUtil.isEnable]) {
      this.isEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isEnable], false);
    } else if (data[StringFieldUtil.isEnable] === undefined) {
      this.isEnable = true;
    }

    if (data[StringFieldUtil.isWhiteLabel]) {
      this.isWhiteLabel = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isWhiteLabel], false);
    } else if (data[StringFieldUtil.isWhiteLabel] === undefined) {
      this.isWhiteLabel = false;
    }

    if (data[StringFieldUtil.storeOwner]){
      this.storeOwner.fromJson(data[StringFieldUtil.storeOwner]);
    }
    if (data[StringFieldUtil.storeAdmin]){
      this.storeAdmin.fromJson(data[StringFieldUtil.storeAdmin]);
    }
    if (data[StringFieldUtil.businessSetting]){
      this.businessSetting = new BusinessSetting();
      this.businessSetting.fromJson(data[StringFieldUtil.businessSetting]);
    }
    if (data[ControlFormNameUtil.whiteLabelSetting]){
      this.whiteLabelSetting = new WhiteLabelSetting();
      this.whiteLabelSetting.fromJson(data[ControlFormNameUtil.whiteLabelSetting]);
    }
    if (data[StringFieldUtil.contractSetting]){
      this.contractSetting = new ContractSetting();
      this.contractSetting.fromJson(data[StringFieldUtil.contractSetting]);
    }


    if(data[ControlFormNameUtil.payment]){
      this.payment = new Payment();
      this.payment.clone(data[ControlFormNameUtil.payment]);
    }
  }


  get contractSetting(): ContractSetting {
    return this._contractSetting;
  }

  set contractSetting(value: ContractSetting) {
    this._contractSetting = value;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }


  get storeOwner(): UserStore {
    return this._storeOwner;
  }

  set storeOwner(value: UserStore) {
    this._storeOwner = value;
  }

  get storeAdmin(): UserStore {
    return this._storeAdmin;
  }

  set storeAdmin(value: UserStore) {
    this._storeAdmin = value;
  }

  get modifiedAt(): string {
    return this._modifiedAt;
  }

  set modifiedAt(value: string) {
    this._modifiedAt = value;
  }

  get imageUrl(): string[] {
    return this._imageUrl;
  }

  set imageUrl(value: string[]) {
    this._imageUrl = value;
  }

  get address(): Address {
    return this._address;
  }

  set address(value: Address) {
    this._address = value;
  }

  get businessSetting(): BusinessSetting {
    return this._businessSetting;
  }

  set businessSetting(value: BusinessSetting) {
    this._businessSetting = value;
  }


  get payment(): Payment {
    return this._payment;
  }

  set payment(value: Payment) {
    this._payment = value;
  }

  toMap(isEdite: boolean) {
    if(Role.hasVersitaSale(SharedPreference.getUser()) && isEdite){
      return {
        "storeId" : this.id,
        "storeName" : this.name,
        "imageUrl": this.imageUrl,
        "address" :this.address.toMap(),
        "modifiedAt" :this.modifiedAt,
        "storeOwner" : this.storeOwner.toMap(),
        "storeAdmin" : this.storeAdmin.toMap(),
        "businessSetting" : this.businessSetting.toMap(),
        "whiteLabelSetting" : this.whiteLabelSetting.toMap(),
        "contractSetting" : this.contractSetting.toMap(),
        "isEnable": this.isEnable,
        "isWhiteLabel": this.isWhiteLabel,
      };
    }else {
      return {
        "storeId" : this.id,
        "storeName" : this.name,
        "imageUrl": this.imageUrl,
        "address" :this.address.toMap(),
        "modifiedAt" :this.modifiedAt,
        "storeOwner" : this.storeOwner.toMap(),
        "storeAdmin" : this.storeAdmin.toMap(),
        "businessSetting" : this.businessSetting.toMap(),
        "whiteLabelSetting" : this.whiteLabelSetting.toMap(),
        "contractSetting" : this.contractSetting.toMap(),
        "isEnable": this.isEnable,
        "isWhiteLabel": this.isWhiteLabel,
        'payment': this.payment ? this.payment.toMap() : new Payment(),
      };
    }
  }

}

export class ContractSetting {
  private _transactionRate: number;
  private _membershipFee: number;
  private _contractStartDate: string;
  private _contractEndDate: string;

  constructor() {
    this.transactionRate = 0;
    this.membershipFee = 0;
    this.contractStartDate = "";
    this.contractEndDate = "";
  }

  get transactionRate(): number {
    return this._transactionRate;
  }

  set transactionRate(value: number) {
    this._transactionRate = value;
  }

  get membershipFee(): number {
    return this._membershipFee;
  }

  set membershipFee(value: number) {
    this._membershipFee = value;
  }

  get contractStartDate(): string {
    return this._contractStartDate;
  }

  set contractStartDate(value: string) {
    this._contractStartDate = value;
  }

  get contractEndDate(): string {
    return this._contractEndDate;
  }

  set contractEndDate(value: string) {
    this._contractEndDate = value;
  }



  fromJson(data: VersitaStores) {
    this.transactionRate = GetSafeValue.getSafeValueAsNumber(data[StringFieldUtil.transactionRate], 0);
    this.membershipFee = GetSafeValue.getSafeValueAsNumber(data[StringFieldUtil.membershipFee], 0);
    this.contractStartDate = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.contractStartDate], "");
    this.contractEndDate = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.contractEndDate], "");
  }

  clone(data: ContractSetting){
    this.transactionRate = data.transactionRate;
    this.membershipFee = data.membershipFee;
    this.contractStartDate = data.contractStartDate;
    this.contractEndDate = data.contractEndDate;
  }

  toMap(){
    return {
      "transactionRate" : this.transactionRate,
      "membershipFee" : this.membershipFee,
      "contractStartDate": this.contractStartDate,
      "contractEndDate" :this.contractEndDate,
    }
  }
}


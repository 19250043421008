import {Product} from './product';
import {GetSafeValue} from '../../utils/get-safe-value';
import {StringFieldUtil} from '../../utils/string-field-util';
import {StringUtils} from '../../utils/string-utils';

export class Brand extends Product{

  constructor() {
    super();
    this.title = new Map();
    this.imageUrls = [];
    this.modifiedAt = StringUtils.getDateLocal(StringUtils.getDateUTC(new Date()));
  }

  static fromJson(data: Brand){
    let brand = new Brand();
    brand.fromJson(data);
    return brand;
  }

  private fromJson(data: Brand){
    this.id = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.id], '');
    this.isEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isEnable], null);
    this.imageUrls = GetSafeValue.getSafeValueAsList(data[StringFieldUtil.imageUrls], [], []);
    if(data[StringFieldUtil.title]){
      this.title = StringUtils.objToStrMap(data[StringFieldUtil.title]);
    }else {
      this.title = new Map();
    }
    this.modifiedAt = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.modifiedAt], undefined);
    this.itemCountTotal = GetSafeValue.getSafeValueAsNumber(data[StringFieldUtil.itemCountTotal], 0);
  }

  static clone(brand: Brand){
    let objBrand = Object.assign(new Brand(), brand);
    objBrand.title = StringUtils.copyMap(brand.title);

    return objBrand;
  }

  toMap() {
    return {
      "id" : this.id,
      "title": StringUtils.strMapToObj(this.title),
      "isEnable": this.isEnable === null ? false : this.isEnable,
      "imageUrls": this.imageUrls,
      "modifiedAt" : this.modifiedAt,
      "itemCountTotal": this.itemCountTotal,
    };
  }
}

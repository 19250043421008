export class TopIntro {
  intro: Map<string, string>;
  ourPlatform: Map<string, string>;
  iosUrl: string;
  androidUrl: string;
  platformUiImageUrl: string;
  khmumLogoUrl: string;
  videoIntro: string;

  constructor() {
    this.intro = new Map();
    this.ourPlatform = new Map();
    this.iosUrl = "";
    this.androidUrl = "";
    this.platformUiImageUrl = "";
    this.khmumLogoUrl = "";
    this.videoIntro = "";
  }
}

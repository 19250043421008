import {Inject, Injectable} from '@angular/core';
import * as psl from 'psl';
import {DOCUMENT} from '@angular/platform-browser';
import {LoggUtilInfo} from '../utils/loggUtil';

@Injectable({
  providedIn: 'root'
})
export class DomainNameParserService {

  constructor(@Inject(DOCUMENT) private document: Document,) { }
  //psl is a JavaScript domain name parser based on the Public Suffix List.
  getDomainName(hostName: string) {
    const parsed = psl.parse(hostName);
    return parsed.domain;
  }

  isCrossDomainNotAllowed(url: string) {
    const domainName = this.getDomainName(this.document.location.hostname);
    LoggUtilInfo.loggingInfo("url:: ", url);
    LoggUtilInfo.loggingInfo("domain:: ", domainName);
    if(domainName === null)
      return false;
    return !url.includes(domainName);
  }
}

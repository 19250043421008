import {Category} from '../inventory/model/category';
import {Observable} from 'rxjs/Rx';
import {DatePipe, formatCurrency} from '@angular/common';
import {Order} from '../model/order';
import {LocalDataSource} from 'ng2-smart-table';
import {SharedPreference} from '../shared-preference/shared-perference';
import {Role} from '../model/role';
import {startWith} from 'rxjs/operators';
import {ToasterService} from "../services/toaster.service";

export class StringUtils {
  static stTitleEn='en';
  static stTitleKh='kh';
  static stUnderScore='_';
  static locale = "en";

  static months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ];

  static allStatus = [
    "Order Received",
    "Order Processing",
    "Order Processed",
    "Order Packaging",
    "Order Packaged",
    "Order Delivering",
    "Order Delivered",
    "Goods Received",
    "Order Completed",
    "Order Cancelled"
  ];

  static isPhone769(isScreen$: Observable<any>, callBack: Function){
    const checkScreenSize = () => document.body.offsetWidth < 766;
    // Create observable from window resize event throttled so only fires every 500ms
    const screenSizeChanged$ = Observable.fromEvent(window, 'resize').throttleTime(10).map(checkScreenSize);

    // Start off with the initial value use the isScreenSmall$ | async in the
    // view to get both the original value and the new value after resize.
    isScreen$ = screenSizeChanged$.pipe(startWith(checkScreenSize()));
    isScreen$.subscribe(value => {
      return callBack(value);
    });
  }

  static screenDetect(isScreen$: Observable<any>, callBack: Function){
    const checkScreenSize = () => document.body.offsetWidth < 321;
    // Create observable from window resize event throttled so only fires every 500ms
    const screenSizeChanged$ = Observable.fromEvent(window, 'resize').throttleTime(10).map(checkScreenSize);

    // Start off with the initial value use the isScreenSmall$ | async in the
    // view to get both the original value and the new value after resize.
    isScreen$ = screenSizeChanged$.pipe(startWith(checkScreenSize()));
    isScreen$.subscribe(value => {
      return callBack(value);
    });
  }

  static getFontStyle(language: string) {
    if (language === "en") {
      return "sans-serif";
    } else {
      return "custom-fontKhmer";
    }
  }

  static fromEmptyToMinus(email: string): string {
    return email.replace(" ", '-');
  }

  static fromDashToNull(id: string): string {
    const replaceAll = /-/gi;
    return id.replace(replaceAll, '');
  }

  static fromUnderscoreToNull(id: string): string {
    const replaceAll = /_/gi;
    return id.replace(replaceAll, "");
  }

  static fromCommarToNull(id: string): string {
    const replaceAll = /,/gi;
    return id.replace(replaceAll, '');
  }

  static getRestPath(path: string): string {
    return path.substring(0, path.lastIndexOf("/") + 1);
  }

  static getLastPath(path: string): string {
    return path.substring(path.lastIndexOf("/") + 1, path.length);
  }

  static delayTimer(callback: Function , time? : number) {
    let timer: any = Observable.timer(time ? time : 3000);
    timer.subscribe(t => {
      callback();
    });
  }

  static monthDiff(startDate, endDate) {
    let start      = startDate.split('-');
    let end        = endDate.split('-');
    let startYear  = parseInt(start[0]);
    let endYear    = parseInt(end[0]);
    let dates      = [];

    for(let i = startYear; i <= endYear; i++) {
      let endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      let startMon = i === startYear ? parseInt(start[1])-1 : 0;
      for(let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
        let month = j+1;
        let displayMonth = month < 10 ? month : month;
        dates.push([i, StringUtils.months[displayMonth - 1]].join('-'));
      }
    }
    return dates;
  }

  static strMapToObj(strMap): any {
    let obj = Object.create(null);
    strMap.forEach((value, key)=>{
      obj[key] = value;
    });
    return obj;
  }

  static strMapSetToObj(strMap): Function {
    let obj = Object.create(null);
    strMap.forEach((value, key)=>{
      let lst = [];
      value.forEach((val)=>{
        lst.push(val);
      });

      obj[key] = lst;
    });
    return obj;
  }

  static objToStrMap(obj) {
    let strMap = new Map();
    for (let k of Object.keys(obj)) {
      strMap.set(k, obj[k]);
    }
    return strMap;
  }

  static copyMap(map: Map<any, any>){
    return StringUtils.objToStrMap(Object.assign({}, StringUtils.strMapToObj(map)));
  }

  static compareStringIgnoreCase(str1: string, str2: string){
    if(!str1 || !str2) return;
    return str1.toString().toUpperCase().indexOf(str2.toString().toUpperCase()) != -1;
  }

  static onSelectFiles(files: File[], callback: Function) {
    let urls = [];
    if (files && files[0]) {
      let filesAmount = files.length;
      for (let i = 0; i < filesAmount; i++) {
        let reader = new FileReader();

        reader.onload = (event) => {
          urls.push(reader.result);
          if(urls.length === filesAmount){
            callback(urls);
          }
        };

        reader.readAsDataURL(files[i]);
      }
    }
  }

  static filterEnableDisable(isEnable: boolean, search: string): boolean{
    let itemIsEnable: string;
    let valueSearch: string;
    if (isEnable){
      itemIsEnable = StringUtils.enable.toLowerCase();
    } else {
      itemIsEnable = StringUtils.disable.toLowerCase();
    }

    if (StringUtils.fromCommarToNull(search.split(StringUtils.space).toString().toLowerCase()) == "enable" ||
      StringUtils.fromCommarToNull(search.split(StringUtils.space).toString().toLowerCase()) == "true"){
      valueSearch = StringUtils.enable.toLowerCase();
    } else if (StringUtils.fromCommarToNull(search.split(StringUtils.space).toString().toLowerCase()) == "disable" ||
      StringUtils.fromCommarToNull(search.split(StringUtils.space).toString().toLowerCase()) == "false") {
      valueSearch = StringUtils.disable.toLowerCase();
    } else {
      valueSearch = StringUtils.internalUser;
    }
    return StringUtils.compareStringIgnoreCase(itemIsEnable, valueSearch);
  }

  static getDateFormat(date: any, format: string){
    return new DatePipe(StringUtils.enUs).transform(date, format);
  }

  static getYYYY(date: any){
    return new DatePipe(StringUtils.enUs).transform(date, 'yyyy');
  }

  static getYYYYMM(date: any){
    return new DatePipe(StringUtils.enUs).transform(date, 'yyyy-MM');
  }

  static getDDHHmmss(date: any){
    return new DatePipe(StringUtils.enUs).transform(date, 'dd-HH-mm-ss');
  }

  static getMM(date: any){
    return new DatePipe(StringUtils.enUs).transform(date, 'MM');
  }

  static getMMMM(date: any){
    return new DatePipe(StringUtils.enUs).transform(date, 'MMMM');
  }

  static getDD(date: any){
    return new DatePipe(StringUtils.enUs).transform(date, 'dd');
  }

  static getNextYearMonth(date){
    return new DatePipe(StringUtils.enUs).transform(new Date(date.getFullYear(), date.getMonth() + 1, 1), 'yyyy-MM');
  }

  static getCurrentYearMonth(date){
    return new DatePipe(StringUtils.enUs).transform(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM');
  }

  static getMonth_mm(month){
    return StringUtils.months.indexOf(month);
  }

  static getFormatCurrency(amt){
    return formatCurrency(amt, StringUtils.enUs, '$ ');
  }

  static getFormatCurrencyWallet(amt){
    return formatCurrency(amt, StringUtils.enUs, 'USD ', );
  }

  static getDateUTC(date) {
    return new DatePipe(StringUtils.enUs).transform(date.toUTCString(), 'yyyy-MM-dd HH:mm:ss.ssssss');
  }
  static getDateLocal(date) {
    return new DatePipe(StringUtils.enUs).transform(new Date(Date.parse(date)), 'yyyy-MM-dd hh:mm:ss a');
  }

  static getDateLocalForm(date) {
    let dat = new Date(date);
    return dat.toISOString();
  }

  static getDate(date) {
    return new DatePipe(StringUtils.enUs).transform(new Date(Date.parse(date)), 'dd-MM-yyyy');
  }

  static getDate24H(date) {
    return new DatePipe(StringUtils.enUs).transform(new Date(Date.parse(date)), 'yyyy-MM-dd HH:mm:ss');
  }

  static getDateLocalTime(date) {
    return new DatePipe(StringUtils.enUs).transform(new Date(Date.parse(date)), 'yyyy-MM-dd hh:mm a');
  }

  static getTimestamp(date) {
    return new Date(Date.parse(date)).getTime();
  }

  static getDatePlusDate(date, numOfDay){
    date.setDate( date.getDate() + numOfDay);
    return new DatePipe(StringUtils.enUs).transform(new Date(Date.parse(date)), 'yyy-MM-dd');
  }

  static getTime(date) {
    return new DatePipe(StringUtils.enUs).transform(new Date(Date.parse(date)), 'hh:mm:ss a');
  }

  static getGenerateId(date) {
    return new DatePipe(StringUtils.enUs).transform(new Date(Date.parse(date)), 'yyMMddhhmmss');
  }

  static getTotalAmountOrder(orders: Order[]){
    let amt = 0.0;
    if(!orders) {
      return
    }
    orders.forEach((order)=>{
      amt += order.total;
    });
    return this.getFormatCurrency(amt);
  }

  static generateYear(orders: any,dropdownYearsOrder: any[]) {
    orders.sort((r, l)=> {
      return r.orderDate.localeCompare(l.orderDate);
    });
    let year: Set<any> = new Set();
    orders.forEach((order) => {
      year.add(this.getYYYY(order.orderDate));
    });

    Array.from(year).forEach((y) => {
      dropdownYearsOrder.push({
        item_id: y, item_text: y
      });
    });
  }

  static generateMonth(months, dropdownMonths: any[]) {
    Array.from(months).forEach((m) => {
      dropdownMonths.push({
        item_id: m, item_text: m
      });
    });
  }

  static convertMapToObj(mapSort) {
    return Array.from(mapSort).reduce((obj, [key, value]) => (
      Object.assign(obj, {[key]: value})
    ), {});
  }

  static getNumberWithZeroBelow10(number){
    return number < 10 ? `0${number}` : number;
  }

  static getDateTimeKeyword(date) {
    return new DatePipe(StringUtils.enUs).transform(new Date(Date.parse(date)), 'yyyyMMddhhmmss');
  }

  static emailSubject(order: Order, currentStatus: string){
    return StringUtils.fromDashToNull(order.id) + ": " + currentStatus + ". " + "Order Total" + " $" + order.total.toFixed(2);
  }

  static getDisplayDays(messageDisplayDay : number) {
    return new Date(new Date().getTime() - messageDisplayDay * 24 * 60 * 60 * 1000);
  }

  static titleCase(word: string) {
    if (!word) return word;
    return word.replace(/\b[a-z]/g,function(f){return f.toUpperCase();});
  }

  static onSourceChange(source: LocalDataSource, data: any[], total: string, callback: Function) {
    source.onChanged().subscribe((change) => {
      if (change.action === 'page' || change.action === 'filter') {
        total = (StringUtils.getTotalData(source, data)).toString();
      }
      callback(total);
    });
  }

  static getTotalData(source: LocalDataSource, data:any[]) {
    let perPage = source.getPaging().perPage * source.getPaging().page;
    let totalCategories = source.count();
    let stringRecord: string = totalCategories === 1 ? ' Record':' Records';
    let doneTotal = totalCategories < perPage ? totalCategories +" of "+totalCategories : perPage+" of "+totalCategories;

    return doneTotal + stringRecord;
  }

  static generateBarcodeAsList(barcode: string[]): string[]{
    let storeId = SharedPreference.getStoreId();
    let barCodes: string[] = [];
    barcode.forEach(v => {
      if(v.includes(StringUtils.generateBarcode(""))){
        return barcode.push(v);
      }else {
        return barCodes.push(storeId.substring(storeId.length, 6) +"-"+ v);
      }

    });
    return barCodes;
  }

  static getRestPathImageUpload(imgUrl: string){
    return imgUrl.substring(0, imgUrl.lastIndexOf("?"));
  }

  static defaultDropDownSetting(text: string = null, singleSelection: boolean = false) {
    return {
      idField: 'id',
      textField: 'itemName',
      singleSelection: singleSelection,
      text: text || 'No selected is all item',
      enableSearchFilter: true,
      noDataLabel: 'Search item',
      maxHeight: 200,
      enableCheckAll: false,
      badgeShowLimit: 10
    };
  }

  static defaultDropDownSettingLimitSelect(text: string = null, limit: number = 10, singleSelection: boolean = false) {
    const dropDown = this.defaultDropDownSetting(text, singleSelection);
    dropDown['limitSelection'] = limit || 10;
    return dropDown;
  }

  static dropdownSettingsWhenSearchNotFound(singleSelection: boolean = false) {
    const dropDown = this.defaultDropDownSetting(null, singleSelection);
    dropDown['noDataLabel'] = "Search not found!";
    dropDown['maxHeight'] = 40;
    dropDown['text'] = "Select Item";
    return dropDown;
  }

  static dropdownSettingsWhenSearching() {
    const dropDown = this.dropdownSettingsWhenSearchNotFound();
    dropDown['noDataLabel'] = "Searching...";
    return dropDown;
  }

  static getValidPhoneNumber(keySearch: string) {
    if (!isNaN(Number(keySearch))) {
      let check = keySearch.replace("+", "").split("");

      if (check[0].includes("8") && check[1].includes("5") && check[2].includes("5")) {
        keySearch = keySearch.substring(3, keySearch.toString().length);
      }

      if (keySearch.split("")[0].includes("0")) {
        keySearch = keySearch.substring(1, keySearch.toString().length);
      }
    }
    return keySearch;
  }

  static generateBarcode(barcode: string){
    let storeId = SharedPreference.getStoreId();
    return storeId.substring(storeId.length, 6) +"-"+ barcode;
  }

  static onSortTitleMap(list: any) {
    list.sort((a, b) =>
      a.title.get('en') &&
      b.title.get('en') &&
      a.title.get('en').toLowerCase().localeCompare(b.title.get('en').toLowerCase())
    );
  }

  static capitalize(s){
    return s.toLowerCase().replace( /\b./g, function(a){ return a.toUpperCase(); } );
  };

  static isGoLiveFalse(toaster: ToasterService){
    setTimeout(() => {
      toaster.showToastWarning('Warning', StringUtils.yourStoreDoseNotBelong,  {
        closeButton: true,
        positionClass: "toast-bottom-right",
        timeOut: 0,
        extendedTimeOut: 1000,
        progressBar: true,
        preventDuplicates: true
      });
    });
  }

  static toastAlert(toaster: ToasterService, title: string, msg: string, type: string, timeOut?: number, delay?: number){
    let option = {
      closeButton: true,
      positionClass: "toast-bottom-right",
      timeOut: timeOut || 0,
      extendedTimeOut: 1000,
      progressBar: true,
      preventDuplicates: true
    };

    setTimeout(() => {
      if(type === "Info"){
        toaster.showToastInfo(title, msg,  option);
      }else if(type === "Error"){
        toaster.showToastError(title, msg,  option);
      }else if(type === "Success"){
        toaster.showToastSuccess(title, msg,  option);
      }else if(type === "Warning"){
        toaster.showToastWarning(title, msg,  option);
      }
    }, delay || 1000);

  }

  static inputFieldError = "One or more fields have an error. Please check and try again.";
  static chooseBanner = "Choose banner";
  static storeSettingCreateUpdateSuccess = "Store setting save/update successfully";
  static suggestSaveSuccess = "Variation Suggestion save successfully";
  static suggestSaveUnsuccess = "Variation Suggestion save unsuccessfully";
  static storeSettingCreateUpdateUnsuccess = "Store setting save/update unsuccessfully";
  static storeCreateUpdateUnsuccess = "Store save/update unsuccessfully";
  static pleaseSelectImage = "Please select Photo";
  static brand = "Brand";
  static createCategorySuccess = "Category has been updated successfully!";
  static addTermsConditions = "Please click on add button first";
  static brandAndCategory = "Please select brand or category click update";
  static termsConditionUpdateSuccess = "Terms and Conditions created successfully";
  static aboutUsUpdateSuccess = "About us updated successfully";
  static termsConditionUnsuccess = "Terms and Conditions created unsuccessfully";
  static aboutUsUnsuccess = "About us created unsuccessfully";
  static deleteAboutUsUnsuccess = "About us deleted unsuccessfully";
  static deleteContactUsUnsuccess = "About us deleted unsuccessfully";
  static text_danger = "text-danger";
  static icon_warning = "fa fa-warning";
  static text_success = "text-success";
  static icon_check = "fa fa-check-circle";
  static profileUpdateSuccess = 'Your profile has been updated successfully';
  static dataUpdateSuccess = 'Your Data has been added/updated Successfully';
  static updateItemDiscountByBrand = 'item has been updated price discount Successfully';
  static dataUpdateUnSuccess = 'Your Data has been Added/Updated Unsuccessfully';
  static profileUpdateUnsuccess = 'Your profile has been updated unsuccessfully';
  static passwordResetSuccess = 'Your password has been reset successfully';
  static passwordResetUnsuccess = 'Your password has been reset unsuccessfully';
  static passwordNotMatch = "Current password doesn't match! Please try again!";
  static pleaseCheck = "Please Check at least 1 Collection";
  static pleaseCheckStoreId = "Please Check at least 1 StoreId";
  static en = 'en';
  static kh = 'kh';
  static true: "true";
  static false: "false";
  static addItemDetailUnSuccess = "Item Detail has been add unsuccessfully!";
  static updateItemDetailUnSuccess = "Item Detail has been updated unsuccessfully!";
  static pleaseClickUpdateBeforeSorting = "Please click 'Add/Update Variation' before sorting!";
  static termsConditionCreateSuccess = 'Terms and Conditions created successfully';
  static aboutUsCreateSuccess = 'About us created successfully';
  static contactUsUsCreateSuccess = 'Contacu us created successfully';
  static termsConditionCreateUpdateSuccess = 'Terms and Conditions created or updated successfully';
  static termsConditionDelete = 'Terms and Conditions deleted successfully';
  static deleteAboutUs = 'About us deleted successfully';
  static deleteContactUs = 'Contact us deleted successfully';
  static contactUsUnsuccess = "Contact us create unsuccessfully!";
  static accountDisable = "Your account is not valid. Please contact Admin";
  static invalidPasswordOrEmail = "Email and / or password is not valid";
  static invalidPhoneNumber = "Phone number is not valid or blocked";
  static phoneEmpty = "Phone number is empty/invalid!";
  static authDisable = "auth/user-disabled";
  static code = "code";
  static ok = "ok";
  static registerSuccessfully = "Register Successfully";
  static emailIsAlreadyInUsed = "This email is already in used by someone!";
  static phoneIsAlreadyInUsed = "This phone is already in used by someone!";
  static connectionError = "Connection error!";
  static internalUser = "Internal User";
  static columnWidth200px = "200px";
  static columnWidth150px = "150px";
  static columnWidth180 = "180px";
  static columnWidth250px = "250px";
  static columnWidth350px = "350px";
  static widthImg = "80px";
  static heightImg = "80px";
  static image = "Image";
  static nameOrder = "Name";
  static enable = "Enable";
  static publish = "Publish";
  static consignment = "Consignment";
  static disable = "Disable";
  static status = "Status";
  static titleEnKh = "Title in English/Khmer";
  static cateAndSubCate = "Category / Subcategory";
  static orderDate = "Order Date";
  static orderID = "OrderID";
  static refID = "RefID";
  static total = "Total";
  static html = "html";
  static custom = "custom";
  static id = "ID";
  static idChat = "id";
  static key = "key";
  static value = "value";
  static serverTimeStamp = 'server-timestamp';
  static itemCountActive = "Item Count Active";
  static itemCountTotal = "Item Count Total";
  static pid = "PID";
  static left = "left";
  static list = "list";
  static right = "right";
  static ratingMinAndMax = "Rating from 1 to 5";
  static active = "active";
  static enUs = "en-US";
  static shortDate = "yyyy-MM-dd hh:mm a";
  static orderReceived = "Order Received";
  static orderProcessing = "Order Processing";
  static orderProcessed = "Order Processed";
  static orderPackaging = "Order Packaging";
  static orderPackaged = "Order Packaged";
  static orderDelivering = "Order Delivering";
  static orderDelivered = "Order Delivered";
  static orderCustomerReceived = "Goods Received";
  static orderCompleted = "Order Completed";
  static orderCancelled = "Order Cancelled";
  static versitaStore = "Versita Store";
  static deliveryMethod = "Delivery Method";
  static nextDayDelivery = " Next Day Delivery";
  static sameDayDelivery = " Same Day Delivery";
  static customDayDelivery = " Custom Day Delivery";
  static storePickup = "Store Pickup";
  static pleaseSelectCountry = "Please select file Country and City to upload";
  static systemUnderMaintenance = "System is under maintenance.";
  static yourStoreDoseNotBelong = "Your products aren't visible on KHMUM App. Please contact Admin!";
  static shipping = "shipping";
  static credit = "credit";
  static coupon = "coupon";
  static type = "Type";
  static createUpdateSuccess = "Save Or Update Successfully";
  static createUpdateUnSuccess = "Save Or Update Unsuccessfully";

  static openOrders = [
    StringUtils.fromEmptyToMinus(StringUtils.orderReceived),
    StringUtils.fromEmptyToMinus(StringUtils.orderProcessing),
    StringUtils.fromEmptyToMinus(StringUtils.orderProcessed),
    StringUtils.fromEmptyToMinus(StringUtils.orderPackaging),
    StringUtils.fromEmptyToMinus(StringUtils.orderPackaged),
    StringUtils.fromEmptyToMinus(StringUtils.orderDelivering),
    StringUtils.fromEmptyToMinus(StringUtils.orderDelivered)
  ];

  static bidStatusObj = [
    {key: 'openBid', value: 'open bid', title: 'Open Bid'},
    {key: 'inBidding', value: 'in bidding', title: 'In Bidding'},
    {key: 'closedBid', value: 'closed bid', title: 'Closed Bid'},
    {key: 'cancelled', value: 'cancelled', title: 'Cancelled'},
  ];

  static bidRecordStatusObj = [
    {key: 'win', value: 'win', title: 'Win'},
    {key: 'lose', value: 'lose', title: 'Lose'},
    {key: 'registered', value: 'registered', title: 'Registered'},
    {key: 'placedBid', value: 'placed bid', title: 'Placed Bid'},
    {key: 'cancelled', value: 'cancelled', title: 'Cancelled'},
  ];

  static bidStatusObjDashboard = [
    {key: 'openBid', value: 'open bid', title: 'Open Bid', color: 'bg-warning'},
    {key: 'inBidding', value: 'in bidding', title: 'In Bidding', color: 'bg-info'},
    {key: 'closedBid', value: 'closed bid', title: 'Closed Bid', color: 'bg-success'},
    {key: 'cancelled', value: 'cancelled', title: 'Cancelled', color: 'bg-danger'},
    {key: 'published', value: 'published', title: 'Published', color: 'bg-primary'},
    {key: 'unpublished', value: 'unpublished', title: 'Unpublished', color: 'bg-secondary'},
    {key: 'win', value: 'win', title: 'Win', color: 'bg-success'},
    {key: 'lose', value: 'lose', title: 'Lose', color: 'bg-danger'},
    {key: 'registered', value: 'registered', title: 'Registered', color: 'bg-info'},
    {key: 'placedBid', value: 'placed bid', title: 'Placed Bid', color: 'bg-primary'},
    {key: 'today', value: 'today', title: 'Today', color: 'bg-success'},
    {key: 'yesterday', value: 'yesterday', title: 'Yesterday', color: 'bg-info'},
    {key: 'pending', value: 'pending', title: 'Pending', color: 'bg-danger'},
    {key: 'oneWeek', value: 'one week', title: 'Last 7 Days', color: 'bg-warning'},
    {key: 'oneMonth', value: 'one month', title: 'Last 30 Days', color: 'bg-primary'},
  ];

  static filterBidObj = [
    {value: 'popular', title: 'Popular'},
    {value: 'latest', title: 'Latest'},
    {value: 'expensive', title: 'Expensive'},
    {value: 'cheap', title: 'Cheap'},
  ];

  static auctionTypeObj = [
    {value: 'bideal', title: 'Bideal'},
    {value: 'donation', title: 'Donation'},
    {value: 'best buy', title: 'Best Buy Bid'},
    {value: 'unique', title: 'Unique'},
  ];

  static transactionType = [
    {value: 'order',     title: 'Order'},
    {value: 'refund',    title: 'Refund'},
    {value: 'deposit',   title: 'Deposit'},
    {value: 'withdraw',  title: 'Withdraw'},
    {value: 'reward',  title: 'Reward'},
    {value: 'bidding fee',    title: 'Bidding Fee'},
    {value: 'cancelled bid', title: 'Cancelled Bid'},
  ];

  static transactionStatus = [
    {value: 'approved', title: 'Approved'},
    {value: 'pending', title: 'Pending'},
    {value: 'rejected', title: 'Rejected'}
  ];

  static paymentMethodStatus = [
    {value: 'cash', title: 'Cash'},
    {value: 'wallet', title: 'Wallet'},
    {value: 'aba', title: 'ABA Payment'},
    {value: 'ac', title: 'Acleda Payment'},
    {value: 'amk', title: 'AMK Payment'},
    {value: 'wing', title: 'Wing'},
    {value: 'truemoney', title: 'True Money'},
    {value: 'other', title: 'Other Payment'},
  ];

  static collectionsHasStoreIdPlusStore = [
    'documentMap',
    'order',
    'orderReport',
    'orderStatus',
    'rating',
  ];

  static collectionsHasStoreIdPlusOwn = [
    'item',
    'itemStock',
    'message',
  ];

  static unSortOrder = [
    StringUtils.orderCompleted,
    StringUtils.orderCancelled
  ];

  static space = " ";
  static barcode = "Barcode";
  static storeName = "Store Name";
  static keyStoreName = "storeName";
  static keyStoreId = "storeId";
  static storeOwnerName = "Owner Name";
  static storeOwnerEmail = "Owner Email";
  static storeOwnerPhone = "Owner Phone";
  static storeAdminName = "Admin Name";
  static storeAdminPhone = "Admin Phone";
  static storeAdminEmail = "Admin Email";
  static firstName = "First Name";
  static lastName = "Last Name";
  static email = "Email";
  static role = "Role";
  static gender = "Gender";
  static createAt = "Create At";
  static category = "Category";
  static saleType = "Sale Type";
  static minRegistration = "Min Registration";
  static registration = "Registration";
  static normalSales = "normal sales";
  static sales = "sales";
  static promotion = "promotions";
  static width120 = "120px";
  static price = "Price";
  static salesPrice = "Sale Price";
  static discount = "Discount";
  static availableQty = "Available Qty";
  static bufferQty = "Buffer Qty";
  static stockIn = "Stock In";
  static width140 = "140px";
  static width95 = "95px";
  static width70 = "60px";
  static orderUnpaid = "Unpaid";
  static contactYourAdmin = "Please contact your admin!";
  static internalUserOnly = "Vendor only!";
  static defaultImage = "assets/images/users/default_profile.png";
  static default = "default";
  static customer = "Customer";
  static order = "Order";
  static hasStatusUpdate = "has status updated to";
  static dd = 'dd';
  static totalSalePerDay = 'Total sale/day';
  static totalUnitCostPerDay = 'Total unit cost/day';
  static totalDiscountPerDay = 'Total discount/day';
  static totalDeliveryPerDay = 'Total delivery/day';
  static yyyymm = "yyyy-MM";
  static roleAdmin = "Admin";
  static roleSupervisor = "Supervisor";
  static roleVersitaSupervisor = "Versita Supervisor";
  static roleCustomerService = "Customer Service";
  static rolePackage = "Package";
  static roleDelivery = "Delivery";
  static roleCustomer = "Customer";
  static roleReadOnly = "Read Only";
  static rolePos = "POS";
  static roleBank = "Bank";

  static versitaSystem = "Versita System";
  static versitaSale = "Versita Sale";
  static versitaFinance = "Versita Finance";
  static versitaAdmin = "Versita Admin";
  static versitaUser = "Versita User";
  static versita = "Versita";

  static selectRole = "Select Role";
  static select = "Select...";
  static paymentStatus = "Payment Status";
  static totalRetail = "Total Retail" ;
  static paid = "Paid";
  static notPaid = "Not Paid";
  static getSafeValueError = "Get safe value error::::";
  static timestamp = "timestamp";
  static lastMessageDate = "lastMessageDate";
  static endDate = "endDate";
  static orderStatusSaved = 'Order status has been saved';
  static itemSaved = 'Item has been saved/updated successfully!';
  static hasResetPassword = 'Password has been reset';
  static brandSaved = 'Brand has been updated successfully!';
  static updateStatusSaved = 'Status has been updated successfully!';
  static updateStatusUnsuccessfully = 'Status has been updated unsuccessfully!';
  static requestSaved = "Thank you for your inquiry. Our sales team will be in touch with you soon. Have a good day!";
  static categorySaved = 'Category has been updated successfully!';
  static formHasBeenChanged = "Form has been changed click Submit/Update before Enable/Disable!";
  static formHasBeenAddVariation = "Form has been changed click Add Variation before Enable/Disable!";
  static success = 'success';
  static center ='center';
  static duplicateTitleBrand = "Brand's title in english already exist!";
  static duplicateTitleCategory = "Category's title english already exist!";
  static duplicateBarcodeItem = "Item's barcode already exist!";
  static alertSound = "/assets/sound/chat-alert.wav";
  static unknown = "Unknown";
  static statusSelectPhoto = "photo";
  static documentName = "Document Name";
  static maxRecord = "Max Record";
  static recordCount = "Record Count";
  static statusDuplicate = "duplicate";
  static searchEmail = "searchEmail";
  static searchNotFound = "Search not found!";
  static openOrderList = "Open Orders List";
  static closeOrderList = "Closed Orders List";
  static searchOrderList = "Search Orders List";
  static summaryStatusList = "Summary Order List";
  static noDataInRange = "No records.";
  static orderStatus = "orderStatus";
  static dataAlreadyExit = "Document map already exist!";
  static modifiedAt = "Modified At";
  static variation = "Variation";
  static itemValid = "Item valid. The item should be enabled in the item list!";
  static itemVariantInvalid = "Item invalid. The item variations are not the same format!";
  static itemInvalid = "Item invalid. The item doesn't have barcode!";
  static barcodeExist = "this barcode already exist!";
  static canNotDelete = "This category has many sub categories.";
  static variationDuplicate = 'Your item variation is duplicate!';
  static reasonOrderCancel = 'You have to give a reason for order cancel!';
  static totalCountGreaterThanZero = "totalCountGreaterThanZero";
  static thereAre = "There are";
  static itemRelatedBrand = "items related to this brand! You can not delete this brand!";
  static itemRelatedCategory = "items related to this category! You can not delete this category!";
  static invalidBarcode = "Barcode is invalid! Barcode must not contain(., $, [, ], #, /).";
  static disableItemVariant = "You have to disable this item before edit the variations!";
  static enableItemVariant = "You have to enable at least one item variations!";
  static reqName = "name";
  static reqDate = "date";
  static reqphoneNumber1 = "phoneNumber1";
  static reqphoneNumber2 = "phoneNumber2";
  static reqphoneNumber3 = "phoneNumber3";
  static phone = "phone";
  static phoneN = "Phone Number";
  static phoneNumber = "phoneNumber";
  static reqMessage = "message";
  static reqStatus = "status";
  static reqCompanyName = "companyName";
  static reqUpdateBy = "updateBy";
  static reqUpdateDate = "updateDate";
  static title = "Title";
  static itemDetail = "itemDetail";
  static itemVariation = "itemVariation";
  static areYouSureToDeleteUser = "Are you sure you want to delete this user?";
  static areYouSure = "Are you sure ?";
  static updateItemSpecail = "Item special will update and enable item folder";
  static itemDelete = 'item special will delete and disable item folder.';
  static yesDeleteIt = "Yes, delete it!";
  static overwrite = "Over write!";
  static yesUpdateIt = "Yes, update it";
  static noCancel = "No, cancel!";
  static deleted = "Deleted!";
  static userHasBeenDeleted = "User has been deleted.";
  static unSuccessful = "Unsuccessful";
  static deleteUserUnsuccessful = "Delete user unsuccessful!";
  static readDocument = "read document ";
  static listenSnapshot = "listen snapshot ";
  static enterTempPassword = 'Please enter temporary code';
  static temporaryPassword = "Temporary Code";
  static resetPassword = 'Change PIN Code';
  static enterOTPCode = 'Please enter OTP Code 6 digits from SMS : ';
  static temporaryOTPCode = "OTP Code 6 digits";
  static temporaryPasswordRequest = "Required field!";
  static temporaryPasswordSixDigits = "Login code must be 6 digits";
  static hrefOrderList = "Orders%20List";
  static invalidCode = "Incorrect sms code!";
  static invalidPin = "Invalid Pin Code!";
  static noPinCode = "No PIN code registered, Please login via OTP/SMS";
  static quotaMessage = "Quota has been  exceeded. Please contact us to change your plan!";
  static itemNotBelong = "This item not belong in this store!";
  static isSubCategory = "isSubCategory";
  static warning : any = 'warning';
  static titleDelete = 'Can not delete';
  static componentCreate = ' component : category create';
  static componentAdminStore = ' component : admin stores';
  static componentLowInventory = ' component : low inventory';
  static componentLogin = ' component : login';
  static componentSystemConfigUtil = ' component : system util';
  static componentMonthlyOrder = ' component : monthly order';
  static componentMonthly = ' component : monthly';
  static componentSummaryOrder = ' component : summary order status';
  static componentOrderList = ' component : order list';
  static componentOrderDetail = ' component : order detail';
  static componentExport = ' component : export';
  static componentReview = ' component : List Review';
  static export = 'export';
  static dashboard = 'dashboard';
  static login = 'login';
  static homePage = 'home-page';
  static sessionTimeout = 'Session Timeout';
  static youHaveBeenSignedOut = 'You have been signed out due to inactivity. Please sign in again.';
  static quotaMessageItemSpecialLimit = 'Quota of Item Special has been exceeded. Change item sale type or contact us to change your plan!';
  static pleaseInputStoreSetting = "Please input store setting before create item!";
  static goToStoreSetting = "Go to Store Setting";
  static selectStoreName = 'Select store name';
  static youNeedToSelectStoreName = "You need to select store name!";
  static selectFileIncorrect = "File is invalid. Please try again!";
  static numberRating = "Number Rating";
  static reviewName = "Review Name";
  static date = "Date";
  static services = "Services";
  static ticket = "Ticket";
  static normal = "normal";
  static versitaSettingKey = "versitaSetting";
  static versitaSettingValue = "Versita Setting";
  static versitaTermConditionKey = "versitaTermCondition";
  static versitaTermConditionValue = "Versita Term & Condition";
  static storeSetting = "Store setting";
  static pleaseSelectStoreSetting = "Please select store setting";
  static termAndCondition = "Term & Condition";
  static pleaseSelectTermAndCondition = "Please select Term & Condition";
  static pleaseSelectBrand = "Please select brand!";
  static enterDiscount = "Please enter discount";
  static miniAndMaxDicountValue = "Discount value is minimum 0 and maximum 1";
  static noRelatedItemToUpdate = "No item related this brand to update!";
  static itemRelatedBrandToUpdateDiscount = "Are you sure you want to update all these item(s) related to this brand?";
  static somethingWentWrong = "Something went wrong!";
  static itemHasBeenUpdate = "item(s) has been update";
  static CDNWidth = "width=300";
  static CDNWidth700 = "width=700";
  static CDNLargeWidth = "width=960,withoutenlarge=true";
  static CDNMetadata = "metadata=true";
  static firestoreDomain = "firebasestorage.googleapis.com";
  static expirationDateRequired = "Expiration date is required!";
  static saleTypeRequired = "Sale Type is required!";
  static invalidExpirationDate = "Invalid expiration date!";
  static couponCodeUpdateSucess = "Coupon Code has been updated successfully!";
  static creditUpdateSucess = "Credit has been updated successfully!";
  static couponCodeDeleted = "Coupon Code has been deleted!";
  static yourDataUpdated = "Your Updated Successfully";
  static creditDeleted = "Credit has been deleted!";
  static areYouSureToDeleteCouponCode = "Are you sure you want to delete this coupon?";
  static areYouSureToDeleteCouponCodes = "Are you sure you want to delete these coupons?";
  static areYouSureToDeletePurchaseLimit = "Are you sure you want to delete these purchase limit?";
  static areYouSureToDeleteCredit = "Are you sure you want to delete this credit?";
  static areYouSureToDeleteCredits = "Are you sure you want to delete these credits?";

  static areYouSureToDeleteNotification = "Are you sure you want to delete these notification(s)?";

  static areYouSureToOverwrite = "Coupon already exist!\nDo you want to overwrite?";
  static areYouSureToOverwriteLimitPurchase = "Purchase limit for this item already exist!\nDo you want to overwrite?";
  static couponCode = "Coupon Code";
  static couponName = "Coupon Name";
  static startDate = "Start Date";
  static expirationDate = "Expiration Date";
  static limit = "Limit";
  static condition = "Condition";
  static fromFieldRequired = "From field is required!";
  static toFieldRequired = "To field is required!";
  static digitFieldRequired = "Digit field is required!";
  static fromMustPositive = "From field must be positive!";
  static toFieldGreaterThanZero = "To field must be greater than 0!";
  static digitFieldGreaterThanOne = "Digit field must be greater than 1";
  static toGreaterThanFrom = "To field must be greater than From field!";
  static percentage = "percentage";
  static absolute = "absolute";
  static countUsage = "Count Usage";
  static shopping = "Shopping";
  static service = "Services";
  static versitaCustomerService = "Versita Customer Service";
  static versitaSupervisor = "Versita Supervisor";
  static limitSelectDeliveryRoutes = 'At least 2 delivery routes must be selected!';
  static saveDeliverySettingSuccess = "Delivery options has been updated";
  static saveGeneralSettingSuccess = "General setting has been updated";
  static saveRegionSuccess = "Your data has been saved";
  static regionRequired = "Region is required!";
  static regionDuplicate = 'Region is duplicate!';
  static saveNotificationSuccess = "Notification have been pushed!";
  static saveNotificationUnSuccess = "Push notification failed!";
  static tryAgain = "Update unsuccessfully please try again!"
}

import { Injectable } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';

@Injectable()
export class ToasterService {

  constructor(
    private toastr: ToastrService
  ) {
  }

  public showToastWarning(title, message, options) {
    this.toastr.warning(message, title, options);
  }

  public showToastSuccess(title, message, options) {
    this.toastr.success(message, title, options);
  }

  public showToastInfo(title, message, options) {
    this.toastr.info(message, title, options);
  }

  public showToastError(title, message, options) {
    this.toastr.error(message, title, options);
  }

  public clear(toastId?: number){
    this.toastr.clear(toastId);
  }
}

import {GetSafeValue} from "../utils/get-safe-value";
import {ControlFormNameUtil} from "../utils/string-control-form-name";
import {Payment} from "./payment";

export class WhiteLabelSetting {

  private _bundleId : string;
  private _iosAppId : string;
  private _appName : string;
  private _logo: string[];
  private _domain : string;
  private _theme : Theme;

  constructor(){
    this.logo = [];
    this.theme = new Theme();
  }


  clone(data: WhiteLabelSetting) {
    this.bundleId = data.bundleId;
    this.iosAppId = data.iosAppId;
    this.appName = data.appName;
    this.domain = data.domain;
    this.logo = data.logo;
    this.theme = data.theme;
  }

  fromJson(data: WhiteLabelSetting){
    this.bundleId = GetSafeValue.getSafeValueAsString(data[ControlFormNameUtil.bundleId], null);
    this.iosAppId = GetSafeValue.getSafeValueAsString(data[ControlFormNameUtil.iosAppId], null);
    this.appName = GetSafeValue.getSafeValueAsString(data[ControlFormNameUtil.appName], null);
    this.domain = GetSafeValue.getSafeValueAsString(data[ControlFormNameUtil.domain], null);
    this.logo = GetSafeValue.getSafeValueAsList(data[ControlFormNameUtil.logo], [], []);

    if(data[ControlFormNameUtil.theme]){
      this.theme = new Theme();
      this.theme.clone(data[ControlFormNameUtil.theme]);
    }

  }

  toMap(){
    return {
      'bundleId': this.bundleId ? this.bundleId : null,
      'iosAppId': this.iosAppId ? this.iosAppId : null,
      'appName': this.appName ? this.appName : null,
      'domain': this.domain ? this.domain : null ,
      'logo': this.logo.length > 0 ? this.logo : [],
      'theme': this.theme ? this.theme.toMap() : new Theme(),
    };
  }


  get theme(): Theme {
    return this._theme;
  }

  set theme(value: Theme) {
    this._theme = value;
  }



  get logo(): string[] {
    return this._logo;
  }

  set logo(value: string[]) {
    this._logo = value;
  }

  get domain(): string {
    return this._domain;
  }

  set domain(value: string) {
    this._domain = value;
  }


  get iosAppId(): string {
    return this._iosAppId;
  }

  set iosAppId(value: string) {
    this._iosAppId = value;
  }

  get bundleId(): string {
    return this._bundleId;
  }

  set bundleId(value: string) {
    this._bundleId = value;
  }

  get appName(): string {
    return this._appName;
  }

  set appName(value: string) {
    this._appName = value;
  }


}

export class Theme {

  private _accentColor : string;
  private _primaryColor : string;
  private _buttonColor : string;
  private _scaffoldBackgroundColor : string;
  private _cardColor : string;
  private _dividerColor : string;
  private _errorColor : string;
  private _bottomAppBarColor : string;
  private _backgroundColor : string;
  private _iconColor : string;
  private _gradientColors : string[];

  constructor(){
    this.gradientColors = [];
  }



  clone(data: Theme) {
   this.accentColor = data.accentColor;
    this.primaryColor = data.primaryColor;
    this.buttonColor = data.buttonColor;
    this.scaffoldBackgroundColor = data.scaffoldBackgroundColor;
    this.cardColor = data.cardColor;
    this.dividerColor = data.dividerColor;
    this.errorColor = data.errorColor;
    this.bottomAppBarColor = data.bottomAppBarColor;
    this.backgroundColor = data.backgroundColor;
    this.iconColor = data.iconColor;
    this.gradientColors = data.gradientColors;
  }

  fromJson(data: Theme){
  this.accentColor = GetSafeValue.getSafeValueAsString(data[ControlFormNameUtil.accentColor], null);
    this.primaryColor = GetSafeValue.getSafeValueAsString(data[ControlFormNameUtil.primaryColor], null);
    this.buttonColor = GetSafeValue.getSafeValueAsString(data[ControlFormNameUtil.buttonColor], null);
    this.scaffoldBackgroundColor = GetSafeValue.getSafeValueAsString(data[ControlFormNameUtil.scaffoldBackgroundColor], null);
    this.cardColor = GetSafeValue.getSafeValueAsString(data[ControlFormNameUtil.cardColor], null);
    this.dividerColor = GetSafeValue.getSafeValueAsString(data[ControlFormNameUtil.dividerColor], null);
    this.errorColor = GetSafeValue.getSafeValueAsString(data[ControlFormNameUtil.errorColor], null);
    this.bottomAppBarColor = GetSafeValue.getSafeValueAsString(data[ControlFormNameUtil.bottomAppBarColor], null);
    this.backgroundColor = GetSafeValue.getSafeValueAsString(data[ControlFormNameUtil.backgroundColor], null);
    this.iconColor = GetSafeValue.getSafeValueAsString(data[ControlFormNameUtil.iconColor], null);
    this.gradientColors = GetSafeValue.getSafeValueAsList(data[ControlFormNameUtil.gradientColors], [],[]);
  }

  toMap(){
    return {
      'accentColor': this.accentColor ? this.accentColor : null,
      'primaryColor': this.primaryColor ? this.primaryColor : null,
      'buttonColor': this.buttonColor ? this.buttonColor : null,
      'scaffoldBackgroundColor': this.scaffoldBackgroundColor ? this.scaffoldBackgroundColor : null,
      'cardColor': this.cardColor ? this.cardColor : null,
      'dividerColor': this.dividerColor ? this.dividerColor : null,
      'errorColor': this.errorColor ? this.errorColor : null,
      'bottomAppBarColor' : this.bottomAppBarColor ? this.bottomAppBarColor : null,
      'backgroundColor' : this.backgroundColor ? this.backgroundColor : null,
      'iconColor' : this.iconColor ? this.iconColor : null,
      'gradientColors' : this.gradientColors ? this.gradientColors : null,
    };
  }


  get accentColor(): string {
    return this._accentColor;
  }

  set accentColor(value: string) {
    this._accentColor = value;
  }

  get primaryColor(): string {
    return this._primaryColor;
  }

  set primaryColor(value: string) {
    this._primaryColor = value;
  }

  get buttonColor(): string {
    return this._buttonColor;
  }

  set buttonColor(value: string) {
    this._buttonColor = value;
  }

  get scaffoldBackgroundColor(): string {
    return this._scaffoldBackgroundColor;
  }

  set scaffoldBackgroundColor(value: string) {
    this._scaffoldBackgroundColor = value;
  }

  get cardColor(): string {
    return this._cardColor;
  }

  set cardColor(value: string) {
    this._cardColor = value;
  }

  get dividerColor(): string {
    return this._dividerColor;
  }

  set dividerColor(value: string) {
    this._dividerColor = value;
  }

  get errorColor(): string {
    return this._errorColor;
  }

  set errorColor(value: string) {
    this._errorColor = value;
  }

  get bottomAppBarColor(): string {
    return this._bottomAppBarColor;
  }

  set bottomAppBarColor(value: string) {
    this._bottomAppBarColor = value;
  }

  get backgroundColor(): string {
    return this._backgroundColor;
  }

  set backgroundColor(value: string) {
    this._backgroundColor = value;
  }

  get iconColor(): string {
    return this._iconColor;
  }

  set iconColor(value: string) {
    this._iconColor = value;
  }

  get gradientColors(): string[] {
    return this._gradientColors;
  }

  set gradientColors(value: string[]) {
    this._gradientColors = value;
  }
}



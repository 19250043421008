import {Dateformat} from "dateformat";

export class DateFormat{

  static getDate({zone = 7, format, date}){
    let now = new Date();
    if (date) {
      now = new Date(date);
    }
    now.setHours(now.getHours() + zone);
    return format ? Dateformat(now, format) : now;
  }

  static getTimeStamp(nextSeconds = 0){
    return (new Date()).getTime() + (nextSeconds * 1000);
  }

  static toTimeStamp(anyDate){
    return new Date(anyDate).getTime();
  }



  static convertTimeToMin(opts){

    const conversionTable = {
      minutes: 1,
      hours: 60,
      days: 24*60
    };

    return Object.keys(opts).reduce((fin, timeKey) => (
        fin + opts[timeKey] * conversionTable[timeKey]
      ), 0);
  }

  static convertMinToDDHHMM(minutes){

    let dates=[1440,60,1];
    let output=[0,0,0];
    for(let x=0; x<3; x++)
    {
      while(minutes>=dates[x])
      {
        minutes-=dates[x];
        output[x]++;
      }
    }

    return {days : output[0],hours: output[1],minutes : output[2]};
  }
}




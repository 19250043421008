import {FirebaseService} from "../../services/firebase.service";
import {CategoryUtils} from "../../utils/category-utils";
import {SharedPreference} from "../../shared-preference/shared-perference";
import {LoggUtilInfo} from "../../utils/loggUtil";
import {StringUtils} from "../../utils/string-utils";
import {Role} from '../../model/role';
import {StoreUtils} from '../../utils/store-utils';

export class CategoryService {
  static onGetCategory(firebaseService: FirebaseService, onThen?: Function, onCatch?: Function) {
    let storeId: string;
    if ( Role.hasVersitaAdmin(SharedPreference.getUser()) || Role.hasVersitaCustomerService(SharedPreference.getUser()) || Role.hasVersitaSupervisor(SharedPreference.getUser())) {
      storeId = StoreUtils.getSelectStoreId();
    } else {
      storeId = SharedPreference.getStoreId();
    }

    if(storeId && storeId !== "versitaSetting") {
      let pathItemCount = StringUtils.readDocument + FirebaseService
        .getPathCounterInventory(storeId) +"/"+ FirebaseService.categoryCollection;

      firebaseService.onReadDocument(
        FirebaseService.getPathCounterInventory(storeId),
        FirebaseService.categoryCollection
      ).then((value => {
        if (value.exists) {
          console.log("------>>> category admin store ---->> ", value.data());
          CategoryUtils.setItemCount(value);
        } else {
          console.log("------>>> category does not exist !!!");
          CategoryUtils.resetItemCount();
        }

        LoggUtilInfo.loggingEnd(pathItemCount, ` component : list category`);
      })).catch((error) => {
        LoggUtilInfo.loggingError(pathItemCount + error.toString(), ` component : list category`);
      });
    }

    firebaseService.onReadDocument(FirebaseService.category, FirebaseService.categoryCollection).then((value => {
      if (value.exists) {

        if (!storeId || storeId === "versitaSetting") {
          CategoryUtils.setItemCount(value);
        }
        CategoryUtils.setCategory(value);
      }

      if(onThen) {
        onThen();
      }
      LoggUtilInfo.loggingEnd(CategoryUtils.getLoggingMsg(), ` component : list brand`);
    })).catch((error) => {
      if(onCatch) {
        onCatch();
      }
      LoggUtilInfo.loggingError(CategoryUtils.getLoggingMsg() + error.toString(), ` component : list brand`);
    });
  }
}

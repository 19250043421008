import {Order} from "../model/order";

/**
 * Created by cheaphakdey on 23/3/19.
 */

export class OrderStatusUtil{
  static openOrderStatus: Map<string, Order> = new Map();
  static allOrderStatus: Map<string, any> = new Map();
  static searchOrderStatus: Order[];
  static searchKeyword: string = null;
  static isSelectedOpenOrder: boolean = true;
  static closedOrderStatus: Map<string, Order> = new Map();
  static documentMapOrder: string[];
  static documentMapClosedOrder: string[];
}

import {GetSafeValue} from '../utils/get-safe-value';
import {StringFieldUtil} from '../utils/string-field-util';

/**
 * Created by cheaphakdey on 17/1/19.
 */

export class Address{
  private _addressLineOne: string;
  private _addressLineTwo: string;
  private _country: string;
  private _city: string;
  private _state: string;
  private _postCode: string;

  constructor(){
    this.addressLineOne = '';
    this.addressLineTwo = '';
    this.country = '';
    this.city = '';
    this.state = '';
    this.postCode = '';
  }

  get addressLineOne(): string {
    return this._addressLineOne;
  }

  set addressLineOne(value: string) {
    this._addressLineOne = value;
  }

  get addressLineTwo(): string {
    return this._addressLineTwo;
  }

  set addressLineTwo(value: string) {
    this._addressLineTwo = value;
  }

  get country(): string {
    return this._country;
  }

  set country(value: string) {
    this._country = value;
  }

  get city(): string {
    return this._city;
  }

  set city(value: string) {
    this._city = value;
  }

  get state(): string {
    return this._state;
  }

  set state(value: string) {
    this._state = value;
  }

  get postCode(): string {
    return this._postCode;
  }

  set postCode(value: string) {
    this._postCode = value;
  }

  fromJson(data: Address) {
  this.addressLineOne = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.addressLineOne], null);
  this.addressLineTwo = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.addressLineTwo], null);
  this.country = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.country], null);
  this.city = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.city], null);
  this.state = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.state], null);
  this.postCode = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.postCode], null);
}

  toMap(){
    return {
      'addressLineOne': this.addressLineOne,
      'addressLineTwo': this.addressLineTwo,
      'country': this.country,
      'city': this.city,
      'state': this.state,
      'postCode': this.postCode,
    };
  } 

  clone(address: Address) {
    this.addressLineOne = address.addressLineOne;
    this.addressLineTwo = address.addressLineTwo;
    this.country = address.country;
    this.city = address.city;
    this.state = address.state;
    this.postCode = address.postCode;
  }

  getFullAddress(): string {
    return (this.addressLineOne == null || this.addressLineOne.trim() == ''? '' : this.addressLineOne + ', ') +
      (this.addressLineTwo == null || this.addressLineTwo.trim() == '' ? '' : this.addressLineTwo + ', ') +
      (this.city == null ||  this.city.trim() == '' ? '' : this.city + ', ') +
      (this.state == null || this.state.trim() == '' ? '' : this.state + ', ') +
      (this.postCode == null || this.postCode.trim() == '' ? '' : this.postCode + ', ' ) +
      (this.country == null || this.country.trim() == '' ? '' : this.country);
  }
}

export class OurTeam {
  fullName: string;
  imageUrl: string;
  quote: string;

  constructor () {
    this.fullName = '';
    this.imageUrl = '';
    this.quote = ''
  }
}

import {SharedPreference} from '../shared-preference/shared-perference';
import {Role} from '../model/role';
import {SystemConfigUtils} from './system-config-utils';
import {Message} from '../model/message';
import {FirebaseService} from '../services/firebase.service';
import {StringUtils} from './string-utils';
import {IChat} from "../model/test-chat";
import {RightSidebarComponent} from "../shared/right-sidebar/rightsidebar.component";
import {SubscriptionUtils} from "./subscriptionUtils";
import {Subscription} from "rxjs";
import {LoggUtilInfo} from './loggUtil';

export class ChatsUtils{
  static counterUnread: number;
  static iChat: IChat;
  static subscriptionUtils : SubscriptionUtils = new SubscriptionUtils();
  static subscriptionUtilChatByUid : SubscriptionUtils = new SubscriptionUtils();

  static getChats(firebaseService: FirebaseService, storeId: string) {
    if (SharedPreference.getUser() && !SharedPreference.getUser().isRequireChangePassword &&
      (Role.hasCustomerServiceRole(SharedPreference.getUser()) || Role.hasVersitaCustomerService(SharedPreference.getUser())) && SystemConfigUtils.getMessageDisplayDay() !== 0) {
      let subscriptionName: string = FirebaseService.chat;
      let subscription: Subscription = firebaseService
        .onGetChat(SystemConfigUtils.getMessageDisplayDay(), storeId).subscribe(data => {
          if(data){
            LoggUtilInfo.loggingListen(StringUtils.listenSnapshot + FirebaseService.chat + " component: chat utils");
            let smsData = StringUtils.objToStrMap(data as Message);
            let fromDate = new Date().setDate(new Date().getDate() - SystemConfigUtils.getMessageDisplayDay());

            if(smsData){
              let mapSMS: Map<string, Message> = new Map();
              smsData.forEach((v, k) => {
                  let toDate = Date.parse(v.date);
                  if(toDate >= fromDate) {
                    mapSMS.set(k, v);
                    this.iChat = RightSidebarComponent.instance;
                    this.iChat.updateChat(mapSMS);
                    this.setMessageData(mapSMS);
                  }
                });
            }else {
              LoggUtilInfo.loggingEnd('No Documents of CHAT DATA.');
            }
          LoggUtilInfo
            .loggingEnd(StringUtils.listenSnapshot + FirebaseService.chat + ` ${StringUtils.lastMessageDate} >= ${SystemConfigUtils.getMessageDisplayDay()}` + " component: chat utils");
          }
        });

      this.subscriptionUtils.subscribe(subscriptionName, subscription);
    }
  }

  static setMessageData(messagesData: Map<string, Message>) {
    this.counterUnread = 0;
    messagesData.forEach((v, k)=>{
      if (!v.isRead && v.role != StringUtils.roleCustomerService) {
        this.counterUnread++;
      }
    });

    return this.counterUnread;
  }

  static unsubscribeChat(){
    this.subscriptionUtils.unsubscribeAll();
  }

  static unsubscribeChatByUid(){
    this.subscriptionUtilChatByUid.unsubscribeAll();
  }

  static getChatByUid(firebaseService: FirebaseService, uid: string, storeId: string, callback: Function){
    let subscriptionName = FirebaseService.chat +"/message/"+ storeId +"/"+ uid ;
    let subscription: Subscription = firebaseService.onGetChatByUid(uid, storeId).subscribe((data)=>{
      LoggUtilInfo.loggingListen(StringUtils.listenSnapshot + subscriptionName + " class: ChatsUtils");
      callback(data);
    }, error => {
      LoggUtilInfo.loggingError("chat util getChatByUid", error);
    } );

    this.subscriptionUtilChatByUid.subscribe(subscriptionName, subscription);
  }
}

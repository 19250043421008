import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Role} from '../model/role';
import {SharedPreference} from '../shared-preference/shared-perference';
import {categoryList, dashboardUrl, profile, bussinessConfigurationUrl, userAccess} from '../inventory/route';
import {orderList} from '../order/route';
import {SystemConfigUtils} from '../utils/system-config-utils';
import {AuthenticationService} from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuardService implements CanActivate{

  constructor(private router: Router, private authenticationService: AuthenticationService ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (!SharedPreference.getUser()) {
      this.authenticationService.onLogout(false);
      return false;
    }

    if (SharedPreference.getUser().isRequireChangePassword) {
      this.router.navigate([profile]);
      return false;
    } else {
      if (Role.hasAdminRole(SharedPreference.getUser()) ||
        Role.hasSupervisorRole(SharedPreference.getUser()) ||
        Role.hasVersitaSystem(SharedPreference.getUser()) ||
        Role.hasVersitaAdmin(SharedPreference.getUser())
      ){
        if (Role.hasSupervisorRole(SharedPreference.getUser()) && !Role.hasVersitaAdmin(SharedPreference.getUser()) && !Role.hasAdminRole(SharedPreference.getUser())){
          if (SystemConfigUtils.getCanUpdateCustomerUser()) {
            return SystemConfigUtils.getCanUpdateCustomerUser();
          } else {
            this.router.navigate([dashboardUrl]);
            return false;
          }
        }
        return true;
      } else {
        if (Role.hasVersitaSystem(SharedPreference.getUser())) {
          this.router.navigate([bussinessConfigurationUrl]);
        } else if (Role.hasVersitaSale(SharedPreference.getUser())) {
        } else if (Role.hasCustomerServiceRole(SharedPreference.getUser())) {
          this.router.navigate([dashboardUrl]);
        } else if (Role.hasVersitaAdmin(SharedPreference.getUser())) {
          this.router.navigate([categoryList]);
        } else {
          this.router.navigate([orderList]);
        }
        return false;
      }
    }
  }
}

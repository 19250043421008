import { throwError } from 'rxjs';

export class HttpError {
  public static handleError(error) {
    let errorMessage = {};
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = {message: error.error.message};
    } else {
      // Get server-side error
      let detail = error.error && error.error.details && error.error.details.length > 0 && error.error.details[0].message;
      errorMessage =  {status: error.status, message: error.error.message, detail: detail};
    }
    console.log('error', error);
    return throwError(errorMessage);
  }
}

import {Subscription} from 'rxjs';

export class SubscriptionUtils {
  subscriptions: Map<string, Subscription> = new Map();

  isNotAlreadySubscribe(name: string) : boolean {
    return !this.subscriptions.has(name);
  }

  subscribe(name: string, subscription: Subscription) {
    console.log("subscribe", name);
    this.subscriptions.set(name,subscription);
  }

  unsubscribe(name: string) {
    if(this.subscriptions && this.subscriptions[name]) {
      let subscription: Subscription = this.subscriptions[name];
      subscription.unsubscribe();
      this.subscriptions.delete(name);
    }
  }

  unsubscribeAll() {
    this.subscriptions.forEach((value, key ) => {
      console.log('unsubscribe', key);
      value.unsubscribe();
    });
    this.subscriptions.clear();
  }
}


/**
 * Created by cheaphakdey on 1/2/19.
 */


export const brandList: string = '/inventory/brand/list';
export const brandCreate: string = '/inventory/brand/create';
export const categoryList: string = '/inventory/category/list';
export const categoryListLogin: string = '#'+categoryList;
export const itemList: string = '/inventory/item/list';
export const biddingList: string = '/inventory/bidding_item/list_bid';
export const biddingCreate: string = '/inventory/bidding_item/create_update_bid';
export const suggestVariation: string = '/inventory/suggest-variation';
export const itemCreate: string = '/inventory/item/create';
export const categoryCreate: string = '/inventory/category/create';
export const home: string = categoryListLogin;
export const dashboardUrl = '/dashboard';
export const orderListUrl = '/order/list';
export const closeOrderListUrl = '/order/closed-order-list';
export const searchOrderListUrl = '/order/search-order-list';
export const tempOrderListUrl = '/order/temp-order-list';
export const bussinessConfigurationUrl = '/business-configuration';
export const requestDemoUrl = '/request-demo/list';
export const versitaSoresListUrl = '/versita-stores/list';
export const versitaStoresCreateUrl = '/versita-stores/create';
export const profile = '/profile';
export const versitaStoresUpdateUrl = '/versita-stores/update';
export const loginUrl = "/login";
export const homePageUrl = "/home-page";
export const userAccess = "/userAccess";
export const storeSetting = "/store-setting";
export const createNotification = "/notifications/create";

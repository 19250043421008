import {StringUtils} from './string-utils';

export class GetSafeValue {

  public static getSafeValueAsString(valueFromDb: any, defaultValue: string): string{
    try {
      if (!valueFromDb) {
        return defaultValue;
      }
      return valueFromDb.toString();
    } catch (e) {
      console.log(StringUtils.getSafeValueError + e);
      return defaultValue;
    }
  }

  public static getSafeValueAsNumber(valueFromDb: any, defaultValue: number): number{
    try {
      if (!valueFromDb) {
        return defaultValue;
      }
      return Number.parseFloat(valueFromDb);
    } catch (e) {
      console.log(StringUtils.getSafeValueError + e);
      return defaultValue;
    }
  }

  public static getSafeValueAsBoolean(valueFromDb: any, defaultValue: boolean): boolean{
    try {
      if (!valueFromDb && valueFromDb != false){
        return defaultValue;
      }
      return valueFromDb;
    } catch (e) {
      console.log(StringUtils.getSafeValueError + e);
      return defaultValue;
    }
  }

  public static getSafeValueAsList(valueFromDb: any, defaultValue: any, finalValue: any): [any]{
    try{
      if (!valueFromDb || valueFromDb === "" || valueFromDb == null){
        return defaultValue;
      }
      for(let i = 0 ; i < valueFromDb.length; i++){
        try{
          try {
            if(valueFromDb[i] != null && valueFromDb[i] && valueFromDb[i] !== "") {
              finalValue.push(valueFromDb[i]);
            }
          } catch (e){
            console.log(StringUtils.getSafeValueError + e);
          }
        } catch (e){
          console.log(StringUtils.getSafeValueError + e);
        }
      }
      return finalValue;
    } catch (e){
      return defaultValue;
    }
  }

  public static getSafeValueAsMap(valueFromDb: any, defaultValue: any): Map<any, any>{
    try {
      if (!valueFromDb) {
        return defaultValue;
      }
      return valueFromDb;
    } catch (e) {
      console.log(StringUtils.getSafeValueError + e);
      return defaultValue;
    }
  }

}

import {GetSafeValue} from "../utils/get-safe-value";
import {StringFieldUtil} from "../utils/string-field-util";

export class FacebookConfig {
  private _accessToken: string;
  private _pageId: string;
  private _clientId: string;
  private _clientSecret: string;

  constructor(){

  }

  fromJson(data: FacebookConfig) {
    this.accessToken = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.accessToken], null);
    this.pageId = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.pageId], null);
    this.clientId = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.clientId], null);
    this.clientSecret = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.clientSecret], null);
  }

  get accessToken(): string {
    return this._accessToken;
  }

  set accessToken(value: string) {
    this._accessToken = value;
  }

  get pageId(): string {
    return this._pageId;
  }

  set pageId(value: string) {
    this._pageId = value;
  }

  get clientId(): string {
    return this._clientId;
  }

  set clientId(value: string) {
    this._clientId = value;
  }

  get clientSecret(): string {
    return this._clientSecret;
  }

  set clientSecret(value: string) {
    this._clientSecret = value;
  }

  toMap(){
    return {
      'accessToken': this.accessToken,
      'pageId': this.pageId,
      'clientId': this.clientId,
      'clientSecret': this.clientSecret,
    };
  }
}

import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Role} from '../model/role';
import {SharedPreference} from '../shared-preference/shared-perference';
import {brandList, dashboardUrl, profile, bussinessConfigurationUrl} from '../inventory/route';
import {FirebaseService} from '../services/firebase.service';
import {StoreUtils} from '../utils/store-utils';
import {LoggUtilInfo} from '../utils/loggUtil';
import {StringUtils} from '../utils/string-utils';
import {VersitaStores} from '../model/versita-stores';
import {AuthenticationService} from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ExportBoardGuardService implements CanActivate{

  constructor(private router: Router,
              private firebaseService: FirebaseService, private  authenticationService: AuthenticationService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!SharedPreference.getUser()) {
      this.authenticationService.onLogout(false);
      return false;
    }

    if(Role.hasVersitaSupervisor(SharedPreference.getUser())){
      return true;
    }

    let isExport = false;

    if(StoreUtils.store.businessSetting.isExportEnable !== undefined || Role.hasVersitaAdmin(SharedPreference.getUser())){
      isExport = this.checkPermission(StoreUtils.store.businessSetting.isExportEnable);

    }else {
      await this.firebaseService.onReadDocument(FirebaseService.store, SharedPreference.getUser().currentStoreId).then((value => {
        if (value.exists) {
          StoreUtils.store = value.data() as VersitaStores;
          isExport = this.checkPermission(StoreUtils.store.businessSetting.isExportEnable);
          LoggUtilInfo.loggingEnd(StringUtils.listenSnapshot + FirebaseService.store +"/"+ SharedPreference.getUser().currentStoreId + ' component : export guard');
        }
      })).catch((e)=>{
        LoggUtilInfo.loggingError('from Dashboard',e.toString());
      });
    }
    return  isExport;
  }

  private checkPermission(isExport: boolean) {
    if (SharedPreference.getUser().isRequireChangePassword) {
      this.router.navigate([profile]);
      isExport = false;
    } else {
      if (StoreUtils.store.businessSetting.isExportEnable &&
        (Role.hasAdminRole(SharedPreference.getUser()) || Role.hasSupervisorRole(SharedPreference.getUser())) ||
         Role.hasVersitaAdmin(SharedPreference.getUser())) {
        isExport = true;
      } else {
        if (Role.hasVersitaSystem(SharedPreference.getUser())) {
          this.router.navigate([bussinessConfigurationUrl]);
        } else if (Role.hasCustomerServiceRole(SharedPreference.getUser())) {
          this.router.navigate([dashboardUrl]);
        } else {
          this.router.navigate([brandList]);
        }
        isExport = false;
      }
    }
    return isExport;
  }
}

import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import {AuthenticationService} from '../services/authentication.service';
import {Router} from '@angular/router';
import {SharedPreference} from '../shared-preference/shared-perference';
import {environment} from '../../environments/environment';
import {NumberUtils} from './number-utils';
import {StringUtils} from './string-utils';
import {MethodUtil} from './method-util';

export class SessionTimeout {
  static sessionTimeout(idle: Idle, authenticationService: AuthenticationService, router: Router) {
    idle.setIdle(environment.sessionTimeout);
    idle.setTimeout(NumberUtils.countDownNumber);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    idle.onTimeout.subscribe(() => {
      authenticationService.onLogout(true);
      MethodUtil.alertMessageAndCloseButton(StringUtils.sessionTimeout, StringUtils.youHaveBeenSignedOut);
    });

    router.events.subscribe((val) => {
      if((!window.location.href.includes(StringUtils.login) || !window.location.href.includes(StringUtils.homePage)) && SharedPreference.getUser())
        idle.watch();
      else
        idle.stop();

    });
  }

}

import {AfterViewInit, Component, OnInit} from '@angular/core';
import {SharedPreference} from '../../shared-preference/shared-perference';
import {Role} from '../../model/role';
import {StoreUtils} from '../../utils/store-utils';
import {SystemConfigUtils} from '../../utils/system-config-utils';
import {FirebaseService} from '../../services/firebase.service';
import {LoggUtilInfo} from '../../utils/loggUtil';
import {StringUtils} from '../../utils/string-utils';
import {StringFieldUtil} from '../../utils/string-field-util';
import {LanguageServiceService, StoreIdService} from '../../services';
import {Router} from '@angular/router';
import {HomePageMockUp} from '../../model/mock-up/home-page-mock-up';
import {ToasterService} from "../../services/toaster.service";
import {profile} from '../../inventory/route';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements AfterViewInit, OnInit {
  // this is for the open close
  isActive = true;
  showMenu = '';
  showSubMenu = '';
  btnNavbarCollapse = 'navbar-toggler collapsed';
  navbarCollapse = 'navbar-collapse collapse';
  listStoreIds: Map<string, string> = new Map();
  language: string;
  languageFromHeader$: any = null;
  menusForHomePage: any[];
  posHomepageMenu: any[];
  listStoreKey: string[] = [];
  selectedItems = [];
  itemList: any = [];
  temStoreId: string;
  login: Map<string, string> = new Map();
  posSystem: Map<string, string> = new Map();

  dropdownSettings = {
    singleSelection: true,
    text: "Select Store Id",
    enableSearchFilter: true,
    noDataLabel: 'Search item',
    maxHeight: 200,
    disabled: false
  };
  storeIdFromHeader$: any = null;

  constructor (
    private _firebaseService: FirebaseService,
    private storeIdService: StoreIdService,
    public router: Router,
    public languageService: LanguageServiceService,
    public toaster: ToasterService
  ) {
  }

  ngOnInit(): void {
    this.temStoreId = StoreUtils.getSelectStoreId();
    this.language = 'en';
    this.languageFromHeader$ = this.languageService.onGetLanguage();
    this.login.set("en", "Login");
    this.login.set("kh", "ចូល");
    this.posSystem.set('en', "POS system");
    this.posSystem.set('kh', "ប្រព័ន្ធ POS");
    this._firebaseService.onGetDataDocumentStoreList(FirebaseService.storeList).then((value => {
      LoggUtilInfo.loggingEnd(StringUtils.readDocument + FirebaseService.storeList + StringUtils.componentAdminStore);

      let mapStoreIdFromServer: Map<any, any> = StringUtils.objToStrMap(value.data());
      let mapList:  any = [];
      mapStoreIdFromServer.forEach((v, k) => {
          mapList.push({key: k, value: v[StringFieldUtil.storeName]});}
      );
          mapList.push({key: StringUtils.versitaSettingKey, value: StringUtils.versitaSettingValue});

      let data = mapList.sort((a, b) =>
        a.value &&
        b.value &&
        a.value.toLowerCase().localeCompare(b.value.toLowerCase())
      );

      data.forEach((v) =>{
        this.listStoreKey.push(v.key);
        this.listStoreIds.set(v.key, v.value);
        const itemObject = {"id": v.key, 'itemName': v.key + " : " + v.value};
        this.itemList.push(itemObject);
      });

      this.storeIdFromHeader$ = this.storeIdService.onGetStoreId();

      StoreUtils.storeIdMap = this.listStoreIds;

    })).catch((error) => {
      LoggUtilInfo.loggingError(StringUtils.readDocument + FirebaseService.storeList + error.toString() + StringUtils.componentAdminStore);
    });

   this.menusForHomePage = HomePageMockUp.menuHomePage();
   this.posHomepageMenu = HomePageMockUp.posHomePageMenu();

    if(!this.isVersitaAdmin() && SharedPreference.getStoreId() && (!StoreUtils.versitaStore || !StoreUtils.versitaStore.get(SharedPreference.getStoreId()))){
      this.onToastShow(SharedPreference.getStoreId());
    }
  }

  private onToastShow(storeId: string) {
    if(SystemConfigUtils.getIsSingleStoreBCSetting() === false){
      let self = this;
      StoreUtils.onGetStoreForSetting(this._firebaseService, storeId, function (v) {
        if (!v.businessSetting.isGoLive) {
          StringUtils.isGoLiveFalse(self.toaster);
        }
      });
    }
  }

  OnItemDeSelect(event: any) {
    this.selectedItems = [];
  }

  isDashboard(){
    if(StoreUtils.store.businessSetting.isDashboardEnable === undefined){
      return this.isVersitaCustomerService() || this.isVersitaSupervisor();

    }
    if (this.isVersitaCustomerService() || this.isVersitaSupervisor()) {
      return true;
    }
    return StoreUtils.store.businessSetting.isDashboardEnable;
  }

  isSingleStore(){
    return SystemConfigUtils.getIsSingleStoreBCSetting();
  }

  isExport(){
    if(StoreUtils.store.businessSetting.isExportEnable === undefined || this.isVersitaSupervisor()){
      return false;
    }
    return StoreUtils.store.businessSetting.isExportEnable;
  }

  isCustomerService(){
    if (!SharedPreference.getUser()){
      return false;
    }
    return Role.hasCustomerServiceRole(SharedPreference.getUser()) || this.isVersitaCustomerService();
  }

  isAdmin(){
    if (!SharedPreference.getUser()) {
      return false;
    }
    return Role.hasAdminRole(SharedPreference.getUser()) || this.isVersitaAdmin();
  }

  isAdminOnly(){
    if (!SharedPreference.getUser()) {
      return false;
    }
    return Role.hasAdminRole(SharedPreference.getUser());
  }

  isVersitaAdmin(){
    if (!SharedPreference.getUser()) {
      return false;
    }
    return Role.hasVersitaAdmin(SharedPreference.getUser());
  }

  isVersitaCustomerService() {
    return Role.hasVersitaCustomerService(SharedPreference.getUser());
  }

  isVersitaSupervisor() {
    return Role.hasVersitaSupervisor(SharedPreference.getUser());
  }

  getSelectStoreId() {
    if (StoreUtils.getSelectStoreId()) {
      return StoreUtils.getSelectStoreName() +": " + StoreUtils.getSelectStoreId();
    } else {
      return "Select store id"
    }
  }

  isVersitaStore(){
    if (!SharedPreference.getUser()) {
      return false;
    }
    return Role.hasVersitaStoreRole(SharedPreference.getUser());
  }

  isVersitaSales(){
    if (!SharedPreference.getUser()) {
      return false;
    }
    return Role.hasVersitaSale(SharedPreference.getUser());
  }

  isSupervisor(){
    if (!SharedPreference.getUser()) {
      return false;
    }
    return Role.hasSupervisorRole(SharedPreference.getUser()) || this.isVersitaSupervisor();
  }

  canUpdateCustomerUser() {
    if (SystemConfigUtils.getCanUpdateCustomerUser()) {
      return SystemConfigUtils.getCanUpdateCustomerUser();
    } else {
      return false;
    }
  }

  isPackage(){
    if (!SharedPreference.getUser()) {
      return false;
    }
    return Role.hasPackageRole(SharedPreference.getUser());
  }

  isVersitaSystem() {
    if (!SharedPreference.getUser()) {
      return false;
    }
    return Role.hasVersitaSystem(SharedPreference.getUser());
  }

  isDelivery(){
    if (!SharedPreference.getUser()) {
      return false;
    }
    return Role.hasDeliveryRole(SharedPreference.getUser());
  }

  getIsShowingMenu(): boolean {
    return SharedPreference.isShowingMenu || this.isOnHomePage();
  }

  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }
  eventCalled() {
    this.isActive = !this.isActive;
  }

  isTermAndConditionEnable(): boolean{
    return SystemConfigUtils.getIsTermAndConditionEnable();
  }
  // End open close  btnNavbarCollapse = 'navbar-toggler';
  //   navbarCollapse = 'navbar-collapse collapse show';
  ngAfterViewInit() {}


  clickNavBar(){
    this.btnNavbarCollapse = 'navbar-toggler collapsed';
    this.navbarCollapse = 'navbar-collapse collapse';
  }


  clickBtn(str: string){
    if(str == 'navbar-toggler collapsed'){
      this.btnNavbarCollapse = 'navbar-toggler';
      this.navbarCollapse = 'navbar-collapse collapse show';
    }else{
      this.btnNavbarCollapse = 'navbar-toggler collapsed';
      this.navbarCollapse = 'navbar-collapse collapse';
    }
  }


  onSelectStoreId(storeId: string, storeName: string): void {
    this.toaster.clear();
    // send message to subscribers via observable subject
    console.log("send message from header..");
    // StoreUtils.setSelectStoreId(storeId);
    this.storeIdService.onStoreIdChange(storeName, storeId);
    if (storeId && !StoreUtils.versitaStore || !StoreUtils.versitaStore.get(storeId)) {
      this.onToastShow(storeId);
    }
    this.clickNavBar();
  }

  isOnHomePage() {
    return this.router.url === "/home-page";
  }

  isUserLogin() {
    return SharedPreference.getLogin();
  }

  async onChangeLanguage(lang: string) {
    if (lang === null) {
      this.language = 'en';
    } else {
      this.language = lang;
    }

    this.languageService.onChangeLanguage(lang);

  }

  isOnJoinAsPartner() {
    return this.router.url.includes("/join-as-partner");
  }

  isOnPartnerRegister() {
    return this.router.url.includes("/partner-register");
  }

  async onChangeStoreIdAndName(storeId: string, storeName: string) {
    if(storeId && storeName && this.temStoreId !== storeId){
      const itemObject = {'id': storeId, 'itemName': storeName.includes(":") ? storeName : storeId + " : " + storeName};
      this.selectedItems = [itemObject];
      this.temStoreId = storeId;
    }
  }

  getStyle() {
    return StringUtils.getFontStyle(this.language);
  }

  onClickMenuHomePage(elementId: string) {
    console.log("//// element id ", elementId.trim().toLowerCase().split(" ").join("-"));

    this.clickBtn(this.btnNavbarCollapse);
    let id = elementId.trim().toLowerCase().split(" ").join("-");
    let element = document.getElementById(id);
    console.log("element", element.offsetTop + 70);
    window.scroll(0,element.offsetTop - 150);
    // element.scrollIntoView({behavior: 'smooth'});
  }

  async onSearch(event) {
    const keyword = event.target.value;
    if (keyword) {
      let search = [];
      this.listStoreIds.forEach((value, key) => {
        if (value.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) || key.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())) {
          const itemObject = {"id": key, 'itemName': key + " : " + value};
          search.push(itemObject);
        }
      });

      this.itemList = [];
      this.itemList = search;
    } else {
      this.itemList = [];
      this.listStoreIds.forEach((value, key) => {
        const itemObject = {'id': key, 'itemName': key + " : " + value};
        this.itemList.push(itemObject);
      });
    }
  }
}

import {StringUtils} from "./string-utils";
import {DatePipe} from "@angular/common";
import {Order} from '../model/order';
import {OrderStatusUtil} from './order-status-util';
import {StoreUtils} from "./store-utils";
/**
 * Created by cheaphakdey on 25/2/19.
 */

export class OrderUtil{
  static order: Map<string, Order>;
  static orderStatusDocument: string;
  static email: string = '';

  static renderStatus(tracking){
    if(tracking.currentStatus.status == null || tracking.currentStatus.status == ''){
      return '';
    } else if(tracking.currentStatus.status == StringUtils.orderReceived ||
      tracking.currentStatus.status == StringUtils.orderProcessing ||
      tracking.currentStatus.status == StringUtils.orderProcessed){
      return `<div class="labelOrange">${tracking.currentStatus.status}</div>`;
    }else  if(tracking.currentStatus.status == StringUtils.orderPackaging ||
      tracking.currentStatus.status == StringUtils.orderPackaged){
      return `<div class="labelYellow">${tracking.currentStatus.status}</div>`;
    }else  if(tracking.currentStatus.status == StringUtils.orderDelivering ||
      tracking.currentStatus.status == StringUtils.orderDelivered){
      return `<div class="labelBlue">${tracking.currentStatus.status}</div>`;
    }else  if(tracking.currentStatus.status == StringUtils.orderCustomerReceived ||
      tracking.currentStatus.status == StringUtils.orderCompleted){
      return `<div class="labelGreen">${tracking.currentStatus.status}</div>`;
    }else {
      return `<div class="labelRed">${tracking.currentStatus.status}</div>`;
    }
  }

  static renderOrderStatus(orderStatus){
    if(orderStatus == null || orderStatus == ''){
      return '';
    } else if(orderStatus == StringUtils.orderReceived ||
      orderStatus == StringUtils.orderProcessing ||
      orderStatus == StringUtils.orderProcessed){
      return `<div class="labelOrange">${orderStatus}</div>`;
    }else  if(orderStatus == StringUtils.orderPackaging ||
      orderStatus == StringUtils.orderPackaged){
      return `<div class="labelYellow">${orderStatus}</div>`;
    }else  if(orderStatus == StringUtils.orderDelivering ||
      orderStatus == StringUtils.orderDelivered){
      return `<div class="labelBlue">${orderStatus}</div>`;
    }else  if(orderStatus == StringUtils.orderCustomerReceived ||
      orderStatus == StringUtils.orderCompleted){
      return `<div class="labelGreen">${orderStatus}</div>`;
    }else {
      return `<div class="labelRed">${orderStatus}</div>`;
    }
  }

  static renderPaymentStatus(isPaid){
    if(isPaid == null || isPaid === undefined || !isPaid ){
      return `<div class="grey">Not Paid</div>`;
    } else if(isPaid){
      return `<div class="green">Paid</div>`;
    }else {
      return `<div class="grey">Not Paid</div>`;
    }
  }

  static renderTotal(total){
    return `<label class="text-success mt-3 pull-right"><td>${total == null ? '' : StringUtils.getFormatCurrency(total)}</td></label>`;
  }

  static renderBufferQty(bufferQty){
    return `<label class="text-danger text-right pull-right"><b>${bufferQty == null ? '' : bufferQty}</b></label>`;
  }

  static renderAvailableQty(availableQty){
    return `<label class="text-danger text-right pull-right"><b>${availableQty == null ? '' : availableQty}</b></label>`;
  }

  static renderDate(orderDate){
    return orderDate == null ? '' :
      `${ new DatePipe(StringUtils.enUs).transform(orderDate, StringUtils.shortDate)}`;
  }

  static renderBuyerName(user){
    return `<label>${user.firstName +" "+ user.lastName}</label>`;
  }

  static renderShippingAddress(user){
    return `<div><label>
             <b>${user.firstName == null || user.lastName == null ? '' : user.firstName +" "+user.lastName}</b>
             ${user.address.addressLineOne == null ? '' : user.address.addressLineOne}
             ${user.address.addressLineTwo == null ? '' : user.address.addressLineTwo}
             ${user.address.country == null ? '' : user.address.country}
           </label></div>`;
  }

  static renderTitle(title){
    return `<label>
             ${title[StringUtils.en] == null ? '' : title[StringUtils.en]} 
            </label> 
            <label>
             ${title[StringUtils.kh] == null ? '' : title[StringUtils.kh]}
            </label>`;
  }

  static filterStatus(status, search){
    if(status != null || status != undefined) {
      return StringUtils.compareStringIgnoreCase(status, search)
    }
    return  StringUtils.compareStringIgnoreCase(status, search);
  }

  static filterTitle(title, search){
    if (title[StringUtils.kh] != null) {
      return StringUtils.compareStringIgnoreCase(title[StringUtils.en], search)
        || StringUtils.compareStringIgnoreCase(title[StringUtils.kh], search);
    }
    return StringUtils.compareStringIgnoreCase(title[StringUtils.en], search);
  }

  static filterBuyerName(user, search){
    if(user.firstName != null || user.lastName != undefined) {
      return StringUtils.compareStringIgnoreCase(user.firstName, search)
        || StringUtils.compareStringIgnoreCase(user.lastName, search);
    }
    return  StringUtils.compareStringIgnoreCase(user.firstName, search);
  }

  static filterShippingAddress(user, search){
    if(user.address.addressLineOne != null || user.address.addressLineOne != undefined ||
      user.address.addressLineTwo != null || user.address.addressLineTwo != undefined ||
      user.address.country != null || user.address.country != undefined ||
      user.firstName != null || user.firstName != undefined ||
      user.lastName != null || user.lastName != undefined
    ) {
      return StringUtils.compareStringIgnoreCase(user.address.addressLineOne, search)
        || StringUtils.compareStringIgnoreCase(user.address.addressLineTwo, search)
        || StringUtils.compareStringIgnoreCase(user.address.country, search)
        || StringUtils.compareStringIgnoreCase(user.firstName, search)
        || StringUtils.compareStringIgnoreCase(user.lastName, search);
    }
    return  StringUtils.compareStringIgnoreCase(user.address.addressLineOne, search);
  }

  static sortShippingAddress(user, search){
    if(user.address.addressLineTwo != null || user.address.addressLineTwo != undefined) {
      return StringUtils.compareStringIgnoreCase(user.address.addressLineOne, search)
        || StringUtils.compareStringIgnoreCase(user.address.addressLineTwo, search);
    }
    return  StringUtils.compareStringIgnoreCase(user.address.addressLineOne, search);
  }

  static getDocumentMapFromServer(documentMap: any, activeRouteID?: any){
    let docKeyOpenOrder = [];
    let docKeyClosedOrder = [];

    documentMap.forEach((value, key) => {
      if (activeRouteID !== undefined) {
        if (key === StringUtils.fromEmptyToMinus(activeRouteID.toString())) {
          this.getOrderHasRecordCount(value.documents, docKeyOpenOrder);
        }
      }else {
        if (StringUtils.openOrders.includes(key)) {
          this.getOrderHasRecordCount(value.documents, docKeyOpenOrder);
        } else {
          this.getOrderHasRecordCount(value.documents, docKeyClosedOrder);
        }
      }
    });

    OrderStatusUtil.documentMapOrder = docKeyOpenOrder;
    OrderStatusUtil.documentMapClosedOrder = docKeyClosedOrder;
  }

  static getOrderHasRecordCount(value: any, docKeyOrder: any[]){
    let docMapDataOrder = StringUtils.objToStrMap(value);
    docMapDataOrder.forEach((v, k) => {
      if (v.recordCount > 0) {
        docKeyOrder.push(k);
      }
    });
  }

  static clear(){
    if(this.order){
      this.order.clear();
    }
  }

  static isShowPhaseStep(key: string, isMulti?: boolean, storeId?: string) {
    let phaseStep: Map<string, boolean> = new Map();

    if(isMulti && storeId){
      phaseStep.set(StringUtils.orderProcessing, StoreUtils.isProcessingEnableFrom(storeId));
      phaseStep.set(StringUtils.orderProcessed, StoreUtils.isProcessedEnableFrom(storeId));
      phaseStep.set(StringUtils.orderPackaging, StoreUtils.isPackagingEnableFrom(storeId));
      phaseStep.set(StringUtils.orderPackaged, StoreUtils.isPackagedEnableFrom(storeId));
      phaseStep.set(StringUtils.orderDelivering, StoreUtils.isDeliveringEnableFrom(storeId));
      phaseStep.set(StringUtils.orderDelivered, StoreUtils.isDeliveredEnableFrom(storeId));
    }else {
      phaseStep.set(StringUtils.orderProcessing, StoreUtils.isProcessingEnable());
      phaseStep.set(StringUtils.orderProcessed, StoreUtils.isProcessedEnable());
      phaseStep.set(StringUtils.orderPackaging, StoreUtils.isPackagingEnable());
      phaseStep.set(StringUtils.orderPackaged, StoreUtils.isPackagedEnable());
      phaseStep.set(StringUtils.orderDelivering, StoreUtils.isDeliveringEnable());
      phaseStep.set(StringUtils.orderDelivered, StoreUtils.isDeliveredEnable());
    }

    return phaseStep.get(key);
  }

  static generateStatusIcon(key: string) {
    let statusIconMap: Map<string, string> = new Map();
      statusIconMap.set(StringUtils.orderReceived, "assets/images/order-status-pic/orderReceived.png");
      statusIconMap.set(StringUtils.orderProcessing, "assets/images/order-status-pic/processing.png");
      statusIconMap.set(StringUtils.orderProcessed, "assets/images/order-status-pic/processed.png");
      statusIconMap.set(StringUtils.orderPackaging, "assets/images/order-status-pic/packaging.png");
      statusIconMap.set(StringUtils.orderPackaged, "assets/images/order-status-pic/packaged.png");
      statusIconMap.set(StringUtils.orderDelivering, "assets/images/order-status-pic/delivering.png");
      statusIconMap.set(StringUtils.orderDelivered, "assets/images/order-status-pic/delivered.png");
      statusIconMap.set(StringUtils.orderCustomerReceived, "assets/images/order-status-pic/received.png");
      statusIconMap.set(StringUtils.orderCompleted, "assets/images/order-status-pic/Paid.png");
      statusIconMap.set(StringUtils.orderCancelled, "assets/images/order-status-pic/orderCancelled.png");

    return statusIconMap.get(key);
  }

  static listPhaseStatus() {
    let phaseStep: Map<string, boolean> = new Map();
    let phases = [];

    phaseStep.set(StringUtils.orderProcessing, StoreUtils.isProcessingEnable());
    phaseStep.set(StringUtils.orderProcessed, StoreUtils.isProcessedEnable());
    phaseStep.set(StringUtils.orderPackaging, StoreUtils.isPackagingEnable());
    phaseStep.set(StringUtils.orderPackaged, StoreUtils.isPackagedEnable());
    phaseStep.set(StringUtils.orderDelivering, StoreUtils.isDeliveringEnable());
    phaseStep.set(StringUtils.orderDelivered, StoreUtils.isDeliveredEnable());

    phases.push({value: StringUtils.orderReceived, title: StringUtils.orderReceived});
    phaseStep.forEach((v, k)=>{
      if(v){
        phases.push({value: k, title: k});
      }
    });

    return phases;
  }

  static listOpenOrderStatus(){
    return [
      { value: StringUtils.orderReceived, title: StringUtils.orderReceived},
      { value: StringUtils.orderProcessing, title: StringUtils.orderProcessing },
      { value: StringUtils.orderProcessed, title: StringUtils.orderProcessed },
      { value: StringUtils.orderPackaging, title: StringUtils.orderPackaging },
      { value: StringUtils.orderPackaged, title: StringUtils.orderPackaged },
      { value: StringUtils.orderDelivering, title: StringUtils.orderDelivering },
      { value: StringUtils.orderDelivered, title: StringUtils.orderDelivered },
    ];
  }

  static listCloseOrderStatus(){
    return [
      { value: StringUtils.orderCustomerReceived, title: StringUtils.orderCustomerReceived },
      { value: StringUtils.orderCompleted, title: StringUtils.orderCompleted },
      { value: StringUtils.orderCancelled, title: StringUtils.orderCancelled },
    ];
  }

  static listAllOrderStatus(){
    return [
      ...this.listOpenOrderStatus(),
      ...this.listCloseOrderStatus(),
    ];
  }
}

import {GetSafeValue} from '../utils/get-safe-value';
import {StringFieldUtil} from '../utils/string-field-util';
import { environment } from '../../environments/environment';

/**
 * Created by cheaphakdey on 17/1/19.
 */

export class CDN{
  private _domain: string;
  private _interval: number;
  private _lastUpdate: number;
  private _paths: string[];

  constructor(){
    this.domain = '';
    this.interval = 0;
    this.lastUpdate = 0;
    this.paths = [];
  }

  get domain(): string {
    return this._domain;
  }

  set domain(value: string) {
    this._domain = value;
  }

  get interval(): number {
    return this._interval;
  }

  set interval(value: number) {
    this._interval = value;
  }

  get lastUpdate(): number {
    return this._lastUpdate;
  }

  set lastUpdate(value: number) {
    this._lastUpdate = value;
  }

  get paths(): string[] {
    return this._paths;
  }

  set paths(value: string[]) {
    this._paths = value;
  }

  fromJson(data: CDN) {
    this.domain = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.domain], environment.firebase.authDomain);
    this.interval = GetSafeValue.getSafeValueAsNumber(data[StringFieldUtil.interval], 15);
    this.lastUpdate = GetSafeValue.getSafeValueAsNumber(data[StringFieldUtil.lastUpdate], 0);
    this.paths = GetSafeValue.getSafeValueAsList(data[StringFieldUtil.paths], ["/cdn/items/home", "/cdn/items?*"], data[StringFieldUtil.paths].length == 0 ? ["/cdn/items/home", "/cdn/items?*"] : []);
  }

  toMap(){
    return {
      'domain': this.domain,
      'interval': this.interval,
      'lastUpdate': this.lastUpdate,
      'paths': this.paths
    };
  }

  clone(cdn: CDN) {
    this.domain = cdn.domain;
    this.interval = cdn.interval;
    this.lastUpdate = cdn.lastUpdate;
    this.paths = cdn.paths;
  }
}

export class PlatformFeature {
  iconUrl: string;
  title: Map<string, string>;
  content: Map<string, string>;

  constructor() {
    this.iconUrl = "";
    this.title =  new Map();
    this.content = new Map();
  }
}

import {GetSafeValue} from "../utils/get-safe-value";
import {ControlFormNameUtil} from "../utils/string-control-form-name";

export class Payment {

  private _khqrMerchantId : string;
  private _isAcledaEnable:boolean;
  private _isABAEnable: boolean;
  private _isPiPayEnable: boolean;
  private _isMasterCardEnable: boolean;
  private _isWingEnable: boolean;
  private _isAmkQrEnable: boolean;
  private _isAmkPayEnable: boolean;

  constructor(){
    this.khqrMerchantId = "";
    this.isAcledaEnable = true;
    this.isABAEnable = true;
    this.isPiPayEnable = true;
    this.isMasterCardEnable = true;
    this.isWingEnable = true;
    this.isAmkPayEnable = true;
    this.isAmkQrEnable = true;
  }

  clone(data: Payment) {
    this.khqrMerchantId = data.khqrMerchantId;
    this.isAcledaEnable = data.isAcledaEnable;
    this.isABAEnable = data.isABAEnable;
    this.isPiPayEnable = data.isPiPayEnable;
    this.isMasterCardEnable = data.isMasterCardEnable;
    this.isWingEnable = data.isWingEnable;
    this.isAmkQrEnable = data.isAmkQrEnable;
    this.isAmkPayEnable = data.isAmkPayEnable;

  }

  fromJson(data: Payment){
    this.khqrMerchantId = GetSafeValue.getSafeValueAsString(data[ControlFormNameUtil.khqrMerchantId], null);
    this.isAcledaEnable = GetSafeValue.getSafeValueAsBoolean(data[ControlFormNameUtil.cfnIsAcledaEnable], true);
    this.isABAEnable = GetSafeValue.getSafeValueAsBoolean(data[ControlFormNameUtil.cfnIsABAEnable], true);
    this.isPiPayEnable = GetSafeValue.getSafeValueAsBoolean(data[ControlFormNameUtil.cfnIsPiPayEnable], true);
    this.isMasterCardEnable = GetSafeValue.getSafeValueAsBoolean(data[ControlFormNameUtil.cfnIsMasterCardEnable], true);
    this.isWingEnable = GetSafeValue.getSafeValueAsBoolean(data[ControlFormNameUtil.isWingEnable], true);
    this.isAmkQrEnable = GetSafeValue.getSafeValueAsBoolean(data[ControlFormNameUtil.isAmkQrEnable], true);
    this.isAmkPayEnable = GetSafeValue.getSafeValueAsBoolean(data[ControlFormNameUtil.isAmkPayEnable], true);

  }

  toMap(){
    return {
      'khqrMerchantId' : this.khqrMerchantId ? this.khqrMerchantId : null,
      'isAcledaEnable': this.isAcledaEnable,
      'isABAEnable': this.isABAEnable,
      'isPiPayEnable': this.isPiPayEnable,
      'isMasterCardEnable': this.isMasterCardEnable,
      'isWingEnable': this.isWingEnable,
      'isAmkQrEnable': this.isAmkQrEnable,
      'isAmkPayEnable': this.isAmkPayEnable,
    };
  }


  get khqrMerchantId(): string {
    return this._khqrMerchantId;
  }

  set khqrMerchantId(value: string) {
    this._khqrMerchantId = value;
  }

  get isAcledaEnable(): boolean {
    return this._isAcledaEnable;
  }

  set isAcledaEnable(value: boolean) {
    this._isAcledaEnable = value;
  }

  get isABAEnable(): boolean {
    return this._isABAEnable;
  }

  set isABAEnable(value: boolean) {
    this._isABAEnable = value;
  }

  get isPiPayEnable(): boolean {
    return this._isPiPayEnable;
  }

  set isPiPayEnable(value: boolean) {
    this._isPiPayEnable = value;
  }

  get isMasterCardEnable(): boolean {
    return this._isMasterCardEnable;
  }

  set isMasterCardEnable(value: boolean) {
    this._isMasterCardEnable = value;
  }

  get isWingEnable(): boolean {
    return this._isWingEnable;
  }

  set isWingEnable(value: boolean) {
    this._isWingEnable = value;
  }

  get isAmkQrEnable(): boolean {
    return this._isAmkQrEnable;
  }

  set isAmkQrEnable(value: boolean) {
    this._isAmkQrEnable = value;
  }

  get isAmkPayEnable(): boolean {
    return this._isAmkPayEnable;
  }

  set isAmkPayEnable(value: boolean) {
    this._isAmkPayEnable = value;
  }

}

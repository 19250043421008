import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {SharedPreference} from '../../shared-preference/shared-perference';
import {User} from '../../model/user';
import {AuthenticationService} from "../../services/authentication.service";
import {environment} from "../../../environments/environment";
import {Role} from "../../model/role";
import {Router} from '@angular/router';
import {LanguageServiceService} from '../../services';
import {DomainNameParserService} from '../../services/domain-name-parser.service';
import {DOCUMENT} from '@angular/platform-browser';
import {Location} from '@angular/common';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit, OnInit {
  showHide: boolean;
  routerLink: string;
  flagSelected: string;
  ukFlag: string = "assets/home-image/uk-flag.png";
  cambodiaFlag: string = "assets/home-image/cambodia-flag.png";
  isLoginEnable: boolean;

  constructor(
    private _location: Location,
    private _authenticationService: AuthenticationService,
    public languageService: LanguageServiceService,
    public router: Router,
    private domainParserService: DomainNameParserService,
    @Inject(DOCUMENT) private document: Document,
    ){}

  ngOnInit(): void {
    this.showHide = true;
    this.onChangeLanguage('en');
    this.flagSelected = "assets/home-image/uk-flag.png";

    // check domain
    const domainName = this.domainParserService.getDomainName(this.document.location.hostname);
    this.isLoginEnable = (domainName !== "khmumtech.com" && domainName !== "versitasoftware.com");
  }

  getUser() : User{
    return SharedPreference.getUser();
  }

  getStoreName(): string {
    if (Role.hasVersitaUser(SharedPreference.getUser())) {
      return SharedPreference.getUserFullName();
    } else {
      return SharedPreference.getStoreName(SharedPreference.getStoreId());
    }

  }

  logout(): void{
    if(environment.isEnableHomePage){
      this.routerLink = '/home-page';
    }else{
      this.routerLink = '/login';
    }
    this._authenticationService.onLogout();
  }

  getIsShowingMenu() :boolean {
    return SharedPreference.isShowingMenu;
  }

  isEnableHomePage(): boolean {
    return environment.isEnableHomePage && !window.location.href.includes('login');
  }

  isLogin(): boolean {
    return SharedPreference.getLogin();
  }

  // This is for Notifications
  notifications: Object[] = [
    {
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just valuePrice reminder that you have event',
      time: '9:10 AM'
    },
    {
      round: 'round-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung valuePrice song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am valuePrice singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  changeShowStatus() {
    this.showHide = !this.showHide;
  }

  ngAfterViewInit() {
    $(function() {
      $('.preloader').fadeOut();
    });

    const set = function() {
      const width =
        window.innerWidth > 0 ? window.innerWidth : this.screen.width;
      const topOffset = 70;
      if (width < 1170) {
        $('body').addClass('mini-sidebar');
        $('.navbar-brand span').hide();
        $('.sidebartoggler i').addClass('ti-menu');
      } else {
        $('body').removeClass('mini-sidebar');
        $('.navbar-brand span').show();
        $('.sidebartoggler i').removeClass('ti-menu');
      }

      const height =
        (window.innerHeight > 0 ? window.innerHeight : this.screen.height) - 1;

      if (height > topOffset) {
        $('.page-wrapper').css('min-height', height + 'px');
      }
    };
    $(window).ready(set);
    $(window).on('resize', set);

    $(document).on('click', '.mega-dropdown', function(e) {
      e.stopPropagation();
    });

    $('.search-box valuePrice, .search-box .app-search .srh-btn').on(
      'click',
      function() {
        $('.app-search').toggle(200);
      }
    );

    (<any>$('[data-toggle="tooltip"]')).tooltip();

    (<any>$('.right-sidebar')).slimScroll({
      height: '100%',
      position: 'right',
      size: '5px',
      color: '#dcdcdc'
    });

    (<any>$('.message-center')).slimScroll({
      position: 'right',
      size: '5px',
      color: '#dcdcdc'
    });

    $('body').trigger('resize');
  }

  isOnHomePage() {
    return this.router.url === "/home-page";
  }

  isUserLogin() {
    return SharedPreference.getLogin();
  }

  onChangeLanguage(language: string) {
    if (language === 'kh') {
      this.flagSelected = "assets/home-image/cambodia-flag.png"
    } else {
      this.flagSelected = "assets/home-image/uk-flag.png";
    }
    this.languageService.onChangeLanguage(language);
  }

  isOnJoinAsPartner() {
    return this.router.url.includes("/join-as-partner");
  }

  isOnPartnerRegister() {
    return this.router.url.includes("/partner-register")
  }

  back(): void {
    if (this.isOnJoinAsPartner() || this.isOnPartnerRegister()) {
      this._location.back();
    }

  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {SharedPreference} from '../shared-preference/shared-perference';
import {Role} from '../model/role';

@Injectable({
  providedIn: 'root'
})
export class AboutContactUsGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const  user = SharedPreference.getUser();
    return Role.hasVersitaAdmin(user);
  }
}

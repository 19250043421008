import {User} from './user';
import {RoleUtils} from '../utils/role-utils';
import {StringUtils} from "../utils/string-utils";
import {StoreUtils} from "../utils/store-utils";
import {SharedPreference} from "../shared-preference/shared-perference";

/**
 * Created by vmc on 18/1/19.
 */

export class Role {
  public static versitaSystem = 'Versita System';
  private static admin = 'Admin';
  private static customerService = 'Customer Service';
  private static customer = 'Customer';
  private static supervisor = 'Supervisor';
  private static readOnly = "Read Only";
  private static package = "Package";
  private static delivery = "Delivery";
  private static versitaSale = "Versita Sale";
  private static versitaAdminStores = "Versita Store";
  private static versitaCustomerService = 'Versita Customer Service';
  private static versitaSupervisor = 'Versita Supervisor';
  private static internalUser = "Internal User";

  //vmc:how-to-use-roles

  public static hasVersitaCustomerService(user: User): boolean {
    if (user && user.roles){
      return RoleUtils.hasRole(user.roles, Role.versitaCustomerService)
    } else {
      return false;
    }
  }

  public static hasVersitaSupervisor(user: User): boolean {
    if (user && user.roles){
      return RoleUtils.hasRole(user.roles, Role.versitaSupervisor)
    } else {
      return false;
    }
  }

  public static hasVersitaSystem(user: User): boolean {
    if (user && user.roles){
      return RoleUtils.hasRole(user.roles, Role.versitaSystem)
    } else {
      return false;
    }
  }

  public static hasAdminRole(user: User): boolean {
    if (user && user.roles){
      return RoleUtils.hasRole(user.roles, Role.admin)
    } else {
      return false;
    }
  }

  public static hasVersitaStoreRole(user: User): boolean {
    if (user && user.roles){
      return RoleUtils.hasRole(user.roles, Role.versitaAdminStores)
    } else {
      return false;
    }
  }

  public static hasPackageRole(user: User){
    if (user && user.roles){
      return RoleUtils.hasRole(user.roles, Role.package) || RoleUtils.hasRole(user.roles, Role.versitaCustomerService)
    } else {
      return false;
    }
  }

  public static hasDeliveryRole(user: User){
    if (user && user.roles){
      return RoleUtils.hasRole(user.roles, Role.delivery) || RoleUtils.hasRole(user.roles, Role.versitaCustomerService)
    } else {
      return false;
    }
  }

  public static hasCustomerServiceRole(user: User): boolean {
    if (user && user.roles){
      return RoleUtils.hasRole(user.roles, Role.customerService) || RoleUtils.hasRole(user.roles, Role.versitaCustomerService);
    } else {
      return false;
    }
  }

  public static hasCustomer(user: User): boolean {
    if (user && user.roles){
      return RoleUtils.hasRole(user.roles, Role.customer) && !RoleUtils.hasRole(user.roles, Role.internalUser);
    } else {
      return false;
    }
  }

  public static hasSupervisorRole(user: User): boolean {
    if (user && user.roles){
      return RoleUtils.hasRole(user.roles, Role.supervisor) || RoleUtils.hasRole(user.roles, Role.versitaSupervisor)
    } else {
      return false;
    }
  }

  public static hasVersitaSale(user: User): boolean {
    if (user && user.roles){
      return RoleUtils.hasRole(user.roles, Role.versitaSale)
    } else {
      return false;
    }
  }

  public static hasVersitaAdmin(user: User): boolean {
    if (user && user.roles){
      return RoleUtils.hasRole(user.roles, StringUtils.versitaAdmin)
    } else {
      return false;
    }
  }

  public static hasVersitaUser(user: User): boolean {
    if (user && user.roles){
      return RoleUtils.hasRole(user.roles, StringUtils.versitaUser)
    } else {
      return false;
    }
  }

  public  static  hasInternalUser(user: User): boolean {
    if (user && user.roles){
      return RoleUtils.hasRole(user.roles, StringUtils.internalUser)
    } else {
      return false;
    }
  }

  public static hasReadOnlyRole(user: User): boolean {
    if (user && user.roles) {
      return RoleUtils.hasRole(user.roles, Role.readOnly);
    } else {
      return false;
    }
  }

}

import {GetSafeValue} from '../utils/get-safe-value';
import {StringFieldUtil} from '../utils/string-field-util';
import {ControlFormNameUtil} from '../utils/string-control-form-name';
import {StringUtils} from '../utils/string-utils';
import {isObject} from 'rxjs/internal-compatibility';

export class UserStore{
  private _uid: string;
  private _firstName: string;
  private _lastName: string;
  private _email: string;
  private _phone: Map<string, string>;

  constructor(){
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.uid = "";
    this.phone = new Map();
    this.phone.set("code", "855");
  }

  get firstName(): string {
    return this._firstName;
  }

  get uid(): string {
    return this._uid;
  }

  set uid(value: string) {
    this._uid = value;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get phone(): Map<string, string> {
    return this._phone;
  }

  set phone(value: Map<string, string>) {
    this._phone = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  fromJson(data: UserStore) {
    this.lastName = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.lastName], null);
    this.firstName = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.firstName], null);
    this.email = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.email], null);
    this.uid = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.uid], null);
    let phone = GetSafeValue.getSafeValueAsMap(data[StringUtils.phoneNumber], {'phone': "", 'code': ""});

    if(isObject(phone)){
      this.phone = StringUtils.objToStrMap(phone);
    } else if (phone) {
      this.phone = phone;
    }else {
      this.phone = new Map();
    }
  }

  toMap(){
    return {
      'uid': this.uid,
      'firstName': this.firstName,
      'lastName': this.lastName,
      'email': this.email,
      'phoneNumber': this.phone.get('phone') === undefined ? {} : {
        'phone': this.phone.get('phone'),
        'code': this.phone.get('code'),
      },
    };
  }

  clone(userStore: UserStore) {
    this.firstName = userStore.firstName;
    this.lastName = userStore.lastName;
    this.email = userStore.email;
    this.phone = userStore.phone;
    this.uid = userStore.uid;
  }

}

export class BusinessSetting {
  private _isDashboardEnable: boolean;
  private _isExportEnable : boolean;
  private _maxItemCount: number;
  private _maxInternalUser: number;
  private _maxItemSpecial: number;
  private _isProcessingEnable: boolean;
  private _isProcessedEnable: boolean;
  private _isPackagingEnable: boolean;
  private _isPackagedEnable: boolean;
  private _isDeliveringEnable: boolean;
  private _isDeliveredEnable: boolean;
  private _isCouponEnable: boolean;
  private _isCreditEnable: boolean;
  private _type: string[];
  private _categoriesType: string[];
  private _isQrCodeEnable: boolean;
  private _isInStoreEnable: boolean;
  private _isShippingDiscountEnable: boolean;
  private _isCustomerDetailEnable: boolean;
  private _isGoLive: boolean;
  private _isSystemDeliveryRate: boolean;
  private _isExportReportEnable: boolean;
  private _isQrCodeUniversalProductEnable: boolean;
  private _isGoogleImageEnable: boolean;
  private _isUploadVideoEnable: boolean;
  private _isPos: boolean;
  private _isItemLabelExportEnable: boolean;
  private _isOrderEditable: boolean;
  private _isUsePrinter: boolean;
  private _isHomeConfig: boolean;
  private _isBackgroundRemovalEnable: boolean;
  private _isPrinterAddressEnable: boolean;
  private _consignment : Consignment;
  private _subscriptionPlan: string;
  private _group: string[];

  constructor() {
    this.isDashboardEnable = undefined;
    this.isExportEnable = undefined;
    this.maxInternalUser = 0;
    this.isPrinterAddressEnable = true;
    this.type = [];
    this.group = [];
    this.subscriptionPlan = null;
    this.isUsePrinter = null;
    this.categoriesType = [];
    this.consignment = new Consignment();
  }


  get isHomeConfig(): boolean {
    return this._isHomeConfig;
  }

  set isHomeConfig(value: boolean) {
    this._isHomeConfig = value;
  }

  get group(): string[] {
    return this._group;
  }

  set group(value: string[]) {
    this._group = value;
  }

  get isUsePrinter(): boolean {
    return this._isUsePrinter;
  }

  set isUsePrinter(value: boolean) {
    this._isUsePrinter = value;
  }

  get categoriesType(): string[] {
    return this._categoriesType;
  }

  set categoriesType(value: string[]) {
    this._categoriesType = value;
  }

  get consignment(): Consignment {
    return this._consignment;
  }

  set consignment(value: Consignment) {
    this._consignment = value;
  }

  get isPrinterAddressEnable(): boolean {
    return this._isPrinterAddressEnable;
  }

  set isPrinterAddressEnable(value: boolean) {
    this._isPrinterAddressEnable = value;
  }

  get isBackgroundRemovalEnable(): boolean {
    return this._isBackgroundRemovalEnable;
  }

  set isBackgroundRemovalEnable(value: boolean) {
    this._isBackgroundRemovalEnable = value;
  }

  get isOrderEditable(): boolean {
    return this._isOrderEditable;
  }

  set isOrderEditable(value: boolean) {
    this._isOrderEditable = value;
  }

  get isItemLabelExportEnable(): boolean {
    return this._isItemLabelExportEnable;
  }

  set isItemLabelExportEnable(value: boolean) {
    this._isItemLabelExportEnable = value;
  }

  get isSystemDeliveryRate(): boolean {
    return this._isSystemDeliveryRate;
  }

  set isSystemDeliveryRate(value: boolean) {
    this._isSystemDeliveryRate = value;
  }

  get isGoLive(): boolean {
    return this._isGoLive;
  }

  set isGoLive(value: boolean) {
    this._isGoLive = value;
  }

  get isShippingDiscountEnable(): boolean {
    return this._isShippingDiscountEnable;
  }

  set isShippingDiscountEnable(value: boolean) {
    this._isShippingDiscountEnable = value;
  }

  get type(): string[] {
    return this._type;
  }

  set type(value: string[]) {
    this._type = value;
  }

  get isQrCodeEnable(): boolean {
    return this._isQrCodeEnable;
  }

  set isQrCodeEnable(value: boolean) {
    this._isQrCodeEnable = value;
  }

  get isCouponEnable(): boolean {
    return this._isCouponEnable;
  }

  set isCouponEnable(value: boolean) {
    this._isCouponEnable = value;
  }

  get isCreditEnable(): boolean {
    return this._isCreditEnable;
  }

  set isCreditEnable(value: boolean) {
    this._isCreditEnable = value;
  }

  get maxItemSpecial(): number {
    return this._maxItemSpecial;
  }

  set maxItemSpecial(value: number) {
    this._maxItemSpecial = value;
  }

  get isDashboardEnable(): boolean {
    return this._isDashboardEnable;
  }

  set isDashboardEnable(value: boolean) {
    this._isDashboardEnable = value;
  }

  get isExportEnable(): boolean {
    return this._isExportEnable;
  }

  set isExportEnable(value: boolean) {
    this._isExportEnable = value;
  }

  get maxItemCount(): number {
    return this._maxItemCount;
  }

  set maxItemCount(value: number) {
    this._maxItemCount = value;
  }

  get maxInternalUser(): number {
    return this._maxInternalUser;
  }

  set maxInternalUser(value: number) {
    this._maxInternalUser = value;
  }

  get isProcessingEnable(): boolean {
    return this._isProcessingEnable;
  }

  set isProcessingEnable(value: boolean) {
    this._isProcessingEnable = value;
  }

  get isProcessedEnable(): boolean {
    return this._isProcessedEnable;
  }

  set isProcessedEnable(value: boolean) {
    this._isProcessedEnable = value;
  }

  get isPackagingEnable(): boolean {
    return this._isPackagingEnable;
  }

  set isPackagingEnable(value: boolean) {
    this._isPackagingEnable = value;
  }

  get isPackagedEnable(): boolean {
    return this._isPackagedEnable;
  }

  set isPackagedEnable(value: boolean) {
    this._isPackagedEnable = value;
  }

  get isDeliveringEnable(): boolean {
    return this._isDeliveringEnable;
  }

  set isDeliveringEnable(value: boolean) {
    this._isDeliveringEnable = value;
  }

  get isDeliveredEnable(): boolean {
    return this._isDeliveredEnable;
  }

  set isDeliveredEnable(value: boolean) {
    this._isDeliveredEnable = value;
  }


  get isInStoreEnable(): boolean {
    return this._isInStoreEnable;
  }

  set isInStoreEnable(value: boolean) {
    this._isInStoreEnable = value;
  }

  get isCustomerDetailEnable(): boolean {
    return this._isCustomerDetailEnable;
  }

  set isCustomerDetailEnable(value: boolean) {
    this._isCustomerDetailEnable = value;
  }


  get isExportReportEnable(): boolean {
    return this._isExportReportEnable;
  }

  set isExportReportEnable(value: boolean) {
    this._isExportReportEnable = value;
  }


  get isQrCodeUniversalProductEnable(): boolean {
    return this._isQrCodeUniversalProductEnable;
  }

  set isQrCodeUniversalProductEnable(value: boolean) {
    this._isQrCodeUniversalProductEnable = value;
  }

  get isGoogleImageEnable(): boolean {
    return this._isGoogleImageEnable;
  }

  set isGoogleImageEnable(value: boolean) {
    this._isGoogleImageEnable = value;
  }


  get isUploadVideoEnable(): boolean {
    return this._isUploadVideoEnable;
  }

  set isUploadVideoEnable(value: boolean) {
    this._isUploadVideoEnable = value;
  }

  get isPos(): boolean {
    return this._isPos;
  }

  set isPos(value: boolean) {
    this._isPos = value;
  }

  get subscriptionPlan(): string {
    return this._subscriptionPlan;
  }

  set subscriptionPlan(value: string) {
    this._subscriptionPlan = value;
  }

  clone(data: BusinessSetting){
    this.isDashboardEnable = data.isDashboardEnable;
    this.isExportEnable = data.isExportEnable;
    this.maxItemCount = data.maxItemCount;
    this.maxInternalUser = data.maxInternalUser;
    this.maxItemSpecial = data.maxItemSpecial;
    this.isProcessingEnable = data.isProcessingEnable;
    this.isProcessedEnable = data.isProcessedEnable;
    this.isPackagingEnable = data.isPackagingEnable;
    this.isPackagedEnable = data.isPackagedEnable;
    this.isDeliveringEnable = data.isDeliveringEnable;
    this.isDeliveredEnable = data.isDeliveredEnable;
    this.isCreditEnable = data.isCreditEnable;
    this.isCouponEnable = data.isCouponEnable;
    this.isQrCodeEnable = data.isQrCodeEnable;
    this.isInStoreEnable = data.isInStoreEnable;
    this.type = data.type;
    this.categoriesType = data.categoriesType;
    this.isShippingDiscountEnable = data.isShippingDiscountEnable;
    this.isCustomerDetailEnable = data.isCustomerDetailEnable;
    this.isGoLive = data.isGoLive;
    this.isSystemDeliveryRate = data.isSystemDeliveryRate;
    this.isExportReportEnable = data.isExportReportEnable;
    this.isQrCodeUniversalProductEnable = data.isQrCodeUniversalProductEnable;
    this.isGoogleImageEnable = data.isGoogleImageEnable;
    this.isUploadVideoEnable = data.isUploadVideoEnable;
    this.isItemLabelExportEnable = data.isItemLabelExportEnable;
    this.isPos = data.isPos;
    this.isOrderEditable = data.isOrderEditable;
    this.isBackgroundRemovalEnable = data.isBackgroundRemovalEnable;
    this.isPrinterAddressEnable = data.isPrinterAddressEnable;
    this.consignment = data.consignment;
    this.subscriptionPlan = data.subscriptionPlan;
    this.isUsePrinter = data.isUsePrinter;
    this.isHomeConfig = data.isHomeConfig;
    this.group = data.group;
  }

  fromJson(data: BusinessSetting){
    this.isDashboardEnable = GetSafeValue.getSafeValueAsBoolean(data[ControlFormNameUtil.cfnIsDashboardEnable], false);
    this.isExportEnable = GetSafeValue.getSafeValueAsBoolean(data[ControlFormNameUtil.cfnIsExportEnable], false);
    this.maxItemCount = GetSafeValue.getSafeValueAsNumber(data[ControlFormNameUtil.cfnMaxItemCount], null);
    this.maxInternalUser = GetSafeValue.getSafeValueAsNumber(data[ControlFormNameUtil.cfnMaxInternalUser], null);
    this.maxItemSpecial = GetSafeValue.getSafeValueAsNumber(data[ControlFormNameUtil.cfnMaxItemSpecial], null);
    this.isProcessingEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isProcessingEnable], false);
    this.isProcessedEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isProcessedEnable], false);
    this.isPackagingEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isPackagingEnable], false);
    this.isPackagedEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isPackagedEnable], false);
    this.isDeliveringEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isDeliveringEnable], false);
    this.isDeliveredEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isDeliveredEnable], false);
    this.isCouponEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isCouponEnable], false);
    this.isCreditEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isCreditEnable], false);
    this.type = GetSafeValue.getSafeValueAsList(data[StringFieldUtil.type], [], []);
    this.categoriesType = GetSafeValue.getSafeValueAsList(data[StringFieldUtil.categoryType], [], []);
    this.isQrCodeEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isQrCodeEnable], false);
    this.isInStoreEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isInStoreEnable], false);
    this.isShippingDiscountEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isShippingDiscountEnable], false);
    this.isCustomerDetailEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isCustomerDetailEnable], false); // no this field in database it's value = true.
    this.isGoLive = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isGoLive], false); // no this field in database it's value = true.
    this.isSystemDeliveryRate = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isSystemDeliveryRate], false); // no this field in database it's value = true.
    this.isExportReportEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isExportReportEnable], false);
    this.isQrCodeUniversalProductEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isQrCodeUniversalProductEnable], false);
    this.isGoogleImageEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isGoogleImageEnable], false);
    this.isUploadVideoEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isUploadVideoEnable], false);
    this.isItemLabelExportEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isItemLabelExportEnable], false);
    this.isPos = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isPos], false);
    this.isOrderEditable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isOrderEditable], false);
    this.isBackgroundRemovalEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isBackgroundRemovalEnable], false);
    this.isPrinterAddressEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isPrinterAddressEnable], true);
    this.subscriptionPlan = GetSafeValue.getSafeValueAsString(data['subscriptionPlan'], null);
    this.isUsePrinter = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isUsePrinter], null);
    this.isHomeConfig = GetSafeValue.getSafeValueAsBoolean(data["isHomeConfig"], null);
    this.group = GetSafeValue.getSafeValueAsList(data[StringFieldUtil.group], [], []);

    if(data["consignment"]){
      this.consignment = new Consignment();
      this.consignment.fromJson(data["consignment"]);
    }
  }

  toMap(){
    let setType = new Set(this.type);
    let arrayType = Array.from(setType);

    let setCategoriesType = new Set(this.categoriesType);
    let arrayCategoryType = Array.from(setCategoriesType);

    let setGroup = new Set(this.group);
    let arrayGroup = Array.from(setGroup);
    return {
      'isDashboardEnable': this.isDashboardEnable,
      'isExportEnable': this.isExportEnable,
      'maxItemCount' : this.maxItemCount,
      'maxInternalUser' : this.maxInternalUser,
      'maxItemSpecial': this.maxItemSpecial,
      'isProcessingEnable': this.isProcessingEnable,
      'isProcessedEnable': this.isProcessedEnable,
      'isPackagingEnable': this.isPackagingEnable,
      'isPackagedEnable': this.isPackagedEnable,
      'isDeliveringEnable': this.isDeliveringEnable,
      'isDeliveredEnable': this.isDeliveredEnable,
      'isCouponEnable': this.isCouponEnable,
      'isCreditEnable': this.isCreditEnable,
      'type': arrayType,
      'categoryType': arrayCategoryType,
      'isQrCodeEnable': this.isQrCodeEnable,
      'isInStoreEnable': this.isInStoreEnable,
      'isShippingDiscountEnable': this.isShippingDiscountEnable,
      'isCustomerDetailEnable': this.isCustomerDetailEnable,
      'isGoLive': this.isGoLive,
      'isSystemDeliveryRate': this.isSystemDeliveryRate,
      'isExportReportEnable': this.isExportReportEnable,
      'isQrCodeUniversalProductEnable': this.isQrCodeUniversalProductEnable,
      'isGoogleImageEnable': this.isGoogleImageEnable,
      'isUploadVideoEnable': this.isUploadVideoEnable,
      'isItemLabelExportEnable': this.isItemLabelExportEnable,
      'isPos': this.isPos,
      "isOrderEditable": this.isOrderEditable,
      "isBackgroundRemovalEnable": this.isBackgroundRemovalEnable,
      "isPrinterAddressEnable": this.isPrinterAddressEnable,
      "consignment": this.consignment.toMap(),
      "subscriptionPlan": this.subscriptionPlan,
      "isUsePrinter": this.isUsePrinter,
      "isHomeConfig": this.isHomeConfig,
      "group": arrayGroup
    };
  }
}


export class Consignment {

  private _delivery : boolean;

  constructor(){};


  fromJson(data : Consignment){
    this.delivery =  GetSafeValue.getSafeValueAsBoolean(data['delivery'], false);
  }


  get delivery(): boolean {
    return this._delivery;
  }

  set delivery(value: boolean) {
    this._delivery = value;
  }

  toMap(){
    return {
      "delivery" : this.delivery
    }
  }
}

import {StoreSetting} from "../model/store-setting";

export class StoreSettingUtil {
  static storeSetting: StoreSetting;

  static getEmailSetting() {
    try {
      return this.storeSetting.emailSetting;
    } catch (e) {
      return null;
    }
  }

  static clear(){
    this.storeSetting = undefined;
  }
}

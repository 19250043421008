import {User} from '../model/user';
import {Role} from "../model/role";
import {AesHelper} from './aes-helper';
import {StoreUtils} from "../utils/store-utils";

export class SharedPreference {
  private static user = "user";
  private static isUserLoginLabel = "isShowingMenu";
  private static storeId: string;
  private static data: Map<string, Object> = new Map();

  static set(key: string, data: any): void {
    try {
      const _key = AesHelper.encrypt(key);
      const _data = AesHelper.encrypt(JSON.stringify(data));
      SharedPreference.data.delete(key);
      localStorage.setItem(_key, _data);
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  static get(key: string) {
    try {
      if (!SharedPreference.data.get(key)) {
        const _key = AesHelper.encrypt(key);
        const _data = AesHelper.decrypt(localStorage.getItem(_key));
        SharedPreference.data.set(key, JSON.parse(_data));
        return JSON.parse(_data);
      } else {
        return SharedPreference.data.get((key));
      }
    } catch (e) {
      return null;
    }
  }

  static removeItem(key: string) {
    const _key = AesHelper.encrypt(key);
    localStorage.removeItem(_key);
  }

  public static getUser(): User {
    return SharedPreference.get(this.user);
  }

  static removeAllItem() {
    this.data = new Map<string, Object>();
    localStorage.clear();
  }

  public static getRoles(): string[] {
    try {
      return this.getUser().roles
    }catch (e) {
      return null;
    }
  }

  public static getStoreId(){
    if(SharedPreference && SharedPreference.getUser() && SharedPreference.getUser().currentStoreId){
      return SharedPreference.getUser().currentStoreId;
    }else if(Role.hasVersitaAdmin(SharedPreference.getUser())){
      return this.storeId
    }

    return StoreUtils.getSelectStoreId();
  }

  public static getUserFullName(){
      return SharedPreference.getUser().firstName + " " + SharedPreference.getUser().lastName;
  }

  public static setStoreId(storeId: string){
    this.storeId = storeId;
  }

  public static setUser(user: User) {
    let us: User = new User();
    us.clone(user);
    SharedPreference.set(this.user, us.toMapForSharePref());
  }

  public static clearUser() {
    SharedPreference.removeItem(this.user);
    SharedPreference.setIsUserLogin(false);
  }

  static isShowingMenu: boolean;

  static setLogin(value: boolean) {
    SharedPreference.set(SharedPreference.isUserLoginLabel, value);
  }

  static getLogin(): boolean {
    return SharedPreference.get(SharedPreference.isUserLoginLabel);
  }

  static setIsUserLogin(value: boolean) {
    SharedPreference.setLogin(value);
    SharedPreference.isShowingMenu = value;
  }

  static setIsShowingMenu(value: boolean) {
    SharedPreference.isShowingMenu = value;
  }

  static setStoreName(storeName: string, key: string) {
    SharedPreference.set(key, storeName);
  }

  static getStoreName(key: string) {
    return SharedPreference.get(key);
  }

  static clearStoreName(key: string) {
    SharedPreference.removeItem(key);
  }
}

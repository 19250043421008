import {TopIntro} from '../top-intro';
import {PlatformFeature} from '../platform-feature';
import {TitleAndContent} from '../about-us';
import {OurTeam} from '../our-team';
import {Partner} from '../partner';

export class HomePageMockUp {

  static topIntroMockUp() :TopIntro{
    let topIntro: TopIntro = new TopIntro();
    topIntro.intro.set('en', 'Digitalizes your business , Grow with us as your partner');
    topIntro.intro.set('kh', 'ធ្វើអាជីវកម្មបែបឌីជីថល, រីកលូតលាស់ជាមួយយើងជាដៃគូ');
    topIntro.ourPlatform.set('en', 'Cambodia Online Shopping App Shopping in your hand, fast and easy from the comfort of your home and get products delivered without getting stuck in the traffic.');
    topIntro.ourPlatform.set('kh', 'ការទំនិញតាមរយះ Online ស្ថិតនៅក្នុងដៃអ្នក រហ័សនិងមានភាពងាយស្រួល អ្នកនៅផ្ទះក៏អាចទទួលទំនិញបានដោយមិនបាច់ហត់ដើរផ្សារនិងស្ទះចរាចរ។');
    topIntro.androidUrl = "https://play.google.com/store/apps/details?id=com.versita.store.vr";
    topIntro.iosUrl = "https://apps.apple.com/us/app/khmum/id1471320793";
    topIntro.platformUiImageUrl = "assets/home-image/ui-1.png";
    topIntro.khmumLogoUrl = "assets/home-image/khmum-logo.png";
    topIntro.videoIntro = "assets/home-image/video/intro.mp4";
    return topIntro;
  }

  static aboutUs() :Map<string, TitleAndContent> {
    let aboutUs: Map<string, TitleAndContent> = new Map();
    let whoWeAre: TitleAndContent = new TitleAndContent();
    whoWeAre.title.set("en", "Who we are?");
    whoWeAre.title.set("kh", "តើយើង​ជា​នរណា?");
    whoWeAre.content.set('en', 'Versita is a software company that specializes in eCommerce. Our company is based in Cambodia to provide the country with innovative alternative to connect sellers and consumers.');
    whoWeAre.content.set('kh', 'Versita គឺជាក្រុមហ៊ុនដែលមានជំនាញផ្នែកប្រព័ន្ធអេឡិចត្រូនិច។ ក្រុមហ៊ុនរបស់យើងមានមូលដ្ឋាននៅក្នុងប្រទេសកម្ពុជាដើម្បីផ្តល់ជូនប្រទេសនូវជម្រើសច្នៃប្រឌិតថ្មីដើម្បីភ្ជាប់អ្នកលក់និងអ្នកប្រើប្រាស់');

    let mission: TitleAndContent = new TitleAndContent();
    mission.title.set('en', "Our Mission");
    mission.title.set('kh', "គោលបំណងរបស់យើង");
    mission.content.set('en', 'Our mission is to offer great technology at a very low cost and to create a sustainable economy that connects craftsmen, brands, and consumers through a transparent technology from end-to-end. Our vision is to promote local products and to create more jobs and opportunities, which in turn will help reduce poverty in Cambodia.');
    mission.content.set('kh', 'គោលបំណងរបស់យើង​ គឺការផ្តល់ជូននូវបច្ចេកវិទ្យាដ៏អស្ចារ្យ ដោយចំណាយតិចបំផុតនិងជួយពង្រីកចំណូលរបស់អ្នក។ ចក្ខុវិស័យរបស់យើងគឺជំរុញផលិតផលក្នុងស្រុកបង្កើតការងារនិងជួយកាត់បន្ថយភាពក្រីក្រនៅកម្ពុជា។');

    let vision: TitleAndContent = new TitleAndContent();
    vision.title.set('en', 'Khmum');
    vision.title.set('kh', 'ឃ្មុំ អ៊ីសប');
    vision.content.set('en', 'Khmum is a multi-vendors eCommerce Mobile Application that is available in iOS and Android. You will have your own Business Page inside the app to show case your products, manage and grow with one intuitive app. Sell smarter, with real-time sales data and intuitive inventory tools, tracking order status and real-time reports that you can access anytime anywhere.');
    vision.content.set('kh', 'Khmum គឺជា អេបដ៍លក់ផលិតផលតាមប្រព័ន្ធអេឡិចត្រូនិចលើ iOS និង Android។ អ្នកនឹងមានទំព័រពាណិជ្ជកម្មផ្ទាល់ខ្លួនរបស់អ្នកនៅក្នុងអេបដ៍​ ដើម្បីបង្ហាញជូននូវផលិតផលរបស់លោកអ្នក និង ជួយបង្កើនការលក់ប្រសើរជាងមុនរៀងរាល់ថ្ងៃ, ផ្តល់ទំនុកចិត្តដល់អតិថិជនរបស់លោកអ្នក, បង្កើនកម្រិតទទួលស្គាល់ម៉ាកយីហោ, ត្រួតពិនិត្យមើលប្រតិបត្តិការនៃការលក់, ត្រួតពិនិត្យមើលដំណើរការនៃការបញ្ជាទិញបានគ្រប់ពេលគ្រប់ទីកន្លែង ។');

    aboutUs.set('whoWeAre', whoWeAre);
    aboutUs.set('mission', mission);
    aboutUs.set('vision', vision);
    return aboutUs;
  }

  static platformFeatures(): PlatformFeature[] {
    let platformFeatures: PlatformFeature[] = [];
    let appDesign: PlatformFeature = new PlatformFeature();
    appDesign.iconUrl = "assets/home-image/design.png";
    appDesign.title.set('en', 'App Design');
    appDesign.title.set('kh', 'ការរចនាអេប');
    appDesign.content.set("en", 'Versita Software\'s App Design mean business which available in both iOS and Android. Our easy-to-use app will showcase your product and drive more business.');
    appDesign.content.set("kh", 'ឃ្មុំអេបដ៍ ត្រូវបានរចនាឡើងដើម្បីផ្តល់ភាពងាយស្រួលដល់អតិថិជន និងម្ចាស់អាជីវកម្ម ដែលមានទាំងនៅក្នុងប្រព័ន្ធ iOS និង Android ។ កម្មវិធីងាយស្រួលប្រើប្រាស់បង្ហាញពីផលិតផលរបស់អ្នកនិងជំរុញអាជីវកម្មកាន់តែច្រើន។');

    let dashboard: PlatformFeature = new PlatformFeature();
    dashboard.iconUrl = "assets/home-image/dashboard.png";
    dashboard.title.set('en', "Dashboard");
    dashboard.title.set('kh', "ផ្ទាំងគ្រប់គ្រង");
    dashboard.content.set('en', 'Sign into Versita Dashboard from any computer to access important data, manage customer order,gross profit report, insight to your sales in real-time, manage your inventory, and set employee permission from anywhere.');
    dashboard.content.set('kh', ' Versita Dashboard ងាយស្រួលក្នុងការគ្រប់គ្រង់ រាល់របាយការណ៍សំខាន់ៗ,  ត្រួតពិនិត្យប្រតិបត្តិការ និង របាយការណ៍ប្រាក់ចំនេញនៃការលក់, គ្រប់គ្រងលើការបញ្ជាទិញ​ ស្តុកទំនិញ និង គ្រប់គ្រងបុគ្គលិក។');

    let support: PlatformFeature = new PlatformFeature();
    support.iconUrl = "assets/home-image/support.png";
    support.title.set('en', "Support");
    support.title.set('kh', "ការគាំទ្រ");
    support.content.set('en', 'We are here to help guide you every step of the way. We can help you launch your online store with confidence. We can customise your own White Label to showcase your brand in the App Store and Play Store, using your company name.');
    support.content.set('kh', 'ពួកយើងនៅទីនេះដើម្បីជួយណែនាំអ្នកគ្រប់ជំហាន។ យើងអាចជួយអ្នកដំណើរការហាងអនឡាញរបស់អ្នកដោយភាពជឿជាក់។ យើងជួយបង្ហាញយីហោរបស់អ្នកនៅក្នុង App Store និង Play Store ដោយប្រើឈ្មោះក្រុមហ៊ុនរបស់អ្នក។');

    platformFeatures.push(appDesign);
    platformFeatures.push(dashboard);
    platformFeatures.push(support);
    return platformFeatures;

  }

  static ourTeam(): OurTeam[] {
    let ourMembersTeam: OurTeam[] = [];
    let member1: OurTeam = new OurTeam();
    member1.fullName = "Dr.Strange";
    member1.quote = "I am a slow walker, but I never walk back.";
    member1.imageUrl = "assets/home-image/our-team/thetpic.png";

    let member2: OurTeam = new OurTeam();
    member2.fullName = "Wolverine";
    member2.quote = "Alone we can do so little; together we can do so much.";
    member2.imageUrl = "assets/home-image/our-team/srouchpic.png";

    let member3: OurTeam = new OurTeam();
    member3.fullName = "Thor";
    member3.quote = "Positive mind, positive vibes, positive life.";
    member3.imageUrl = "assets/home-image/our-team/nakhimpic.png";

    let member4: OurTeam = new OurTeam();
    member4.fullName = "Doremon";
    member4.quote = "Try your best before you say no";
    member4.imageUrl = "assets/home-image/our-team/vichetpic.png";

    let member5: OurTeam = new OurTeam();
    member5.fullName = "Wolverine";
    member5.quote = "Developer";
    member5.imageUrl = "assets/home-image/our-team/srouchpic.png";

    let member6: OurTeam = new OurTeam();
    member6.fullName = "Panhchapor";
    member6.quote = "Seller";
    member6.imageUrl = "assets/home-image/our-team/sereypic.png";

    ourMembersTeam.push(member1);
    ourMembersTeam.push(member2);
    ourMembersTeam.push(member3);
    ourMembersTeam.push(member4);
    // ourMembersTeam.push(member5);
    // ourMembersTeam.push(member6);
    // ourMembersTeam.push(member6);
    // ourMembersTeam.push(member6);

    return ourMembersTeam;
  }

  static partner() : Partner[] {
    let partners: Partner[] = [];
    let partner1: Partner = new Partner();
    partner1.companyLogo = "assets/home-image/partner1.svg";
    partner1.companyName = "partner1";
    let partner2: Partner = new Partner();
    partner2.companyLogo = "assets/home-image/partner2.png";
    partner2.companyName = "partner2";
    let partner3: Partner = new Partner();
    partner3.companyLogo = "assets/home-image/partner3.jpg";
    partner3.companyName = "partner3";
    let partner4: Partner = new Partner();
    partner4.companyLogo = "assets/home-image/team1.png";
    partner4.companyName = "partner4";
    let partner5: Partner = new Partner();
    partner5.companyLogo = "assets/home-image/team2.png";
    partner5.companyName = "partner5";
    let partner6: Partner = new Partner();
    partner6.companyLogo = "assets/home-image/team3.png";
    partner6.companyName = "partner6";

    partners.push(partner1);
    partners.push(partner2);
    partners.push(partner3);
    partners.push(partner4);
    partners.push(partner5);
    partners.push(partner6);

    return partners;
  }

  static menuHomePage() {
    let menus: any[] = [];
    let ourPlatform: Map<string, string> = new Map();
    ourPlatform.set('en', "Our Platform");
    ourPlatform.set('kh', "កម្មវិធីឃ្មុំបស់យើង");

    let aboutUs: Map<string, string> = new Map();
    aboutUs.set('en', "About us");
    aboutUs.set('kh', "អំពី​ពួក​យើង");

    let platformFeature: Map<string, string> = new Map();
    platformFeature.set('en', "Platform Features");
    platformFeature.set('kh', "លក្ខណៈពិសេសនៃកម្មវិធីឃ្មុំ");

    let ourTeam: Map<string, string> = new Map();
    ourTeam.set('en', "Our Team");
    ourTeam.set('kh', "ក្រុម​របស់​យើង");

    let ourPartner: Map<string, string> = new Map();
    ourPartner.set('en', "Our Partner");
    ourPartner.set('kh', "ដៃគូរបស់យើង");

    menus.push(ourPlatform);
    menus.push(aboutUs);
    menus.push(platformFeature);
    // menus.push(ourTeam);
    // menus.push(ourPartner);

    return menus
  }

  static posHomePageMenu() {

    let menus: any[] = [];
    let ourService: Map<string, string> = new Map();
    ourService.set("en", "Our service");
    ourService.set("kh", "សេវាកម្មរបស់យើង");

    let howItWork: Map<string, string> = new Map();
    howItWork.set("en", "How it work");
    howItWork.set("kh", "របៀបដែលវាដំណើរការ");

    let pricing: Map<string, string> = new Map();
    pricing.set("en", "Pricing");
    pricing.set("kh", "តម្លៃ");

    let contact: Map<string, string> = new Map();
    contact.set("en", "Contact us");
    contact.set("kh", "ទាក់ទង​មក​ពួក​យើង");

    menus.push(ourService);
    menus.push(pricing);
    menus.push(howItWork);
    menus.push(contact);

    return menus
  }

}

/**
 * Created by vmc on 28/12/18.
 */
export class Product {
  id: string;
  title: Map<any, any>;
  imageUrls: string[];
  isEnable: boolean;
  modifiedAt: string;
  itemCountTotal: number;
}

import {Address} from './address';
import {GetSafeValue} from '../utils/get-safe-value';
import {StringFieldUtil} from '../utils/string-field-util';
import {User} from './user';
import {StringUtils} from '../utils/string-utils';

/**
 * Created by bunthetsam on 18/1/19.
 */

export class UserProfile {
  private _uid: string;
  private _firstName: string;
  private _lastName: string;
  private _email: string;
  private _imageUrl: string;
  private _address: Address;
  private _phoneNumber: Map<string, string>;
  private _storeIds: string[];
  private _roles: string[];
  constructor(){
    this.address = new Address();
    this.phoneNumber = new Map();
    this.phoneNumber.set('code', '+855');
    this.phoneNumber.set('isoCode', 'kh');
    this.address.country = '';
    this.uid = '';
    this.imageUrl = "";
    this.email = "";
    this.firstName = "";
    this.lastName = "";
    this.roles = [];
    this.storeIds = [];
  }

  clone(user: any){
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    let add: Address = new Address();
    add.clone(user.address);
    this.address = add;
    this.phoneNumber = user.phoneNumber;
    this.uid = user.uid;
    this.imageUrl = user.imageUrl;
    this.roles = user.roles;
    this.storeIds = user.storeIds ? user.storeIds : [];
  }

  get phoneNumber(): Map<string, string> {
    return this._phoneNumber;
  }

  set phoneNumber(value: Map<string, string>) {
    this._phoneNumber = value;
  }

  getFullName():string{
    return this._firstName +" "+ this._lastName;
  }

  get uid(): string {
    return this._uid;
  }

  set uid(value: string) {
    this._uid = value;
  }

  get firstName(): string {
    return this._firstName;
  }

  set firstName(value: string) {
    this._firstName = value;
  }

  get lastName(): string {
    return this._lastName;
  }

  set lastName(value: string) {
    this._lastName = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get imageUrl(): string {
    return this._imageUrl;
  }

  set imageUrl(value: string) {
    this._imageUrl = value;
  }

  get address(): Address {
    return this._address;
  }

  set address(value: Address) {
    this._address = value;
  }


  get roles(): string[] {
    return this._roles;
  }

  set roles(value: string[]) {
    this._roles = value;
  }


  get storeIds() {
    return this._storeIds;
  }

  set storeIds(value) {
    this._storeIds = value;
  }

  fromJson(key: string, data: UserProfile){
    this.uid = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.uid], null);
    this.firstName = GetSafeValue.getSafeValueAsString( data[StringFieldUtil.firstName], null);
    this.lastName = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.lastName], null);
    this.email = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.email], null);
    this.phoneNumber = GetSafeValue.getSafeValueAsMap(data[StringFieldUtil.phoneNumber], {'code': null, 'isoCode': null,'phone' : null});
    this.imageUrl = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.imageUrl], '');
    if (data[StringFieldUtil.address]){
      this.address.fromJson(data[StringFieldUtil.address]);
    }
    this.storeIds = GetSafeValue.getSafeValueAsList(data[StringFieldUtil.storeIds], [], []);
  }

  isVersitaUser(): boolean {
    return this.roles.includes(StringUtils.versitaUser)
  }

  toMap() {
    if (this.isVersitaUser()) {
      return this.toMapForVersitaUser();
    } else {
      return this.toMapForInternalUser();
    }
  }

  toMapForInternalUser() {
    return {
      "firstName": this.firstName,
      "lastName": this.lastName,
      "phoneNumber": this.phoneNumber.get('phone') === undefined ? {} : {
        'phone': this.phoneNumber.get('phone'),
        'code': this.phoneNumber.get('code'),
        'isoCode': this.phoneNumber.get('isoCode')
      },
      "email": this.email,
      "imageUrl": this.imageUrl,
      "address": this.address.toMap(),
      "uid" : this.uid,
      "storeIds": this.storeIds
    };
  }

  toMapForVersitaUser() {
    return {
      "firstName": this.firstName,
      "lastName": this.lastName,
      "phoneNumber": this.phoneNumber.get('phone') === undefined ? {} : {
        'phone': this.phoneNumber.get('phone'),
        'code': this.phoneNumber.get('code'),
        'isoCode': this.phoneNumber.get('isoCode')
      },
      "email": this.email,
      "imageUrl": this.imageUrl,
      "address": this.address.toMap(),
      "uid" : this.uid,
    };
  }
}

import {GetSafeValue} from '../utils/get-safe-value';
import {StringFieldUtil} from '../utils/string-field-util';

/**
 * Created by cheaphakdey on 26/3/19.
 */

export class OrderReportMap{
  private _currentReportDocument: number;
  private _recordCount: number;
  private _maxRecord: number;
  private _documentName: string;

  fromJson(data: OrderReportMap) {
    this.documentName = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.documentName], null);
    this.recordCount = GetSafeValue.getSafeValueAsNumber(data[StringFieldUtil.recordCount], 0);
    this.currentReportDocument = GetSafeValue.getSafeValueAsNumber(data[StringFieldUtil.currentDocument], 0);
    this.maxRecord = GetSafeValue.getSafeValueAsNumber(data[StringFieldUtil.maxRecord], 0);
  }

  get currentReportDocument(): number {
    return this._currentReportDocument;
  }

  set currentReportDocument(value: number) {
    this._currentReportDocument = value;
  }

  get recordCount(): number {
    return this._recordCount;
  }

  set recordCount(value: number) {
    this._recordCount = value;
  }

  get maxRecord(): number {
    return this._maxRecord;
  }

  set maxRecord(value: number) {
    this._maxRecord = value;
  }

  get documentName(): string {
    return this._documentName;
  }

  set documentName(value: string) {
    this._documentName = value;
  }

  toMap() {
    return {
      'documentName': this.documentName,
      'recordCount': this.recordCount,
      'currentDocument': this.currentReportDocument,
      'maxRecord': this.maxRecord,
    };
  }
}

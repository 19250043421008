import {GetSafeValue} from "../../utils/get-safe-value";

export class ItemCount{
  itemCountTotal: number;
  itemCountActive: number;

  constructor(itemCountTotal: number, itemCountActive: number) {
    this.itemCountTotal = itemCountTotal;
    this.itemCountActive = itemCountActive;
  }

  static fromJson(jsonObj: ItemCount){
    let itemCount = new ItemCount(
      GetSafeValue.getSafeValueAsNumber(jsonObj.itemCountTotal, 0),
      GetSafeValue.getSafeValueAsNumber(jsonObj.itemCountActive, 0)
    );

    return itemCount;
  }

  toMap() {
    return {
      "itemCountTotal": this.itemCountTotal,
      "itemCountActive": this.itemCountActive
    };
  }
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {SharedPreference} from '../shared-preference/shared-perference';
import {FirebaseService} from '../services/firebase.service';
import {User} from '../model/user';
import {environment} from "../../environments/environment";
import {LoggUtilInfo} from '../utils/loggUtil';
import {StringUtils} from '../utils/string-utils';

@Injectable({providedIn: 'root'})
export class AuthLoginGuard implements CanActivate {
  constructor(private router: Router,   private firebaseService: FirebaseService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if(SharedPreference.getUser() !== null && SharedPreference.getUser().email !== null){
      this.getUser();
    }
    let isLogin: boolean = SharedPreference.getLogin();
    SharedPreference.setIsShowingMenu(isLogin);
    if (isLogin) {
      return true;
    } else {
      if (environment.isEnableHomePage) {
        this.router.navigate(['/home-page']);

      } else {
        this.router.navigate(['/login']);
      }
      return false;
    }
  }

  getUser(){
      let user = new User();
      if(!SharedPreference.getUser().uid) {
        this.firebaseService.onReadDocument(FirebaseService.user, SharedPreference.getUser().uid).then(data => {
          user = data.data() as User;
          if (user === null || user.isEnable === null || !user.isEnable) {
            this.logout();
          }

          LoggUtilInfo.loggingEnd(StringUtils.readDocument + FirebaseService.user + "/" + SharedPreference.getUser().uid, ` component : auth guard`);
        }).catch((error) => {
          LoggUtilInfo.loggingError(StringUtils.readDocument + FirebaseService.user + "/" + SharedPreference.getUser().uid + error.toString(), ` component : auth guard`);
        });
      }
  }

  logout(): void{
    SharedPreference.setIsUserLogin(false);
    if(environment.isEnableHomePage){
      window.location.replace('#/home-page');
    }else{
      window.location.replace('#/login');
    }

    window.location.reload();
  }
}

import {StringUtils} from './string-utils';
import {FirebaseService} from '../services/firebase.service';
import {CategoryUtils} from './category-utils';
import {BrandUtils} from './brand-utils';
import Swal from "sweetalert2";
import {Observable} from 'rxjs';
import {SharedPreference} from "../shared-preference/shared-perference";
import {StoreUtils} from './store-utils';
import {Role} from '../model/role';

export class MethodUtil {
  static updateItemToServer(firebaseService: FirebaseService, item: any) {
    let batch = firebaseService.db.firestore.batch();

    let refItem = firebaseService.db.firestore.doc(StringUtils.getRestPath(item.documentPath));
    let refItemSpecial = firebaseService.db.firestore.doc(
      FirebaseService.itemSpecial + '/' + FirebaseService.itemSpecialCollection
    );
    let refItemCountCategory = firebaseService.db.firestore.doc(
      FirebaseService.getPathCounterInventory(SharedPreference.getStoreId()) +"/"+
      FirebaseService.categoryCollection
    );
    let refItemCountBrand = firebaseService.db.firestore.doc(
      FirebaseService.getPathCounterInventory(SharedPreference.getStoreId()) +"/"+
      FirebaseService.brandCollection
    );
    let pathItemStock = StringUtils.getRestPath(
      item.documentPath.replace(FirebaseService.getItemPath(), FirebaseService.getItemStockPath()));
    let refItemStock = firebaseService.db.firestore.doc(pathItemStock);

    let itemMap: Map<string, any> = new Map();
    itemMap.set(item.id+".isEnable", item.isEnable);
    itemMap.set(item.id+".modifiedAt", StringUtils.getDateLocal(StringUtils.getDateUTC(new Date())));

    if (item.category && item.category !== StringUtils.unknown) {
      let itemCountMap: Map<string, any> = new Map();
      let itemCount = CategoryUtils.incOrDecCategory(item.category, item.isEnable, false, false);

      if(item.category) {
        itemCountMap.set(item.category, itemCount.toMap());
        let categoryMapDb = StringUtils.strMapToObj(itemCountMap);
        batch.set(refItemCountCategory, categoryMapDb, {merge: true});
      }
    }

    if (item.brand && item.brand !== StringUtils.unknown) {
      let itemCountMap: Map<string, any> = new Map();
      let itemCount = BrandUtils.incOrDecBrand(item.brand, item.isEnable, false, false);

      if(item.brand) {
        itemCountMap.set(item.brand, itemCount.toMap());
        let brandMapDb = StringUtils.strMapToObj(itemCountMap);
        batch.set(refItemCountBrand, brandMapDb, {merge: true});
      }
    }

    item.variation.forEach((v, k)=>{
      let itemStockMap: Map<string, any> = new Map();
      itemStockMap.set(v.id+".isEnable", !item.isEnable ? false : v.isEnable );
      let itemStockMapDb = StringUtils.strMapToObj(itemStockMap);
      batch.update(refItemStock, itemStockMapDb);
    });

    let itemMapDb = StringUtils.strMapToObj(itemMap);
    batch.update(refItem, itemMapDb);
    if(item.saleType == StringUtils.sales || item.saleType == StringUtils.promotion) {
      batch.update(refItemSpecial, itemMapDb);
    }

    return batch.commit();
  }

  static informMessage(message: string, callBack?: Function , time?: number){
    Swal.fire({
      allowOutsideClick: false,
      position: 'center',
      type: 'success',
      text: message,
      showConfirmButton: false,
    });

    let timer: any = Observable.timer(time? time : 2000);
    timer.subscribe(t => {
      Swal.close();
      callBack ? callBack() : null
    });
  }

  static spinnerLoading(text: string){
    Swal.fire({
      html: 'processing...',
      timer: 3000,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    }).then(value => {
      MethodUtil.informMessage(text);
    });
  }

  static showAlertDialog(type: any, text: string, callBack?: Function, time?: number){
    Swal.fire({
      type: type,
      text: text,
    })
  }

  static showAlertDialogError(text: string, callBack?: Function, time?: number){

    Swal.fire({
      allowOutsideClick: true,
      position: 'center',
      type: 'error',
      text: text,
      showConfirmButton: false,
    });

    let timer: any = Observable.timer(time? time : 2000);
    timer.subscribe(t => {
      Swal.close();
      callBack ? callBack() : null
    });
  }

  static alertMessageAndCloseButton(title: string, content: string) {
    Swal.fire({
      title: `<h3>${title}</h3>`,
      html:
        `<small>${content}</small>`,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      showConfirmButton: false
    });
  }

  static warningAlertMessage(title: string, text: string, type: any, confirmButtonText: string, callback: Function){
    Swal.fire({
      title: title,
      text: text,
      type: type,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText
    }).then((result) => {
      if (result.value) {
        callback();
      }
    })
  }

  static warningAlertMessageOneButton(text: string, type: any, confirmButtonText: string, callback: Function){
    Swal.fire({
      text: text,
      type: type,
      allowOutsideClick: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: confirmButtonText
    }).then((result) => {
      if (result.value) {
        callback();
      }
    });
  }

  static selectStoreId(mapStoreIds: Map<string,string> , callback: Function, title?: string, placeHolder?: string){

    let mapDisplayOnOption: Map<string, string> = new Map();
    mapStoreIds.forEach((v, k) => {
      mapDisplayOnOption.set(k, (k != StringUtils.versitaSettingKey && k != StringUtils.versitaTermConditionKey)  ? v + "-" + k : v);
    });

    Swal.fire({
      title:title  ? `<h3>${title}</h3>` : `<h3>Please select store name</h3>`,
      input: 'select',
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonText: "Submit",
      inputOptions: mapDisplayOnOption,
      inputPlaceholder: placeHolder ? placeHolder : StringUtils.selectStoreName,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            if (value != StringUtils.versitaSettingKey && value != StringUtils.versitaTermConditionKey) {
              StoreUtils.storeName = mapStoreIds.get(value) + "-" + value;
            } else {
              StoreUtils.storeName = mapStoreIds.get(value);
            }

            resolve()
          } else {
            resolve(placeHolder ? "You need to select " + placeHolder : StringUtils.youNeedToSelectStoreName)
          }
        })
      }
    }).then(result => {
      if (result.value) {
          callback(result.value);
      }
    });
  }

  static clearStoreNameOnNavBar() {
    if (Role.hasVersitaAdmin(SharedPreference.getUser())) {
      StoreUtils.storeName = undefined;
    }
  }
}

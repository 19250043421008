import {GetSafeValue} from '../utils/get-safe-value';
import {StringFieldUtil} from '../utils/string-field-util';

export class Message {
  private _id: string;
  private _uid : string;
  private _date: string;
  private _isRead : boolean;
  private _senderName: string;
  private _senderPhotoUrl: string;
  private _text: string;
  private _role: string;

  constructor(){
    this.id = '';
    this.uid = '';
    this.date = '';
    this.senderName = '';
    this.senderPhotoUrl = '';
    this.text = '';
    this.role = '';
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get uid(): string {
    return this._uid;
  }

  set uid(value: string) {
    this._uid = value;
  }

  get date(): string {
    return this._date;
  }

  set date(value: string) {
    this._date = value;
  }

  get isRead(): boolean {
    return this._isRead;
  }

  set isRead(value: boolean) {
    this._isRead = value;
  }

  get senderName(): string {
    return this._senderName;
  }

  set senderName(value: string) {
    this._senderName = value;
  }

  get senderPhotoUrl(): string {
    return this._senderPhotoUrl;
  }

  set senderPhotoUrl(value: string) {
    this._senderPhotoUrl = value;
  }

  get text(): string {
    return this._text;
  }

  set text(value: string) {
    this._text = value;
  }

  get role(): string {
    return this._role;
  }

  set role(value: string) {
    this._role = value;
  }

  clone(data: Message){
    this.id = data.id;
    this.uid = data.uid;
    this.date = data.date;
    this.isRead = data.isRead;
    this.senderName = data.senderName;
    this.senderPhotoUrl = data.senderPhotoUrl;
    this.text = data.text;
    this.role = data.role;
  }

  fromJson(data: Message){
    this.uid = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.uid], null);
    this.date = data[StringFieldUtil.date];
    this.isRead = GetSafeValue.getSafeValueAsBoolean( data[StringFieldUtil.isRead], false);
    this.senderName = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.senderName], null);
    this.senderPhotoUrl = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.senderPhotoUrl], null);
    this.text = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.text], null);
    this.role = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.role], null);
  }

  static fromJson(data: Message){
    let message = new Message();
    message.fromJson(data);
    return message;
  }

  toMap(){
    return {
      'uid': this.uid,
      'date' : this.date,
      'isRead' : this.isRead,
      'senderName' : this.senderName,
      'senderPhotoUrl': this.senderPhotoUrl,
      'text' : this.text,
      'role' : this.role,
    }
  }

  toMapSummary(){
    return {
      'date' : this.date,
      'isRead' : this.isRead,
      'text' : this.text,
      'role' : this.role,
    }
  }
}


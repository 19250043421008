import {Category} from '../inventory/model/category';
import {FirebaseService} from '../services/firebase.service';
import {StringUtils} from './string-utils';
import {Role} from '../model/role';
import {SharedPreference} from '../shared-preference/shared-perference';
import {ItemCount} from "../inventory/model/item-count";
import {UICategory} from "../inventory/category/list/category.list.component";
import {RoleUtils} from './role-utils';
import { StoreUtils } from "./store-utils";


export class CategoryUtils {
  private static _category: Map<string, Category>;
  private static _itemCount: Map<string, ItemCount>;

  static get category(): Map<string, Category> {
    return this._category;
  }

  static setCategory(value: any) {
    if(!this._category){
      this._category = new Map();
    }

    StringUtils.objToStrMap(value.data() as Category).forEach((v, k)=>{
      let category = Category.fromJson(v);
      this._category.set(k, category);
    });
  }

  static get itemCount(): Map<string, ItemCount> {
    return this._itemCount;
  }

  static getCountTotal(catId: string){
    if(this.category.get(catId)) {
      return this.category.get(catId).itemCountTotal;
    }else {
      return 0;
    }
  }

  static setItemCount(value: any) {
    this._itemCount = new Map();
    StringUtils.objToStrMap(value.data() as ItemCount).forEach((v, k)=>{
      let itemCount = ItemCount.fromJson(v);
      this._itemCount.set(k, itemCount);
    });
  }

  static resetItemCount() {
    this._itemCount = new Map();
  }

  static isItemCountEmpty(){
    return !this.itemCount || this.itemCount.size <= 0;
  }

  static isCategoryEmpty(){
    return !this.category || this.category.size <= 0;
  }

  static incOrDecCategory(categoryId: string, isEnable: boolean, isNew: boolean, isDelete: boolean){
    let itemCount = new ItemCount(1,0);

    if(!CategoryUtils.isItemCountEmpty() && !CategoryUtils.isItemCountEmpty() && CategoryUtils.itemCount.get(categoryId)) {
      itemCount = CategoryUtils.itemCount.get(categoryId);
      let countTotal = 0;
      let countActive = 0;

      if(!isNaN(itemCount.itemCountTotal)){
        countTotal = itemCount.itemCountTotal;
      }
      if(!isNaN(itemCount.itemCountActive)){
        countActive = itemCount.itemCountActive;
      }

      if (isDelete) {
        if (itemCount.itemCountTotal > 0) {
          countTotal--;
          itemCount.itemCountTotal = countTotal;
        }
      } else {
        if (isNew) {
          countTotal++;
          itemCount.itemCountTotal = countTotal;
          itemCount.itemCountActive = countActive;
        } else {
          if (isEnable) {
            countActive++;
            itemCount.itemCountActive = countActive;
          } else {
            countActive--;
            itemCount.itemCountActive = countActive;
          }
        }
      }

      CategoryUtils.itemCount.set(categoryId, itemCount);
    }
    return itemCount;
  }

  static updateChangeCategory(newCategoryId: string, oldCategoryId: string){
    let mapItemCount: Map<string, ItemCount> = new Map();
    let newItemCount: ItemCount;
    let count = 0;

    if(this.itemCount.get(newCategoryId)){
      newItemCount = this.itemCount.get(newCategoryId);
    }else {
      newItemCount = new ItemCount(0, 0);
    }

    if(newItemCount.itemCountTotal){
      count = newItemCount.itemCountTotal;
    }

    count++;
    newItemCount.itemCountTotal = count;

    if(isNaN(newItemCount.itemCountActive)){
      newItemCount.itemCountActive = 0;
    }

    CategoryUtils.itemCount.set(newCategoryId, newItemCount);
    let oldItemCount = new ItemCount(0,0);

    if(this.itemCount.get(oldCategoryId)) {
      oldItemCount = this.itemCount.get(oldCategoryId);
      count = oldItemCount.itemCountTotal;

      if(count > 0) {
        count--;
        oldItemCount.itemCountTotal = count;
      }

      CategoryUtils.itemCount.set(oldCategoryId, oldItemCount);
    }

    mapItemCount.set('new', newItemCount);
    mapItemCount.set('old', oldItemCount);
    return mapItemCount;
  }

  static getMainHasNoSubcategoryAndSubCategory(){
    let categories = [];
    let subCategoryId: Set<string> = new Set();

    this._category.forEach((value, key)=>{
      let c = Category.clone(value);
      if(value.pid !== "_"){
        let pid = value.pid.substr(1);
        subCategoryId.add(pid);

        let category: Category = Array.from(this._category.values()).filter(category=> category.id === pid)[0];
        c.title.set(StringUtils.stTitleEn, CategoryUtils.getMainCategoryTitle(category)  + "_" + value.title.get(StringUtils.stTitleEn));
      }

      categories.push(c);
    });

    subCategoryId.forEach((id)=>{
      let category = categories.filter(category=> category.id === id)[0];
      let index = categories.indexOf(category);

      categories.splice(index, 1);
    });

    return categories;
  }

  static getMainCategoryTitle(category: Category) {

    let titles: string[] = [];
    let title: string = '';
    let pid = category.pid;
    while (pid !== "_"){
      let id = pid.substr(1);
      let mainC:Category = Array.from(this._category.values()).filter(category=> category.id === id)[0];
      pid = mainC.pid;
      titles.push(mainC.title.get('en'));
    }

    titles.reverse().forEach((t) => {
      title += (t + "_");
    });

    return title + category.title.get(StringUtils.stTitleEn);

  }

  static getSetting(isSingleStoreSetting: boolean) {
    return {
      noDataMessage: null,
      actions: {
        add: false,
        edit: false,
        delete: false,
        custom: CategoryUtils.customAction(isSingleStoreSetting),
        position: 'right'
      },
      columns: {
        imageUrls:{
          title: StringUtils.image,
          filter: false,
          type: StringUtils.html,
          class: StringUtils.left,
          width: StringUtils.width120,
          editable: false,
          valuePrepareFunction: (imageUrl: string) => {
            return `<img width="${StringUtils.widthImg}" height="${StringUtils.heightImg}" src="${imageUrl}">`;
          },
        },
        id: {
          title: StringUtils.id,
          width: StringUtils.columnWidth200px,
          editable: false,
        },
        title: {
          title: StringUtils.cateAndSubCate,
          type: StringUtils.html,
          valuePrepareFunction: (title: Map<string, string>, category: Category) => {
            let pidEn = CategoryUtils._category.get(category.pid.substr(1))
              ? CategoryUtils._category.get(category.pid.substr(1)).title.get(StringUtils.en) : "";
            let pidKh = CategoryUtils._category.get(category.pid.substr(1))
              ? CategoryUtils._category.get(category.pid.substr(1)).title.get(StringUtils.kh) : "";
            if(category.pid === '_'){
              return `<label>
                    ${title.get(StringUtils.en) == null ? '' : title.get(StringUtils.en)} 
                  </label> 
                  <br> 
                  <label>
                    ${title.get(StringUtils.kh) == null ? '' : title.get(StringUtils.kh)}
                  </label>`;
            }else {
              return `<label>
                    ${title.get(StringUtils.en) == null ? '' : pidEn + "_" + title.get(StringUtils.en)} 
                  </label> 
                  <br> 
                  <label>
                    ${title.get(StringUtils.kh) == null ? '' : pidKh + "_" + title.get(StringUtils.kh)}
                  </label>`;
            }
          },
          filterFunction(title?: Map<string, string>, search?: string): boolean {
            if(title.get(StringUtils.kh) != null) {
              return StringUtils.compareStringIgnoreCase(title.get(StringUtils.en), search)
                || StringUtils.compareStringIgnoreCase(title.get(StringUtils.kh), search);
            }
            return StringUtils.compareStringIgnoreCase(title.get(StringUtils.en), search);
          },
        },
        itemCountActive: {
          title: StringUtils.itemCountActive,
          width: StringUtils.width140,
          editable: false,
        },
        itemCountTotal: {
          title: StringUtils.itemCountTotal,
          width: StringUtils.width140,
          editable: false,
        },
        modifiedAt: {
          title: StringUtils.modifiedAt,
          editable: false,
          width: StringUtils.columnWidth180,
          valuePrepareFunction: (dateModified: string) => {
            if(dateModified !== null && dateModified !== undefined) {
              return !dateModified ? null : StringUtils.getDateFormat(dateModified, 'yyyy-MM-dd hh:mm a').toString();
            }
          },
        },
        isEnable: {
          title: StringUtils.enable,
          type: StringUtils.html,
          class: StringUtils.center,
          width: StringUtils.width95,
          editable: false,
          filter: {
            type: StringUtils.list,
            config: {
              selectText: StringUtils.select,
              list: [
                { value: true, title: StringUtils.enable },
                { value: false, title: StringUtils.disable },
              ],
            },
          },
          valuePrepareFunction: (isEnable: boolean) => {
            return isEnable
              ? `<i class="mdi-24px mdi mdi-check-circle enable"></i>`
              : `<i class="mdi-24px mdi mdi-close-circle disable"></i>`;
          },

          filterFunction(isEnable: boolean, search?: string): boolean {
            return StringUtils.filterEnableDisable(isEnable, search);
          }
        },

      },

    }
  }

  private static customAction(isSingleStoreSetting: boolean) {

    let showEnableDisableOnCustomer = (Role.hasAdminRole(SharedPreference.getUser()) && (isSingleStoreSetting || StoreUtils.isHomeConfig())) || Role.hasVersitaAdmin(SharedPreference.getUser());
    if (showEnableDisableOnCustomer && !RoleUtils.hasReadOnlyRole()) {
      return [
        {
          name: 'enable',
          title: 'Enable ',
        },
        {
          name: 'disable',
          title: 'Disable ',
        },
      ];
    } else {
      return [];
    }
  }

  static addUICategory(category) {
    let uiCategory = new UICategory(
      category.id,
      category.pid,
      category.title,
      category.imageUrls,
      category.isEnable,
      category.modifiedAt,
      CategoryUtils.itemCount.get(category.id).itemCountTotal,
      CategoryUtils.itemCount.get(category.id).itemCountActive
    );

    return uiCategory;
  }

  static setTotalActiveItemCount(catId: string, totalCount: number, activeCount: number){
    if(CategoryUtils.isItemCountEmpty()){
      this._itemCount = new Map();
    }

    let itemCount = new ItemCount(totalCount, activeCount);
    this._itemCount.set(catId, itemCount);
  }

  static clear(){
    if(this.category){
      this.category.clear();
    }

    if(this.itemCount){
      this.itemCount.clear();
    }
  }

  static getLoggingMsg() {
    return StringUtils.readDocument + FirebaseService.category + "/" + FirebaseService.categoryCollection;
  }
}

import {GetSafeValue} from './get-safe-value';

export class StringFieldUtil {
  //== .item. === //
  static barcode: string = "barcode";
  static barcodes: string = "barcodes";
  static relatedItems: string = "relatedItems";
  static documentPath: string = "documentPath";
  static rating: string = "rating";
  static description: string = "description";
  static suggestionVariations: string = "suggestionVariations";
  static title: string = "title";
  static brand: string = "brand";
  static reviewCounter: string = "reviewCounter";
  static category: string = "category";
  static size: string = "size";
  static qtyPerCase: string = "qtyPerCase";
  static saleType: string = "saleType";
  static originalPrice: string = "originalPrice";
  static unitCost: string = "unitCost";
  static discount: string = "discount";
  static discountType: string = "discountType";
  static percentage: string = "percentage";
  static absolute: string = "absolute";
  static regions: string = "regions";
  static availableQty: string = "availableQty";
  static bufferQty: string = "bufferQty";
  static cartLimit: string = "cartLimit";
  static purchaseLimit: string = "purchaseLimit";
  static keywords: string = "keywords";
  static imageUrls: string = "imageUrls";
  static target: string = "target";
  static targetType: string = "targetType";
  static elementIds: string = "elementIds";
  static sortSection: string = "sort";
  static accessToken: string = "accessToken";
  static pageId: string = "pageId";
  static clientId: string = "clientId";
  static clientSecret: string = "clientSecret";
  static maxVideoSize: string = "maxVideoSize";
  static maxImageSize: string = "maxImageSize";
  static isWatermarkEnable: string = "isWatermarkEnable";
  static watermark: string = "watermark";
  static salePrice: string = "salePrice";
  static facebookConfig: string = "facebookConfig";
  static isEnable: string = "isEnable";
  static index: string = "index";
  static isWhiteLabel: string = "isWhiteLabel";
  static isOutStock: string = "isOutStock";
  static isDelete: string = "isDelete";
  static createAt: string = "createAt";
  static variation: string = "variation";
  static color: string = "color";
  static pid: string = "pid";
  static id: string = "id";
  static cellType: string = "cellType";
  static elementShape: string = "elementShape";
  static dataSource: string = "dataSource";
  static cellCount: string = "cellCount";
  static slideInterval: string = "slideInterval";
  static itemCountActive: string = "itemCountActive";
  static itemCountTotal: string = "itemCountTotal";
  static itemSpecialExpirationDate: string = "itemSpecialExpirationDate";
  static modifiedAt: string = "modifiedAt";
  static selectedVariant: string = "selectedVariant";
  static ranking: string = "ranking";
  static isApproved: string = "isApproved";
  static modifier: string = "modifier";
  static tag: string = "tag";

  //== .Bid Item. =//
  static statusAt: string = "statusAt";
  static cancelledAt: string = "cancelledAt";
  static closeAt: string = "closeAt";
  static bidAt: string = "bidAt";
  static openAt: string = "openAt";
  static duration: string = "duration";
  static endAt: string = "endAt";
  static registrationFee: string = "registrationFee";
  static publish: string = "publish";
  static minRegistration: string = "minRegistration";
  static maxRegistration: string = "maxRegistration";
  static termCondition: string = "termCondition";
  static registration: string = "registration";
  static typeDetail: string = "typeDetail";
  static bestBuy: string = "bestBuy";
  static unique: string = "unique";
  static donation: string = "donation";
  static reward: string = "reward";
  static number: string = "number";
  static startingPrice: string = "startingPrice";
  static bideal: string = "bideal";
  static presetPrice: string = "presetPrice";
  static bidRangePrice: string = "bidRangePrice";
  static otherWinner: string = "otherWinner";

  //== .Admin Setting  ==//
  static storePhoneNumber: string = "storePhoneNumber";
  static pushStatusDeliveryMessage: string = "pushStatusDeliveryMessage";
  static pushStatusProcessedMessage: string = "pushStatusProcessedMessage";
  static storeOrderConfirmMessage: string = "storeOrderConfirmMessage";
  static sameDayDeliveryCutOffTime: string = "sameDayDeliveryCutOffTime";
  static socialMedia: string = "socialMedia";
  static emailSetting: string = "emailSetting";
  static appLinks: string = "appLinks";
  static items: string = "items";
  static deliverySettings: string = "deliverySettings";
  static stores: string = "stores";
  static adminEmail: string ="adminEmail";
  static adminPassword: string = "adminPassword";
  static recipientEmailTeam: string = "recipientEmailTeam";
  static deliveryEmailTeam: string = "deliveryEmailTeam";
  static packageEmailTeam: string = "packageEmailTeam";
  static country: string = "country";
  static city: string = "city";
  static sameDayCost: string = "sameDayCost";
  static nextDayCost: string = "nextDayCost";
  static limitCostFreeShipping: string ="limitCostFreeShipping";
  static deliveryOption: string ="deliveryOption";
  static customDayShipping: string ="customDayShipping";
  static customDayCost: string ="customDayCost";
  static facebook: string = "facebook";
  static instagram: string = "instagram";
  static website: string ="website";
  static androidUrl: string ="androidUrl";
  static iosUrl: string ="iosUrl";
  static webUrl: string ="webUrl";
  static nameStore: string = "name";
  static storeName: string = "storeName";
  static storeBannerUrl: string = "storeBannerUrl";
  static storeId: string = "storeId";
  static refId: string = "refId";
  static rewardType: string = "rewardType";
  static imageUrl: string ="imageUrl";
  static address: string = "address";
  static phone: string ="phone";
  static phoneNumber: string ="phoneNumber";
  static phoneNumber_Phone: string ="phoneNumber.phone";
  static openHours: string ="openHours";
  static storeMapUrl: string ="storeMapUrl";
  static playStore: string = "playStore";
  static appStore: string = "appStore";
  static storeAvailableStockWarning: string = "storeAvailableStockWarning";
  static documentMap: string = "documentMap";
  static recordCount: string = "recordCount";
  static maxRecord: string = "maxRecord";
  static countries: string = "countries";
  static countryCity: string = "countryCity";

  //== .Terms and Condition ==//
  static termsAndCondition: string = "terms and condition";
  static termsAndConditionPayment: string = "termsAndConditionPayment";


  //== .User ==//
  static uid: string = "uid";
  static firstName: string = "firstName";
  static lastName: string = "lastName";
  static email: string = "email";
  static loginVia: string = "loginVia";
  static companyName: string = "companyName";
  static gender: string = "gender";
  static createdAt: string = "createdAt";
  static singedInAt: string = "singedInAt";
  static roles: string = "roles";
  static devTokens: string = "devTokens";
  static topics: string = "topics";
  static addressLineOne: string = "addressLineOne";
  static addressLineTwo: string = "addressLineTwo";
  static state: string = "state";
  static stats: string = "stats";
  static postCode: string = "postCode";
  static isRequireChangePassword: string = "isRequireChangePassword";
  static ownerName: string = "ownerName";
  static ownerEmail: string = "ownerEmail";
  static ownerPhoneNumber: string = "ownerPhoneNumber";
  static adminName: string = "ownerName";
  static adminPhoneNumber: string = "ownerPhoneNumber";
  static businessSetting: string = "businessSetting";
  static storeOwner: string = "storeOwner";
  static storeAdmin: string = "storeAdmin";

  //== .Message ==/
  static isRead: string = "isRead";
  static date: string = "date";
  static senderName: string = "senderName";
  static senderPhotoUrl: string = "senderPhotoUrl";
  static text: string = "text";
  static role: string = "role";

  //== .Order ==//
  static orderColor: string = "orderColor";
  static subtotal: string = "subtotal";
  static orderQty: string = "orderQty";
  static orderSize: string = "orderSize";
  static orderQtyPerCase: string = "orderQtyPerCase";
  static orderType: string = "orderType";
  static isCheck: string = "isCheck";
  static shippingAddress: string = "shippingAddress";
  static tracking: string = "tracking";
  static deliveryMethod: string = "deliveryMethod";
  static orderStatusPath: string = "orderStatusPath";
  static storeDiscount: string = "storeDiscount";
  static comment: string = "comment";
  static hasSentEmail: string = "hasSentEmail";
  static isPaid: string = "isPaid";
  static paymentMethod: string = "paymentMethod";
  static orderDate: string = "orderDate";
  static deliveryDate: string = "deliveryDate";
  static customerId: string = "customerId";
  static total: string = "total";
  //== .phase ==//
  static status: string = "status";
  static userId: string = "userId";
  static userIds: string = "userIds";
  static name_: string = "name";
  static message: string = "message";
  static isProcessingEnable = "isProcessingEnable";
  static isProcessedEnable = "isProcessedEnable";
  static isPackagingEnable = "isPackagingEnable";
  static isPackagedEnable = "isPackagedEnable";
  static isDeliveringEnable = "isDeliveringEnable";
  static isDeliveredEnable = "isDeliveredEnable";
  static isCouponEnable = "isCouponEnable";
  static isCreditEnable = "isCreditEnable";
  static isQrCodeEnable = "isQrCodeEnable";
  static isInStoreEnable = "isInStoreEnable";
  static isShippingDiscountEnable = "isShippingDiscountEnable";
  static isCustomerDetailEnable = "isCustomerDetailEnable";

  static orderId: string = "orderId";
  static customerName: string = "customerName";
  static orderStatus: string = "orderStatus";
  static documentName: string = "documentName";
  static currentDocument: string = "currentDocument";



  //== .email ==//
  static toEmails = "toEmails";
  static fromEmail = "fromEmails";
  static subject = "subject";
  static platform = "platform";
  static isSent = "isSent";
  static notificationDate = "notificationDate";
  static type = "type";
  static sort = "sort";
  static categoryType = "categoryType";
  static location = "location";
  static startsDate = "startsDate";
  static endsDate = "endsDate";
  static trigger = "trigger";
  static source = "source";
  static isConsignmentStore = "isConsignmentStore";
  static accountName = "accountName";
  static accountNumber = "accountNumber";
  static bankName = "bankName";
  static transactionId = "transactionId";
  static cashOnDelivery = "cashOnDelivery";
  static bank = "bank";
  static value = "value";
  static day = "day";
  static days = "days";
  static newPassword = "newPassword";
  static storeIds = "storeIds";
  static bankIds = "bankIds";
  static keywordsIndexes = "keywordsIndexes";
  static about = "about";
  static businessType = "businessType";
  static commentOnReceipt = "commentOnReceipt";

  //== .credit ==//
  static couponCode = "couponCode";
  static couponName = "couponName";
  static coupon = "coupon";
  static expirationDate = "expirationDate";
  static limit = "limit";
  static conditional = "conditional";
  static startDate = "startDate";
  static uids = "uids";
  static isReusable = "isReusable";
  static isPublic = "isPublic";
  static itemIds = "itemIds";
  static countUsage = "countUsage";
  static countUniqueUsage = "countUniqueUsage";
  static code = "code";
  static excludeStore = "excludeStore";
  static includeStore = "includeStore";
  static paymentMethods = "paymentMethods";
  static messageError = "messageError";
  static messageSuccess = "messageSuccess";

  /*---Business Setting ==> Contract Setting---*/
  static transactionRate = "transactionRate";
  static membershipFee = "membershipFee";
  static contractStartDate = "contractStartDate";
  static contractEndDate = "contractEndDate";
  static contractSetting = "contractSetting";

  //---------CDN Config ----------------------//
  static cdn: string = "cdn";
  static domain: string = "domain";
  static interval: string = "interval";
  static lastUpdate: string = "lastUpdate";
  static paths: string = "paths";
  static isQrCodeSystemControlEnable = "isQrCodeSystemControlEnable";
  static isGoLive = "isGoLive";
  static isSystemDeliveryRate = "isSystemDeliveryRate";
  static isExportReportEnable = "isExportReportEnable";
  static isQrCodeUniversalProductEnable = "isQrCodeUniversalProductEnable";
  static isGoogleImageEnable = "isGoogleImageEnable";
  static isUploadVideoEnable = "isUploadVideoEnable";
  static isItemLabelExportEnable = "isItemLabelExportEnable";
  static isPos = "isPos";
  static isOrderEditable = "isOrderEditable";
  static isBackgroundRemovalEnable = "isBackgroundRemovalEnable";
  static isPrinterAddressEnable = "isPrinterAddressEnable";
  static isUsePrinter = "isUsePrinter";
  static group = "group";

  /*---Gps---*/
  static latitude = "lat";
  static longitude = "lon";
  static gps = "gps";

  // delivery settings
  static generalSettings = 'generalSettings';
  static sameRegionDeliveryRate  = 'sameRegionDeliveryRate';
  static baseLocation = 'baseLocation';
  static deliveryRoute = 'deliveryRoute';
  static base = 'base';
  static store = 'store';
  static customer = 'customer';
  static options = 'options';
  static maxSameRegionDeliveryDistance = "maxSameRegionDeliveryDistance";
  static maxSameRegionDeliveryCost = "maxSameRegionDeliveryCost";
  static minSameRegionDeliveryCost = "minSameRegionDeliveryCost";

}

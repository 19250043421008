import * as CryptoJS from 'crypto-js';
import {environment} from "../../environments/environment";

const key = CryptoJS.enc.Utf8.parse(environment.encryptKey);
const iv = CryptoJS.enc.Utf8.parse(environment.encryptKey);
export class AesHelper {
  public static encrypt(value: string) {
    return CryptoJS.AES.encrypt(
      value, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString();
  }
  public static decrypt(encrypted: string) {
    return CryptoJS.AES.decrypt(
      encrypted, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString(CryptoJS.enc.Utf8);
  }
}

import {StringUtils} from "../utils/string-utils";
/**
 * Created by cheaphakdey on 23/3/19.
 */

export class ActiveOrderStatus {
  documentName: string;
  recordCount: number;
  maxRecord: number;

  fromJson(data: any) {
    this.documentName = data['documentName'];
    this.recordCount = data['recordCount'];
    this.maxRecord = data['maxRecord'];
  }

  toMap() {
    return{
      'documentName': this.documentName,
      'recordCount': this.recordCount,
      'maxRecord': this.maxRecord
    };
  }
}

export class OrderStatusMap {
  key: string;
  currentDocument: number;
  activeOrderStatusMap: Map<string, ActiveOrderStatus>;

  constructor(){
    this.activeOrderStatusMap = new Map();
  }

  fromJson(key: string, data: any) {
    this.activeOrderStatusMap = new Map();
    this.currentDocument = data['currentDocument'];
    this.key = key;

    let activeOrder: ActiveOrderStatus;
    StringUtils.objToStrMap(data['documents']).forEach((v, k)=>{
      activeOrder = new ActiveOrderStatus();
      activeOrder.fromJson(v);
      this.activeOrderStatusMap.set(k, activeOrder);
    });
  }

  toMap() {
    let mapObj: Map<string, any> = new Map();
    this.activeOrderStatusMap.forEach((v, k) => {
      mapObj.set(k, v.toMap());
    });

    return {
      "currentDocument": this.currentDocument,
      "documents": StringUtils.strMapToObj(mapObj)
    };
  }
}

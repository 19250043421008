import * as $ from 'jquery';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule, LocationStrategy, HashLocationStrategy} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';

import {FullComponent} from './layouts/full/full.component';
import {BlankComponent} from './layouts/blank/blank.component';
import {SIDEBAR_TOGGLE_DIRECTIVES} from './shared/sidebar.directive';
import {NavigationComponent} from './shared/header-navigation/navigation.component';
import {SidebarComponent} from './shared/sidebar/sidebar.component';
import {RightSidebarComponent} from './shared/right-sidebar/rightsidebar.component';
import {BreadcrumbComponent} from './shared/breadcrumb/breadcrumb.component';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SpinnerComponent} from './shared/spinner.component';
import {FirebaseService} from "./services/firebase.service";
import {AngularFireModule} from "@angular/fire";
import {AngularFirestoreModule} from "@angular/fire/firestore";
import {environment} from "../environments/environment";
import {UploadFileService} from "./services/uploadfile.service";
import {AngularFireDatabaseModule} from "@angular/fire/database";
import {AngularFireStorageModule} from "@angular/fire/storage";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {MomentModule} from 'angular2-moment';
import {HttpModule} from '@angular/http';
import {Ng2LoadingSpinnerModule} from "ng2-loading-spinner";
import {ToastrModule} from "ngx-toastr";
import {ToasterService} from "./services/toaster.service";
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import {SocketService} from './services/socketio-service/socket.service';
@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    RightSidebarComponent,
    SIDEBAR_TOGGLE_DIRECTIVES,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularMultiSelectModule,
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgbModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    MomentModule,
    NgIdleKeepaliveModule.forRoot(),
    Ng2LoadingSpinnerModule,
    ToastrModule.forRoot() // ToastrModule added
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    FirebaseService,
    UploadFileService,
    SocketService,
    ToasterService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Role} from '../model/role';
import {SharedPreference} from '../shared-preference/shared-perference';
import {profile} from '../inventory/route';
import {AuthenticationService} from '../services/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class ItemSpecialService implements CanActivate{

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!SharedPreference.getUser()) {
      this.authenticationService.onLogout(false);
      return false;
    }
    if (SharedPreference.getUser().isRequireChangePassword) {
      this.router.navigate([profile]);
      return false;
    } else {
      return Role.hasAdminRole(SharedPreference.getUser());
    }
  }
}

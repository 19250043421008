import {AngularFireDatabase, AngularFireList} from '@angular/fire/database';
import {FileUpload} from '../inventory/model/fileupload';
import {Injectable} from '@angular/core';
import {FirebaseApp} from '@angular/fire';
import {AngularFirestore} from '@angular/fire/firestore';
import {StringFormatDateUtil} from '../utils/string-format-date';
import {FirebaseService} from './firebase.service';
import {DatePipe} from '@angular/common';
import {StringUtils} from '../utils/string-utils';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {
  private storage: any;

  constructor(public firebase: FirebaseApp, public db: AngularFireDatabase,
  public firebaseService: AngularFirestore) {
    this.storage = firebase.storage(environment.firebase['storageBucket']);
  }

  pushFileToStorage(
    basePath: string,
    fileUpload: FileUpload,
    progress: any,
    isString?: string,
    onComplete?: Function,
    uid?: string,  isGif? : boolean) {

    return new Promise((resolve, reject) => {
      const storageRef = this.storage.ref();
      let fileLocation;
      let fileUploadName;

      if(isGif)
        fileUploadName =  fileUpload.file.name;
      else
        fileUploadName = fileUpload.name ? fileUpload.name + ".jpg" : fileUpload.file.name;

      console.log("===> fileUploadName", fileUploadName);

      if (basePath == FirebaseService.profile) {
        fileLocation = storageRef.child(`${basePath}/${uid}.jpg`);
      } else {
        fileLocation = storageRef.child(`${basePath}/${fileUploadName}`);
      }

      let uploadSetting = isGif ? {cacheControl: "max-age=0, no-store", contentType: "image/gif"} : {cacheControl: "max-age=0, no-store", contentType: "image/jpeg"};
      let uploadTask;
      isString ? (() => {
          let format = isString.includes(';base64,') ? 'base64' : 'raw';
          format === 'base64' && (() => isString = isString.split(';base64,')[1])();
          uploadTask = fileLocation.putString(isString, format, uploadSetting);
        })() :
        (() => uploadTask = fileLocation.put(fileUpload.file, uploadSetting))();
      console.log("upload");

      uploadTask.on(
        "state_changed",
        (_snap: any) => {
          progress.percentage = Math.ceil(_snap.bytesTransferred / _snap.totalBytes) * 100.0;
          console.log("progress " + progress.percentage);
        },
        _error => {
          console.log(_error);
        },
        () => {
          fileLocation
            .getDownloadURL()
            .then((url) => {
              //https://firebasestorage.googleapis.com/v0/b/versita-store-vr-preprd.appspot.com/o/item%2FCoCo%2FHACCStoreforHomepage.jpg?alt=media&token=04956ec8-13ce-4ae7-b719-9b906f89b8e2
              let imagePath = url.substring(url.lastIndexOf("/o/") + 3, url.lastIndexOf("?alt"));
              let cdnUrl: string = `https://${environment.imageDomain}/cdn/image/${StringUtils.CDNWidth700}/${imagePath}?key=${btoa(new Date().getTime().toString())}`;
              onComplete(cdnUrl);
            })
          ;
        }
      );
    });
  }

  saveFileData(basePath: string, fileUpload: FileUpload) {
    this.db.list(`${basePath}`).push(fileUpload);
  }

  getFileUploads(basePath: string, numberItems: number): AngularFireList<FileUpload> {
    console.log("download");
    return this.db.list(basePath, ref =>
      ref.limitToLast(numberItems));
  }

  getFileUpload(basePath: string, childId: string): AngularFireList<FileUpload> {
    console.log("download");
    return this.db.list(basePath, ref => ref.child(childId));
  }

  deleteFileUpload(basePath: string, fileUpload: FileUpload) {
    this.deleteFileDatabase(basePath, fileUpload.key)
      .then(() => {
        this.deleteFileStorage(basePath, fileUpload.name);
      })
      .catch(error => console.log(error));
  }

  private deleteFileDatabase(basePath: string, key: string) {
    return this.db.list(`${basePath}/`).remove(key);
  }

  private deleteFileStorage(basePath: string, name: string) {
    const storageRef = this.storage.ref();
    let path = `${basePath}/${name}`;
    storageRef.child(path).delete();
  }
}

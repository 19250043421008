import {GetSafeValue} from '../utils/get-safe-value';
import {StringFieldUtil} from '../utils/string-field-util';

export class ItemStock {
  private _id: string;
  private _isEnable: boolean;
  private _barcode: string;
  private _availableQty: number;
  private _bufferQty: number;
  private _documentPath: string;
  private _storeId: string;


  fromJson(data: any){
    this.id = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.id], null);
    this.barcode = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.barcode], null);
    this.storeId = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.storeId], null);
    this.availableQty = GetSafeValue.getSafeValueAsNumber(data[StringFieldUtil.availableQty], 0);
    this.bufferQty = GetSafeValue.getSafeValueAsNumber(data[StringFieldUtil.bufferQty], 0);
    this.documentPath = GetSafeValue.getSafeValueAsString(data[StringFieldUtil.documentPath], null);
    if(data[StringFieldUtil.isOutStock])
       this.isEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isOutStock], false);
    else
      this.isEnable = GetSafeValue.getSafeValueAsBoolean(data[StringFieldUtil.isEnable], false);
  }

  get barcode(): string {
    return this._barcode;
  }

  set barcode(value: string) {
    this._barcode = value;
  }

  get availableQty(): number {
    return this._availableQty;
  }

  set availableQty(value: number) {
    this._availableQty = value;
  }

  get bufferQty(): number {
    return this._bufferQty;
  }

  set bufferQty(value: number) {
    this._bufferQty = value;
  }


  get documentPath(): string {
    return this._documentPath;
  }

  set documentPath(value: string) {
    this._documentPath = value;
  }

  get isEnable(): boolean {
    return this._isEnable;
  }

  set isEnable(value: boolean) {
    this._isEnable = value;
  }


  get storeId(): string {
    return this._storeId;
  }

  set storeId(value: string) {
    this._storeId = value;
  }


  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  toMap() {
    return {
      'id': this.barcode,
      'barcode': this.barcode,
      'availableQty': this.availableQty,
      'bufferQty': this.bufferQty,
      'documentPath': this.documentPath,
      'isEnable': this.isEnable,
      'storeId': this.storeId,
    };
  }

}

import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Role} from '../model/role';
import {SharedPreference} from '../shared-preference/shared-perference';
import {brandList, dashboardUrl, orderListUrl, profile, bussinessConfigurationUrl, categoryList, home, homePageUrl, requestDemoUrl, versitaSoresListUrl} from '../inventory/route';
import {StoreUtils} from '../utils/store-utils';
import {FirebaseService} from '../services/firebase.service';
import {VersitaStores} from '../model/versita-stores';
import {LoggUtilInfo} from '../utils/loggUtil';
import {StringUtils} from '../utils/string-utils';
import {AuthenticationService} from '../services/authentication.service';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class DashBoardGuardService implements CanActivate{

  constructor(private router: Router,
              private firebaseService : FirebaseService,
              private authenticationService: AuthenticationService
  ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!SharedPreference.getUser()) {
      this.authenticationService.onLogout(false);
      return false;
    }
    let isDashboard = false;

    if (Role.hasVersitaCustomerService(SharedPreference.getUser()) || Role.hasVersitaSupervisor(SharedPreference.getUser())) {
      if (SharedPreference.getUser().isRequireChangePassword) {
        this.router.navigate([profile]);
      } else {
        return true;
      }
    }

    if(StoreUtils.store.businessSetting.isDashboardEnable !== undefined){
      isDashboard = true/* || this.checked(StoreUtils.store.businessSetting.isDashboardEnable)*/;
    }else {

      if (Role.hasVersitaUser(SharedPreference.getUser()) && SharedPreference.getUser().currentStoreId === null) {
        const user: User = SharedPreference.getUser();
        if (Role.hasVersitaAdmin(user)) {
          this.router.navigate([categoryList]);
        } else if (Role.hasVersitaSystem(user)) {
          this.router.navigate([bussinessConfigurationUrl]);
        } else if (Role.hasVersitaSale(user)) {
          this.router.navigate([requestDemoUrl]);
        } else if (Role.hasVersitaStoreRole(user)) {
          this.router.navigate([versitaSoresListUrl]);
        } else {
         this.router.navigate([homePageUrl]);
        }
        return false;
      }

      await this.firebaseService.onReadDocument(FirebaseService.store, SharedPreference.getUser().currentStoreId).then((value => {
        if (value.exists) {
          StoreUtils.store = value.data() as VersitaStores;
          isDashboard = this.checked(StoreUtils.store.businessSetting.isDashboardEnable);
          LoggUtilInfo.loggingEnd(StringUtils.listenSnapshot + FirebaseService.store +"/"+ SharedPreference.getUser().currentStoreId + ' component : dashboard guard');
        }
      })).catch((e)=>{
        LoggUtilInfo.loggingError('from Dashboard',e.toString());
      });
    }
    return isDashboard;
  }

  private checked(isDashboard: boolean) {
    if (SharedPreference.getUser().isRequireChangePassword) {
      this.router.navigate([profile]);
      isDashboard = false;
    } else {
      if ((Role.hasAdminRole(SharedPreference.getUser())
        || Role.hasVersitaStoreRole(SharedPreference.getUser())
        || Role.hasSupervisorRole(SharedPreference.getUser())
        || Role.hasCustomerServiceRole(SharedPreference.getUser()))
        && !Role.hasVersitaSale(SharedPreference.getUser())
        && StoreUtils.store.businessSetting.isDashboardEnable) {
        isDashboard = true;
      } else {
        if (Role.hasVersitaSystem(SharedPreference.getUser())) {
          this.router.navigate([bussinessConfigurationUrl]);
        } else {
          this.router.navigate([brandList]);
        }
        isDashboard = false;
      }
    }
    return isDashboard;
  }
}

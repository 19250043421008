import {Role} from '../model/role';
import {SharedPreference} from '../shared-preference/shared-perference';

export class RoleUtils {
  public static hasRole(roles: any[], userRole){
    return roles.includes(userRole);
  }

  public static hasReadOnlyRole() {
    return Role.hasReadOnlyRole(SharedPreference.getUser());
  }
}

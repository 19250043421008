import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import * as firebase from 'firebase';
import {SystemConfigUtils} from '../utils/system-config-utils';
import {ChatsUtils} from '../utils/chats-utils';
import {SharedPreference} from '../shared-preference/shared-perference';
import {UserService} from '../login/user-service';
import {Router} from '@angular/router';
import {homePageUrl, loginUrl} from '../inventory/route';
import {environment} from '../../environments/environment';
import {ItemUtils} from "../utils/items-utils";
import {BrandUtils} from "../utils/brand-utils";
import {CategoryUtils} from "../utils/category-utils";
import {OrderUtil} from "../utils/order-util";
import {UserProfileUtils} from "../utils/user-profile-utils";
import {StoreUtils} from "../utils/store-utils";
import {NumberUtils} from '../utils/number-utils';
import {StoreSettingUtil} from "../utils/store-setting-util";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  constructor(
    public db: AngularFirestore,
    private router: Router,
  ) {}

  async onLogin(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  }

  signUp(email: string, passsword: string): Promise<firebase.auth.UserCredential> {
    return firebase.auth().createUserWithEmailAndPassword(email, passsword);
  }

  onLogout(isSessionTimeout?: boolean) {
    if (SharedPreference.getUser() && SharedPreference.getUser().currentStoreId) {
      SharedPreference.clearStoreName(SharedPreference.getUser().currentStoreId);
    }
    SystemConfigUtils.unsubscribeSystemConfiguration();
    ChatsUtils.unsubscribeChat();
    ChatsUtils.unsubscribeChatByUid();
    UserService.unsubcribUser();
    SharedPreference.setIsUserLogin(false);
    SharedPreference.clearUser();
    StoreUtils.setSelectStoreId("");
    StoreUtils.clearSelectStoreId();
    SystemConfigUtils.clear();
    if (!isSessionTimeout) {
      ItemUtils.clear();
      OrderUtil.clear();
      UserProfileUtils.clear();
      SystemConfigUtils.clear();
      StoreUtils.clearStore();
      StoreSettingUtil.clear();
      CategoryUtils.clear();
      BrandUtils.clear();
    }

    NumberUtils.currentTotalInternalUser = undefined;

    firebase.auth().signOut().then(()=>{
      if (environment.isEnableHomePage && !isSessionTimeout) {
        window.location.replace(homePageUrl);
      } else {
        window.location.replace(loginUrl);
      }
    });
  }

  logout() {
    firebase.auth().signOut().then();
  }

  get windowRef() {
    return window
  }
}


import {Brand} from "../inventory/model/brand";
import {FirebaseService} from '../services/firebase.service';
import {StringUtils} from './string-utils';
import {ItemCount} from "../inventory/model/item-count";

/**
 * Created by cheaphakdey on 7/3/19.
 */

export class BrandUtils{
  private static _brand: Map<string, Brand>;
  private static _itemCount: Map<string, ItemCount>;

  static get brand(): Map<string, Brand> {
    return this._brand;
  }

  static setBrand(value: any) {
    if(!this._brand){
      this._brand = new Map();
    }

    StringUtils.objToStrMap(value.data() as Brand).forEach((v, k)=>{
      let brand = Brand.fromJson(v);
      this._brand.set(k, brand);
    });
  }

  static get itemCount(): Map<string, ItemCount> {
    return this._itemCount;
  }

  static setItemCount(value: any) {
    this._itemCount = new Map();

    StringUtils.objToStrMap(value.data() as ItemCount).forEach((v, k)=>{
      let itemCount = ItemCount.fromJson(v);
      this._itemCount.set(k, itemCount);
    });
  }

  static resetItemCount() {
    this._itemCount = new Map();
  }

  static isItemCountEmpty(){
    return !this.itemCount || this.itemCount.size <= 0;
  }

  static isBrandEmpty(){
    return !this.brand || this.brand.size <= 0;
  }

  static incOrDecBrand(brandId: string, isEnable: boolean, isNew: boolean, isDelete: boolean){
    let itemCount = new ItemCount(1, 0);
    if(!BrandUtils.isBrandEmpty() && !BrandUtils.isItemCountEmpty() && BrandUtils.itemCount.get(brandId)) {
      itemCount = BrandUtils.itemCount.get(brandId);
      let countTotal = 0;
      let countActive = 0;

      if(!isNaN(itemCount.itemCountTotal)){
        countTotal = itemCount.itemCountTotal;
      }

      if(!isNaN(itemCount.itemCountActive)){
        countActive = itemCount.itemCountActive;
      }

      if (isDelete) {
        if (itemCount.itemCountTotal > 0) {
          countTotal--;
          itemCount.itemCountTotal = countTotal;
        }
      } else {
        if (isNew) {
          countTotal++;
          itemCount.itemCountTotal = countTotal;
          itemCount.itemCountActive = countActive;
        } else {
          if (isEnable) {
            countActive++;
            itemCount.itemCountActive = countActive;
          } else {
            countActive--;
            itemCount.itemCountActive = countActive;
          }
        }
      }

      BrandUtils.itemCount.set(brandId, itemCount);
    }

    return itemCount;
  }

  static updateChangeBrand(newBrandId: string, oldBrandId: string){
    let mapItemCount: Map<string, ItemCount> = new Map();
    let newItemCount: ItemCount;
    let count = 0;

    if(this.itemCount.get(newBrandId)){
      newItemCount = this.itemCount.get(newBrandId);
    }else {
      newItemCount = new ItemCount(0, 0);
    }

    if(newItemCount.itemCountTotal){
      count = newItemCount.itemCountTotal;
    }

    count++;
    newItemCount.itemCountTotal = count;

    if(isNaN(newItemCount.itemCountActive)){
      newItemCount.itemCountActive = 0;
    }

    BrandUtils.itemCount.set(newBrandId, newItemCount);
    let oldItemCount = new ItemCount(0,0);

    if(this.itemCount.get(oldBrandId)) {
      oldItemCount = this.itemCount.get(oldBrandId);
      count = oldItemCount.itemCountTotal;

      if(count > 0) {
        count--;
        oldItemCount.itemCountTotal = count;
      }

      BrandUtils.itemCount.set(oldBrandId, oldItemCount);
    }

    mapItemCount.set('new', newItemCount);
    mapItemCount.set('old', oldItemCount);
    return mapItemCount;
  }

  static setTotalActiveItemCount(brandId: string, totalCount: number, activeCount: number){
    if(BrandUtils.isItemCountEmpty()){
      this._itemCount = new Map();
    }

    let itemCount = new ItemCount(totalCount, activeCount);
    this._itemCount.set(brandId, itemCount);
  }

  static clear(){
    if(this.brand){
      this.brand.clear();
    }

    if(this.itemCount){
      this.itemCount.clear();
    }
  }

  static getLoggingMsg() {
    return StringUtils.readDocument + FirebaseService.brand + "/" + FirebaseService.brandCollection;
  }
}

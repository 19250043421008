import {GetSafeValue} from "../utils/get-safe-value";
import { CDN } from "./cdn";
import { StringFieldUtil } from "../utils/string-field-util";
import {Watermark} from "./watermark";
import {FacebookConfig} from "./facebook-config";
import {TagConfi} from "./tag-confi";

export class SystemConfiguration {
  private _maxRecordItems: number;
  private _maxRecordKeywords: number;
  private _maxRecordReport: number;
  private _maxRecordOrderStatus: number;
  private _maxRecordRating: number;
  private _messageDisplayDays: number;
  private _subscribeDashboardOrderStatus: boolean;
  private _isInventoryDeleteEnable: boolean;
  private _isSingleStoreSetting: boolean;
  private _canUpdateCustomerUser: boolean;
  private _isTermAndConditionEnable: boolean;
  private _isCustomShippingEnable: boolean;
  private _viewStoresEnable: boolean;
  private _isMultiPayment : boolean;
  private _isPaymentOnlineEnable: boolean;
  private _isGoogleMapEnable: boolean;
  private _webUrl: string;
  private _cdn: CDN;
  private _isQrCodeSystemControlEnable: boolean;
  private _isCashOnDeliveryEnable : boolean;
  private _isABAEnable : boolean;
  private _isAcledaEnable : boolean;
  private _isPiPayEnable : boolean;
  private _isMasterCardEnable : boolean;
  private _isEmailEnable : boolean;
  private _isPhoneEnable : boolean;
  private _isWingEnable: boolean;
  private _isAmkQrEnable: boolean;
  private _isAmkPayEnable: boolean;
  private _watermark: Watermark;
  private _facebookConfig: FacebookConfig;
  private _maxVideoSize: number;
  private _maxImageSize: number;
  private _isBackgroundRemovalEnable: boolean;
  private _isBackgroundPlaceOrderEnable: boolean;
  private _isApplyLoanEnable: boolean;
  private _maxItemRanking: number;
  private _minItemRanking: number;
  private _tags : TagConfi[];
  private _systemBideal: SystemBideal;


  constructor(){
    this.isTermAndConditionEnable = undefined;
    this.isCustomShippingEnable = undefined;
    this.isInventoryDeleteEnable = undefined;
    this.subscribeDashboardOrderStatus = undefined;
    this.isSingleStoreSetting = undefined;
    this.canUpdateCustomerUser = undefined;
    this.maxRecordItems = 0.0;
    this.maxRecordKeywords = 0.0;
    this.maxRecordReport = 0.0;
    this.maxRecordOrderStatus = 0.0;
    this.maxRecordRating = 0.0;
    this.messageDisplayDays = 0.0;
    this.webUrl = "";
    this.cdn = new CDN();
    this.watermark = new Watermark();
    this.facebookConfig = new FacebookConfig();
    this.systemBideal = new SystemBideal();
    this.isQrCodeSystemControlEnable = false;
    this.maxItemRanking = 100;
    this.minItemRanking = 0;
    this.tags = [];
  }

  fromJson(data: SystemConfiguration) {
    this.maxRecordItems = GetSafeValue.getSafeValueAsNumber(
      data['maxRecordItems'], 0
    );

    this.maxItemRanking = GetSafeValue.getSafeValueAsNumber(
      data['maxItemRanking'], 100
    );

    this.minItemRanking = GetSafeValue.getSafeValueAsNumber(
      data['minItemRanking'], 0
    );

    this.maxRecordKeywords = GetSafeValue.getSafeValueAsNumber(
      data['maxRecordKeywords'], 0
    );
    this.maxRecordReport = GetSafeValue.getSafeValueAsNumber(
      data['maxRecordReport'], 0
    );
    this.maxRecordOrderStatus = GetSafeValue.getSafeValueAsNumber(
      data['maxRecordOrderStatus'],0
    );
    this.maxRecordRating = GetSafeValue.getSafeValueAsNumber(
      data['maxRecordRating'], 0
    );
    this.messageDisplayDays = GetSafeValue.getSafeValueAsNumber(
      data['messageDisplayDays'], 7
    );
    this.isInventoryDeleteEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isInventoryDeleteEnable'], false
    );
    this.subscribeDashboardOrderStatus = GetSafeValue.getSafeValueAsBoolean(
      data['subscribeDashboardOrderStatus'], false
    );
    this.isSingleStoreSetting = GetSafeValue.getSafeValueAsBoolean(
      data['isSingleStoreSetting'], false
    );
    this.isTermAndConditionEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isTermAndConditionEnable'], false
    );
    this.isCustomShippingEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isCustomShippingEnable'], false
    );
    this.canUpdateCustomerUser = GetSafeValue.getSafeValueAsBoolean(
      data['canUpdateCustomerUser'], false
    );
    this.viewStoresEnable = GetSafeValue.getSafeValueAsBoolean(
      data['viewStoresEnable'], false
    );
    this.isMultiPayment = GetSafeValue.getSafeValueAsBoolean(
      data['isMultiPayment'], false
    );
    this.webUrl = GetSafeValue.getSafeValueAsString(
      data['webUrl'], undefined
    );

    if(data[StringFieldUtil.cdn]) {
      this.cdn.fromJson(data[StringFieldUtil.cdn]);
    } else {
      this.cdn.fromJson(new CDN());
    }

    if(data[StringFieldUtil.watermark]) {
      this.watermark.fromJson(data[StringFieldUtil.watermark]);
    } else {
      this.watermark.fromJson(new Watermark());
    }

    if(data[StringFieldUtil.facebookConfig]) {
      this.facebookConfig.fromJson(data[StringFieldUtil.facebookConfig]);
    } else {
      this.facebookConfig.fromJson(new FacebookConfig());
    }

    if(data['systemSetting']) {
      this.systemBideal.fromJson(data['systemSetting']);
    } else {
      this.systemBideal.fromJson(new SystemBideal());
    }

    this.maxVideoSize = GetSafeValue.getSafeValueAsNumber(data[StringFieldUtil.maxVideoSize], null);
    this.maxImageSize = GetSafeValue.getSafeValueAsNumber(data[StringFieldUtil.maxImageSize], null);

    this.isPaymentOnlineEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isPaymentOnlineEnable'], false
    );
    this.isGoogleMapEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isGoogleMapEnable'], false
    );

    this.isQrCodeSystemControlEnable = GetSafeValue.getSafeValueAsBoolean(
      data[StringFieldUtil.isQrCodeSystemControlEnable], false
    );

    this.isCashOnDeliveryEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isCashOnDeliveryEnable'], false
    );

    this.isAcledaEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isAcledaEnable'], false
    );

    this.isABAEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isABAEnable'], false
    );

    this.isPiPayEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isPiPayEnable'], false
    );

    this.isMasterCardEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isMasterCardEnable'], false
    );

    this.isEmailEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isEmailEnable'], false
    );

    this.isPhoneEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isPhoneEnable'], true
    );

    this.isWingEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isWingEnable'], false
    );

    this.isAmkQrEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isAmkQrEnable'], false
    );

    this.isAmkPayEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isAmkPayEnable'], false
    );

    this.isBackgroundRemovalEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isBackgroundRemovalEnable'], false
    );

    this.isBackgroundPlaceOrderEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isBackgroundPlaceOrderEnable'], false
    );

    this.isApplyLoanEnable = GetSafeValue.getSafeValueAsBoolean(
      data['isApplyLoanEnable'], false
    );


    this.tags = [];
    if(data['tagConfig']){
      data['tagConfig'].forEach(value => {
        let tag : TagConfi = new TagConfi();
       tag.fromJson(value);
       this.tags.push(tag);
      });
    }
  }

  get systemBideal(): SystemBideal {
    return this._systemBideal;
  }

  set systemBideal(value: SystemBideal) {
    this._systemBideal = value;
  }

  get isApplyLoanEnable(): boolean {
    return this._isApplyLoanEnable;
  }

  set isApplyLoanEnable(value: boolean) {
    this._isApplyLoanEnable = value;
  }

  get isAmkQrEnable(): boolean {
    return this._isAmkQrEnable;
  }

  set isAmkQrEnable(value: boolean) {
    this._isAmkQrEnable = value;
  }

  get isAmkPayEnable(): boolean {
    return this._isAmkPayEnable;
  }

  set isAmkPayEnable(value: boolean) {
    this._isAmkPayEnable = value;
  }

  get isSingleStoreSetting(): boolean {
    return this._isSingleStoreSetting;
  }


  get canUpdateCustomerUser(): boolean {
    return this._canUpdateCustomerUser;
  }

  get cdn(): CDN {
    return this._cdn;
  }

  set cdn(value: CDN) {
    this._cdn = value;
  }

  set isSingleStoreSetting(value: boolean) {
    this._isSingleStoreSetting = value;
  }

  set canUpdateCustomerUser(value: boolean) {
    this._canUpdateCustomerUser = value;
  }

  get webUrl(): string {
    return this._webUrl;
  }

  set webUrl(value: string) {
    this._webUrl = value;
  }

  get subscribeDashboardOrderStatus(): boolean {
    return this._subscribeDashboardOrderStatus;
  }

  set subscribeDashboardOrderStatus(value: boolean) {
    this._subscribeDashboardOrderStatus = value;
  }

  get maxRecordKeywords(): number {
    return this._maxRecordKeywords;
  }

  set maxRecordKeywords(value: number) {
    this._maxRecordKeywords = value;
  }

  get isInventoryDeleteEnable(): boolean {
    return this._isInventoryDeleteEnable;
  }

  set isInventoryDeleteEnable(value: boolean) {
    this._isInventoryDeleteEnable = value;
  }

  get maxRecordItems(): number {
    return this._maxRecordItems;
  }

  set maxRecordItems(value: number) {
    this._maxRecordItems = value;
  }

  get maxRecordReport(): number {
    return this._maxRecordReport;
  }

  set maxRecordReport(value: number) {
    this._maxRecordReport = value;
  }

  get maxRecordOrderStatus(): number {
    return this._maxRecordOrderStatus;
  }

  set maxRecordOrderStatus(value: number) {
    this._maxRecordOrderStatus = value;
  }

  get maxRecordRating(): number {
    return this._maxRecordRating;
  }

  set maxRecordRating(value: number) {
    this._maxRecordRating = value;
  }

  get messageDisplayDays(): number {
    return this._messageDisplayDays;
  }

  set messageDisplayDays(value: number) {
    this._messageDisplayDays = value;
  }

  get isTermAndConditionEnable(): boolean {
    return this._isTermAndConditionEnable;
  }

  set isTermAndConditionEnable(value: boolean) {
    this._isTermAndConditionEnable = value;
  }

  get isCustomShippingEnable(): boolean {
    return this._isCustomShippingEnable;
  }

  set isCustomShippingEnable(value: boolean) {
    this._isCustomShippingEnable = value;
  }

  get isMultiPayment(): boolean {
    return this._isMultiPayment;
  }

  set isMultiPayment(value: boolean) {
    this._isMultiPayment = value;
  }

  get isPaymentOnlineEnable(): boolean {
    return this._isPaymentOnlineEnable;
  }

  set isPaymentOnlineEnable(value: boolean) {
    this._isPaymentOnlineEnable = value;
  }


  get isGoogleMapEnable(): boolean {
    return this._isGoogleMapEnable;
  }

  set isGoogleMapEnable(value: boolean) {
    this._isGoogleMapEnable = value;
  }

  get viewStoresEnable(): boolean {
    return this._viewStoresEnable;
  }

  set viewStoresEnable(value: boolean) {
    this._viewStoresEnable = value;
  }


  get isQrCodeSystemControlEnable(): boolean {
    return this._isQrCodeSystemControlEnable;
  }

  set isQrCodeSystemControlEnable(value: boolean) {
    this._isQrCodeSystemControlEnable = value;
  }


  get isCashOnDeliveryEnable(): boolean {
    return this._isCashOnDeliveryEnable;
  }

  set isCashOnDeliveryEnable(value: boolean) {
    this._isCashOnDeliveryEnable = value;
  }

  get isABAEnable(): boolean {
    return this._isABAEnable;
  }

  set isABAEnable(value: boolean) {
    this._isABAEnable = value;
  }

  get isAcledaEnable(): boolean {
    return this._isAcledaEnable;
  }

  set isAcledaEnable(value: boolean) {
    this._isAcledaEnable = value;
  }

  get isPiPayEnable(): boolean {
    return this._isPiPayEnable;
  }

  set isPiPayEnable(value: boolean) {
    this._isPiPayEnable = value;
  }

  get isMasterCardEnable(): boolean {
    return this._isMasterCardEnable;
  }

  set isMasterCardEnable(value: boolean) {
    this._isMasterCardEnable = value;
  }

  get isEmailEnable(): boolean {
    return this._isEmailEnable;
  }

  set isEmailEnable(value: boolean) {
    this._isEmailEnable = value;
  }

  get isPhoneEnable(): boolean {
    return this._isPhoneEnable;
  }

  set isPhoneEnable(value: boolean) {
    this._isPhoneEnable = value;
  }

  get isWingEnable(): boolean {
    return this._isWingEnable;
  }

  set isWingEnable(value: boolean) {
    this._isWingEnable = value;
  }

  get watermark(): Watermark {
    return this._watermark;
  }

  set watermark(value: Watermark) {
    this._watermark = value;
  }


  get facebookConfig(): FacebookConfig {
    return this._facebookConfig;
  }

  set facebookConfig(value: FacebookConfig) {
    this._facebookConfig = value;
  }

  get maxVideoSize(): number {
    return this._maxVideoSize;
  }

  set maxVideoSize(value: number) {
    this._maxVideoSize = value;
  }

  get isBackgroundRemovalEnable(): boolean {
    return this._isBackgroundRemovalEnable;
  }

  set isBackgroundRemovalEnable(value: boolean) {
    this._isBackgroundRemovalEnable = value;
  }

  get isBackgroundPlaceOrderEnable(): boolean {
    return this._isBackgroundPlaceOrderEnable;
  }

  set isBackgroundPlaceOrderEnable(value: boolean) {
    this._isBackgroundPlaceOrderEnable = value;
  }

  get maxImageSize(): number {
    return this._maxImageSize;
  }

  set maxImageSize(value: number) {
    this._maxImageSize = value;
  }


  get maxItemRanking(): number {
    return this._maxItemRanking;
  }

  set maxItemRanking(value: number) {
    this._maxItemRanking = value;
  }

  get minItemRanking(): number {
    return this._minItemRanking;
  }

  set minItemRanking(value: number) {
    this._minItemRanking = value;
  }


  get tags(): TagConfi[] {
    return this._tags;
  }

  set tags(value: TagConfi[]) {
    this._tags = value;
  }

  toMap(){
    let tagsMap = [];
    if(this.tags.length > 0) {
      this.tags.forEach(tag => {
        tagsMap.push(tag.toMap());
      });
    }
    return{
      'isInventoryDeleteEnable' : this.isInventoryDeleteEnable,
      'subscribeDashboardOrderStatus' : this.subscribeDashboardOrderStatus,
      'maxRecordItems' : this.maxRecordItems,
      'maxRecordKeywords' : this.maxRecordKeywords,
      'maxRecordReport' : this.maxRecordReport,
      'maxRecordOrderStatus' : this.maxRecordOrderStatus,
      'maxRecordRating' : this.maxRecordRating,
      'messageDisplayDays' : this.messageDisplayDays,
      'webUrl': this.webUrl,
      'cdn': this.cdn.toMap(),
      'watermark': this.watermark.toMap(),
      'facebookConfig': this.facebookConfig.toMap(),
      'canUpdateCustomerUser': this.canUpdateCustomerUser,
      'isTermAndConditionEnable' : this.isTermAndConditionEnable,
      'isCustomShippingEnable' : this.isCustomShippingEnable,
      'isSingleStoreSetting': this.isSingleStoreSetting,
      'viewStoresEnable': this.viewStoresEnable,
      'isMultiPayment' : this.isMultiPayment,
      'isPaymentOnlineEnable': this.isPaymentOnlineEnable,
      'isGoogleMapEnable': this.isGoogleMapEnable,
      'isQrCodeSystemControlEnable': this.isQrCodeSystemControlEnable,
      'isCashOnDeliveryEnable' : this.isCashOnDeliveryEnable,
      'isAcledaEnable' : this.isAcledaEnable,
      'isABAEnable' : this.isABAEnable,
      'isPiPayEnable' : this.isPiPayEnable,
      'isMasterCardEnable' : this.isMasterCardEnable,
      'isEmailEnable' : this.isEmailEnable,
      'isPhoneEnable' : this.isPhoneEnable,
      'isAmkQrEnable' : this.isAmkQrEnable,
      'isAmkPayEnable' : this.isAmkPayEnable,
      'isWingEnable': this.isWingEnable,
      'maxVideoSize': this.maxVideoSize,
      'maxImageSize': this.maxImageSize,
      'isBackgroundRemovalEnable': this.isBackgroundRemovalEnable,
      'isBackgroundPlaceOrderEnable': this.isBackgroundPlaceOrderEnable,
      'isApplyLoanEnable': this.isApplyLoanEnable,
      'maxItemRanking' : this.maxItemRanking,
      'minItemRanking' : this.minItemRanking,
      'tagConfig' : tagsMap
    }
  }
}

export class SystemBideal {
  private _isMarketPlace: boolean;
  private _isBideal: boolean;
  private _isDonation: boolean;
  private _isBestBuyBid: boolean;
  private _isUnique: boolean;

  constructor(){
  }

  fromJson(data){
    this.isMarketPlace = GetSafeValue.getSafeValueAsBoolean(data['isMarketPlace'], false);
    this.isBideal = GetSafeValue.getSafeValueAsBoolean(data['isBideal'], false);
    this.isDonation = GetSafeValue.getSafeValueAsBoolean(data['isDonation'], false);
    this.isBestBuyBid = GetSafeValue.getSafeValueAsBoolean(data['isBestBuyBid'], false);
    this.isUnique = GetSafeValue.getSafeValueAsBoolean(data['isUnique'], false);
  }

  toMap(){
    return {
      "isMarketPlace" : this.isMarketPlace,
      "isBideal" : this.isBideal,
      "isDonation" : this.isDonation,
      "isBestBuyBid" : this.isBestBuyBid,
      "isUnique" : this.isUnique,
    };
  }

  get isMarketPlace(): boolean {
    return this._isMarketPlace;
  }

  set isMarketPlace(value: boolean) {
    this._isMarketPlace = value;
  }

  get isBideal(): boolean {
    return this._isBideal;
  }

  set isBideal(value: boolean) {
    this._isBideal = value;
  }

  get isDonation(): boolean {
    return this._isDonation;
  }

  set isDonation(value: boolean) {
    this._isDonation = value;
  }

  get isBestBuyBid(): boolean {
    return this._isBestBuyBid;
  }

  set isBestBuyBid(value: boolean) {
    this._isBestBuyBid = value;
  }

  get isUnique(): boolean {
    return this._isUnique;
  }

  set isUnique(value: boolean) {
    this._isUnique = value;
  }
}


import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {SharedPreference} from '../shared-preference/shared-perference';
import {Role} from '../model/role';

@Injectable({
  providedIn: 'root'
})
export class WalletGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return Role.hasVersitaAdmin(SharedPreference.getUser())   ||
           Role.hasAdminRole(SharedPreference.getUser())      ||
           Role.hasVersitaSystem(SharedPreference.getUser())  ||
           Role.hasSupervisorRole(SharedPreference.getUser()) ||
           Role.hasSupervisorRole(SharedPreference.getUser()) ||
           Role.hasCustomerServiceRole(SharedPreference.getUser()) ||
           Role.hasVersitaCustomerService(SharedPreference.getUser())
        ;
  }
}

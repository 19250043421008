/**
 * Created by cheaphakdey on 17/5/19.
 */
export class LoggingUtil{
  static logging(msg: string, object?: Object){
    console.log(msg, object ? object : "");
  }
}

export class LoggUtilInfo {
  static loggingStart(msg: string, object?: Object){
    let obj = object ? object : '';
    let status = msg + " " + obj ;
    console.log("%c<> start:%c" + status, "color: blue", "color: black");
  }

  static loggingInfo(msg: string, object?: Object){
    let obj = object ? object : '';
    let status = msg + " " + obj ;
    console.log("%c! info: %c" + status, "color: blue", "color: black");
  }

  static loggingEnd(msg: string, object?: Object){
    let obj = object ? object : '';
    let status = msg + " " + obj ;
    console.log('%c</> end:%c' + status, "color: orange", "color: black");
  }

  static loggingListen(msg: string, object?: Object){
    let obj = object ? object : '';
    let status = msg + " " + obj ;
    console.log("%c<.> listen:%c" + status , "color:green", "color: black");
  }

  static loggingError(msg: string, object?: Object){
    let obj = object ? object : '';
    let status = msg + " " + obj ;
    console.log("%c<(e)> error:%c" + status, "color: red", "color: black");
  }

}

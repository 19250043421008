import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {SharedPreference} from "../../shared-preference/shared-perference";
import { HttpClient } from '@angular/common/http';
import {Role} from '../../model/role';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  textHasApp: string;

  constructor(
    public router: Router,
    public http: HttpClient
  ) {}

  ngOnInit() {
    this.textHasApp = '';
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/dashboard1']);
    }
    this.hasApp();
  }

  hasApp(){
    if(SharedPreference.getLogin()) {
      if (Role.hasVersitaUser(SharedPreference.getUser())) {
        this.http.get('assets/git-commit-revision.txt', {responseType: 'text'})
          .subscribe(data => this.textHasApp = data);
      }
    }
  }

  isOnHomePage() {
    return this.router.url === "/home-page";
  }

  isOnJoinAsPartner() {
    return this.router.url.includes("/join-as-partner");
  }

  isOnPartnerRegister() {
    return this.router.url.includes("/partner-register");
  }

  public getYear(){
    const d = new Date();
    return  d.getFullYear();
  }
}
